import { ScanWaiverDialogCaptureContent } from './ScanWaiverDialogCaptureContent';
import { ScanWaiverDialogReviewContent } from './ScanWaiverDialogReviewContent';
import { ScanWaiverDialogToolbar } from './ScanWaiverDialogToolbar';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { useCallback, useState } from 'react';

interface ScanWaiverDialogProps extends DialogProps {
  onConfirm: (imageData: string) => Promise<void>;
}

export const ScanWaiverDialog = ({ onClose, onConfirm, ...props }: ScanWaiverDialogProps) => {
  const [imageData, setImageData] = useState<string>(null);

  const handleClose = useCallback(() => {
    // TODO: clear waiver image data
    setImageData(null);
    onClose(null, null);
  }, [onClose, setImageData]);

  const handleConfirm = useCallback(
    async (imageData: string) => {
      await onConfirm(imageData);
      setImageData(null);
      onClose(null, null);
    },
    [onConfirm, onClose],
  );

  const handlePhotoTaken = useCallback(
    (data: string) => {
      setImageData(data);
    },
    [setImageData],
  );

  const handleRetake = useCallback(() => {
    setImageData(null);
  }, [setImageData]);

  return (
    <Dialog
      {...props}
      onClose={handleClose}
      fullScreen
      PaperProps={{ sx: { p: 0, m: '0 !important' } }}
      sx={{ textAlign: 'center' }}
    >
      <ScanWaiverDialogToolbar onClose={handleClose} title="Scan Waiver" />

      {!imageData ? (
        <ScanWaiverDialogCaptureContent onClose={handleClose} onPhotoTaken={handlePhotoTaken} />
      ) : (
        <ScanWaiverDialogReviewContent
          imageData={imageData}
          onConfirm={handleConfirm}
          onRetake={handleRetake}
        />
      )}
    </Dialog>
  );
};
