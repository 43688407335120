import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { FC, useCallback } from 'react';

interface TicketQuantitySelectorProps {
  disabled?: boolean;

  max: number;

  onChange: (quantity: number) => void;

  value: number;
}

export const TicketQuantity: FC<TicketQuantitySelectorProps> = ({
  disabled = false,
  max,
  onChange,
  value,
}) => {
  const theme = useTheme();

  const handleMinusClick = useCallback(() => {
    onChange(Math.max(value - 1, 1));
  }, [onChange, value]);

  const handlePlusClick = useCallback(() => {
    onChange(Math.min(value + 1, max));
  }, [max, onChange, value]);

  const handleAllClick = useCallback(() => {
    onChange(max);
  }, [max, onChange]);

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{ alignItems: 'center', textAlign: 'center', width: '100%' }}
    >
      <Stack
        direction="row"
        spacing={0.5}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h1">{value}</Typography>
        <Typography variant="h1" color="rgba(0, 0, 0, .25)">
          /{max}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          p: 0,
          width: '100%',
        }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            flexBasis: 180,
            flexShrink: 0,
            flexGrow: 0,
            justifyContent: 'space-between',
          }}
        >
          <IconButton
            disabled={disabled || max <= 1}
            onClick={handleMinusClick}
            size="medium"
            sx={{
              backgroundColor: theme.palette.action.hover,
              flexGrow: 0,
              flexShrink: 0,
              height: 36,
              width: 36,
            }}
          >
            <Remove />
          </IconButton>
          <Button
            color="secondary"
            disabled={disabled || max <= 1}
            onClick={handleAllClick}
            sx={{ flexGrow: 0, flexShrink: 0 }}
          >
            Select All
          </Button>
          <IconButton
            disabled={disabled || max <= 1}
            onClick={handlePlusClick}
            size="medium"
            sx={{
              backgroundColor: theme.palette.action.hover,
              flexGrow: 0,
              flexShrink: 0,
              height: 36,
              width: 36,
            }}
          >
            <Add />
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  );
};
