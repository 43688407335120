import { InjectGraphQLClient } from '../graph/InjectGraphQLClient';
import { AppConfigRepository } from '../repositories/AppConfigRepository';
import { ApolloClient } from '@apollo/client';
import { OperationVariables } from '@apollo/client/core/types';
import { MutationOptions, QueryOptions } from '@apollo/client/core/watchQueryOptions';
import { Logger } from '@greatcrowd/ui-logging';
import { Service } from 'typedi';

@Service()
export class GraphQlClientService {
  protected readonly logger = new Logger(GraphQlClientService.name);
  protected readonly defaultSortField: string = 'id';

  constructor(
    private readonly appConfigRepository: AppConfigRepository,
    @InjectGraphQLClient()
    private readonly client: ApolloClient<any>,
  ) {}

  private async getContext(): Promise<any> {
    const appConfig = await this.appConfigRepository.single();
    const token = appConfig.token;
    const organizationId = appConfig.scanner.organizationId;

    return {
      organizationId,
      token,
    };
  }

  async mutate<TData = any>(options: MutationOptions<TData>): Promise<TData> {
    const { data, errors } = await this.client.mutate<TData>({
      ...options,
      context: options.context ?? (await this.getContext()),
    });

    if (errors) {
      this.logger.error('Mutation failed', errors, options);
      throw new Error('Mutation failed', { cause: errors });
    }

    return data;
  }

  async query<TData = any, TVariables extends OperationVariables = OperationVariables>(
    options: QueryOptions<TVariables, TData>,
  ): Promise<TData> {
    const { data, error } = await this.client.query<TData>({
      ...options,
      context: options.context ?? (await this.getContext()),
    });

    if (error) {
      this.logger.error('Query failed', error, options);
      throw new Error('Query failed', { cause: error });
    }

    return data;
  }
}
