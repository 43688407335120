import { AdminLayout } from '../../components/admin/AdminLayout';
import { AdminLayoutContent } from '../../components/admin/AdminLayoutContent';
import { AdminLayoutTitle } from '../../components/admin/AdminLayoutTitle';
import { SettingsListItem } from '../../components/admin/SettingsListItem';
import {
  GetLocalScansDocument,
  GetLocalScansQuery,
  GetLocalSyncsDocument,
  GetLocalSyncsQuery,
  Scan,
  ScannerSync,
} from '../../gql/__generated__/graphql';
import { useGuestData } from '../../hooks/useGuestData';
import { routes } from '../../routes';
import { arrayToCsv, downloadBlob } from './csvExport.util';
import { ApolloClient, useApolloClient } from '@apollo/client';
import List from '@mui/material/List';
import { useTheme } from '@mui/material/styles';
import snakeCase from 'lodash/snakeCase';
import { FC, useCallback } from 'react';

const fetchAllScans = async (
  client: ApolloClient<any>,
): Promise<Omit<Omit<Scan, 'scanner'>, 'ticket'>[]> => {
  const { data } = await client.query<GetLocalScansQuery>({
    query: GetLocalScansDocument,
    variables: {
      limit: 10_000,
      offset: 0,
    },
  });

  return data.localScans;
};

const fetchAllSyncs = async (
  client: ApolloClient<any>,
): Promise<Omit<ScannerSync, 'scanner'>[]> => {
  const { data } = await client.query<GetLocalSyncsQuery>({
    query: GetLocalSyncsDocument,
    variables: {
      limit: 10_000,
      offset: 0,
    },
  });

  return data.localSyncs;
};

const getFilename = (...names: string[]): string => snakeCase(names.join(' ')) + '.csv';

export const AdminDebugExportDataView: FC = () => {
  const theme = useTheme();
  const client = useApolloClient();
  const { appConfig } = useGuestData();
  const friendlyName = appConfig?.scanner.friendlyName;

  const handleDownloadScans = useCallback(async () => {
    const data = await fetchAllScans(client);
    downloadBlob(arrayToCsv(data, friendlyName), getFilename('scans', friendlyName));
  }, [friendlyName, client]);

  const handleDownloadSyncs = useCallback(async () => {
    const data = await fetchAllSyncs(client);
    downloadBlob(arrayToCsv(data, friendlyName), getFilename('syncs', friendlyName));
  }, [friendlyName, client]);

  return (
    <AdminLayout ToolbarProps={{ back: routes.adminDebug() }}>
      <AdminLayoutTitle>Export Data</AdminLayoutTitle>
      <AdminLayoutContent>
        <List sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}>
          <SettingsListItem label="Download All Scans" onClick={handleDownloadScans} />
          <SettingsListItem label="Download All Syncs" onClick={handleDownloadSyncs} />
        </List>
      </AdminLayoutContent>
    </AdminLayout>
  );
};
