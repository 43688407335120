import { AttendeeWaiver, MutationAddWaiverArgs } from '../../../gql/__generated__/graphql';
import { WaiverService } from '../../services/WaiverService';
import { Resolver } from '@apollo/client';
import { Container } from 'typedi';

export const addWaiverResolver: Resolver = async (
  _,
  input: MutationAddWaiverArgs,
): Promise<AttendeeWaiver> => {
  const waiverService = Container.get(WaiverService);

  return waiverService.addWaiver(input.scanId, input.imageData);
};
