import { QueryScanArgs, Scan } from '../../../gql/__generated__/graphql';
import { ScanService } from '../../services/ScanService';
import { addTypename } from './addTypename';
import { Resolver } from '@apollo/client';
import { Container } from 'typedi';

export const getScanResolver: Resolver = async (
  _: null,
  { scanId }: QueryScanArgs,
): Promise<Scan> => {
  const scanService = Container.get(ScanService);

  return addTypename<Scan>(scanService.findOneById(scanId), 'Scan');
};
