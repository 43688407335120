import { AppLayout } from '../components/AppLayout';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

export const NotFoundView: FC = () => {
  const theme = useTheme();

  return (
    <AppLayout>
      <Box sx={{ flexGrow: 1, mt: 16, p: 2 }}>
        <Typography
          component="h2"
          sx={{ fontWeight: theme.typography.fontWeightBold, mb: 2 }}
          variant="h4"
        >
          Sorry, I couldn't find that.
        </Typography>
        <Typography variant="body1">
          If you expected to find something at this location, please contact an administrator for
          help.
        </Typography>
      </Box>
    </AppLayout>
  );
};
