import { routes } from '../routes';
import * as Views from '../views';
import { AuthenticatedRoute } from './AuthenticatedRoute';
import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

export const AppRoutes: FC = () => {
  return (
    <Routes>
      <Route path={routes.home.template} element={<Views.HomeView />} />

      <Route path={routes.linkScannerScan.template} element={<Views.LinkScannerScanView />} />
      <Route path={routes.linkScannerConfirm.template} element={<Views.LinkScannerConfirmView />} />

      <Route element={<AuthenticatedRoute />}>
        <Route path={routes.admin.template} element={<Views.AdminView />}>
          <Route path={routes.adminDebug.template} element={<Views.AdminDebugView />} />
          <Route path={routes.adminLogin.template} element={<Views.AdminLoginView />} />
          <Route path={routes.adminSettings.template} element={<Views.AdminSettingsView />} />
        </Route>
        <Route path={routes.adminDebugAuth.template} element={<Views.AdminDebugAuthView />} />
        <Route path={routes.adminDebugDevice.template} element={<Views.AdminDebugDeviceView />} />
        <Route path={routes.adminDebugMetrics.template} element={<Views.AdminDebugMetricsView />} />
        <Route path={routes.adminDebugUpdate.template} element={<Views.AdminDebugUpdateView />} />
        <Route path={routes.adminDebugWipe.template} element={<Views.AdminDebugWipeView />} />
        <Route
          path={routes.adminDebugExport.template}
          element={<Views.AdminDebugExportDataView />}
        />
        <Route
          path={routes.adminDebugSettings.template}
          element={<Views.AdminDebugSettingsView />}
        />
        <Route path={routes.adminDebugTicket.template} element={<Views.AdminDebugQRView />} />

        <Route path={routes.lookupGuests.template} element={<Views.LookupGuestsView />} />
        <Route
          path={routes.lookupGuestsCheckIn.template}
          element={<Views.LookupGuestsCheckInView />}
        />
        <Route
          path={routes.lookupGuestsDetail.template}
          element={<Views.LookupGuestsDetailView />}
        />

        <Route path={routes.metrics.template} element={<Views.MetricsView />} />

        <Route path={routes.scanningHome.template} element={<Views.ScanningHomeView />} />
        <Route
          path={routes.scanningTicketsScanner.template}
          element={<Views.ScanningTicketsScannerView />}
        />
        <Route
          path={routes.scanningTicketsCheckIn.template}
          element={<Views.ScanningTicketsCheckInView />}
        />
        <Route
          path={routes.scanningTicketsResult.template}
          element={<Views.ScanningTicketsResultView />}
        />
        <Route path={routes.scanningTicketsNote.template} element={<Views.ScanningNoteView />} />
      </Route>
      <Route path="*" element={<Views.NotFoundView />} />
    </Routes>
  );
};
