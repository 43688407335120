import { getImageFromVideo } from '../../utils/imageFromVideo';
import { QrCodeScanner } from '../scanning/QrCodeScanner';
import { ScanSizer } from './ScanSizer';
import { ButtonStack } from '@greatcrowd/ui-button';
import CameraIcon from '@mui/icons-material/CameraAlt';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Fab from '@mui/material/Fab';
import { useCallback, useRef } from 'react';

interface ScanWaiverDialogCaptureContentProps {
  onClose: () => void;

  onPhotoTaken: (data: string) => void;
}

export const ScanWaiverDialogCaptureContent = ({
  onClose,
  onPhotoTaken,
}: ScanWaiverDialogCaptureContentProps) => {
  const videoRef = useRef<HTMLVideoElement>();

  const calculateScanRegion = useCallback((video: HTMLVideoElement) => {
    const height = Math.floor(Math.min(video.clientWidth * 0.7, video.clientHeight * 0.25));
    const x = Math.floor((video.videoWidth - height) / 2);
    const y = Math.floor(video.clientHeight * 0.01);
    return {
      height,
      x,
      y,
      width: height,
      downScaledWidth: 400,
      downScaledHeight: 400,
    };
  }, []);

  const setSecondVideoRef = useCallback((video: HTMLVideoElement) => {
    videoRef.current = video;
  }, []);

  const handleBack = useCallback(() => {
    onClose();
  }, [onClose]);

  const takePhoto = useCallback(
    (code: string): void => {
      // TODO: store the scanned QR code
      const imageData = getImageFromVideo(videoRef.current);
      onPhotoTaken(imageData);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [getImageFromVideo, onPhotoTaken],
  );

  const handleTakePhotoClick = useCallback(() => {
    takePhoto(null);
  }, [takePhoto]);

  const handleScan = useCallback(
    (code: string) => {
      takePhoto(code);
    },
    [takePhoto],
  );

  return (
    <>
      <DialogContent sx={{ p: 2, pt: 0 }}>
        <ScanSizer>
          <QrCodeScanner
            onScan={handleScan}
            qrScannerOptions={{ calculateScanRegion }}
            setSecondVideoRef={setSecondVideoRef}
          />
        </ScanSizer>
      </DialogContent>
      <DialogActions sx={{ p: 2, pt: 0 }}>
        <ButtonStack sx={{ flexGrow: 0, justifyContent: 'end', pb: 3 }}>
          <Fab color="primary" onClick={handleTakePhotoClick} size="large" sx={{ margin: 'auto' }}>
            <CameraIcon />
          </Fab>
        </ButtonStack>
      </DialogActions>
    </>
  );
};
