import {
  Guest,
  QueryRandomGuestArgs,
  TicketCheckInStatus,
} from '../../../gql/__generated__/graphql';
import { GuestService } from '../../services/GuestService';
import { addTypename } from './addTypename';
import { Resolver } from '@apollo/client';
import { Container } from 'typedi';

export const getRandomGuestResolver: Resolver = async (
  parent: null,
  { ticketStatus }: QueryRandomGuestArgs,
): Promise<Guest> => {
  const guestService = Container.get(GuestService);
  return addTypename<Guest>(guestService.random(ticketStatus), 'Guest');
};
