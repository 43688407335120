import { GuestTicket } from '../../components/guest/GuestTicket';
import { getUnscannedTickets } from '../../data/utils/guest.util';
import {
  CreateManualScanDocument,
  CreateManualScanMutation,
  Guest,
  useGetGuestQuery,
} from '../../gql/__generated__/graphql';
import { routes } from '../../routes';
import { useApolloClient } from '@apollo/client';
import { ButtonStack } from '@greatcrowd/ui-button';
import { useLogger } from '@greatcrowd/ui-logging';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { FC, useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

interface LookupGuestsDetailViewProps {}

export const LookupGuestsDetailView: FC<LookupGuestsDetailViewProps> = () => {
  const client = useApolloClient();
  const logger = useLogger(LookupGuestsDetailView.name);
  const navigate = useNavigate();
  const { guestId: _localGuestId } = useParams();

  const handleCheckIn = useCallback(() => {
    // Because we don't have an explicit scan associated with the guest, we generate
    // a scan for a random, unscanned ticket for use during the workflow
    (async () => {
      const { data, errors } = await client.mutate<CreateManualScanMutation>({
        mutation: CreateManualScanDocument,
        variables: { _localGuestId },
      });

      if (errors) {
        logger.error('Failed to create scan for random ticket', errors, { _localGuestId });
        return;
      }

      navigate(routes.lookupGuestsCheckIn({ scanId: data.createManualScan.id }));
    })();
  }, [_localGuestId, client, logger, navigate]);

  const { data, loading } = useGetGuestQuery({
    variables: { guestId: _localGuestId },
  });

  if (loading) {
    return null;
  }

  const guest = data?.guest as Guest;
  const unscannedTickets = getUnscannedTickets(guest);

  // `scanMode` is hard-coded to "party" because in the scenario where we look up a guest
  // we don't have a way to individually select tickets for check-in
  return (
    <Stack direction="column" spacing={1} sx={{ height: '100%', p: 2 }}>
      <Toolbar sx={{ height: '40px', minHeight: '40px', px: 0 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 0,
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <IconButton
            aria-label="return to guest list"
            color="inherit"
            component={Link}
            edge="start"
            to={routes.lookupGuests()}
            size="small"
          >
            <ChevronLeft />
          </IconButton>
        </Box>
      </Toolbar>
      <Stack direction="column" sx={{ flexGrow: 1, justifyContent: 'space-between', pb: 3 }}>
        <GuestTicket guest={guest} />
        <ButtonStack>
          {unscannedTickets?.length ? (
            <Button color="primary" onClick={handleCheckIn} size="large" variant="contained">
              Start check-in
            </Button>
          ) : (
            <Button
              color="primary"
              disabled
              endIcon={<HowToRegIcon />}
              size="large"
              variant="contained"
            >
              All checked in
            </Button>
          )}
        </ButtonStack>
      </Stack>
    </Stack>
  );
};
