import { GuestTicket } from '../../components/guest/GuestTicket';
import { AttendeeWaiverCollection } from '../../components/scanning/AttendeeWaiverCollection.enum';
import { WaiverCollectionCard } from '../../components/waiver/WaiverCollectionCard';
import { WorkflowStep, WorkflowStepProps } from '../../components/workflow/WorkflowStep.interface';
import { WorkflowStepLayout } from '../../components/workflow/WorkflowStepLayout';
import { ScanError } from '../../gql/__generated__/graphql';
import { CheckInWorkflowContext } from './CheckInWorkflowContext.interface';
import { FinishCheckInButton } from './FinishCheckInButton';
import { ButtonStack } from '@greatcrowd/ui-button';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { FC, useCallback, useEffect, useState } from 'react';

export interface WaiverCollectionStepProps extends WorkflowStepProps<CheckInWorkflowContext> {}

const isValid = ({ quantity, waiverCollection, waivers }: CheckInWorkflowContext): boolean => {
  return !!(
    (waiverCollection === AttendeeWaiverCollection.ONE_PER_ATTENDEE &&
      waivers.length >= quantity) ||
    (waiverCollection === AttendeeWaiverCollection.ONE_PER_PARTY && waivers.length)
  );
};

export const WaiverCollectionStep: FC<WaiverCollectionStepProps> = ({
  context,
  onAbort,
  onBack,
  onNext,
  onSubmit,
  setContext,
  stepIndex,
  steps,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (context.abortReason) {
      // noinspection JSIgnoredPromiseFromCall
      onAbort?.();
    }
  }, [context.abortReason, onAbort]);

  const handleCancel = useCallback(async () => {
    setContext({
      ...context,
      abortReason: ScanError.CancelledNoWaiver,
    });
  }, [context, setContext]);

  const handleConfirmWaiver = useCallback(
    async (imageData: string) => {
      setContext({
        ...context,
        waivers: context.waivers.concat({ imageData }),
      });
    },
    [context, setContext],
  );

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    await onSubmit();
    await onNext();
  }, [onNext, onSubmit, setLoading]);

  const isNextToLastStep = steps.length - 2 === stepIndex;

  return (
    <WorkflowStepLayout onBack={onBack} steps={steps} stepIndex={stepIndex} title="Collect Waivers">
      <Stack direction="column" sx={{ flexGrow: 1 }}>
        <WaiverCollectionCard
          collection={context.waiverCollection}
          disabled={loading}
          onCancel={handleCancel}
          onConfirm={handleConfirmWaiver}
          quantity={context.quantity}
          waivers={context.waivers}
        />
      </Stack>
      <Stack direction="column" spacing={2} sx={{ flexGrow: 0 }}>
        <GuestTicket guest={context.guest} />

        <ButtonStack sx={{ flexGrow: 0, pb: 3 }}>
          {isNextToLastStep ? (
            <FinishCheckInButton
              disabled={!isValid(context)}
              loading={loading}
              onClick={handleSubmit}
            />
          ) : (
            <Button
              color="primary"
              disabled={!isValid(context) || loading}
              onClick={onNext}
              size="large"
              variant="contained"
            >
              Next
            </Button>
          )}
        </ButtonStack>
      </Stack>
    </WorkflowStepLayout>
  );
};

export const WaiverCollectionStepDefinition: WorkflowStep = {
  Component: WaiverCollectionStep,
  id: 'waiver',
};
