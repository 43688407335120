import { ActionData } from './ActionData';
import { GuestAction } from './GuestAction';
import { GuestActionType } from './GuestActionType.enum';

export enum WorkerMode {
  SYNC = 'sync',
  SCAN = 'scan',
}

export interface InitializeWorkerActionData extends ActionData {
  mode: WorkerMode;
  port: MessagePort;
}

export class InitializeWorkerAction extends GuestAction<InitializeWorkerActionData> {
  data: InitializeWorkerActionData;
  type = GuestActionType.INITIALIZE_WORKER;

  constructor(port: MessagePort, mode: WorkerMode) {
    super();

    this.data = {
      mode,
      port,
    };
  }

  static from(data: InitializeWorkerActionData): InitializeWorkerAction {
    return new InitializeWorkerAction(data.port, data.mode);
  }
}
