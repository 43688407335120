import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { ToolbarProps } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

export interface BasicToolbarProps extends ToolbarProps {
  back: string;

  backAriaLabel?: string;

  children?: ReactNode;
}

export const BasicToolbar: FC<BasicToolbarProps> = ({
  back,
  backAriaLabel,
  children,
  sx,
  ...props
}) => {
  return (
    <Toolbar {...props} sx={{ height: '40px', minHeight: '40px', p: 0, ...sx }}>
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <IconButton
          aria-label={backAriaLabel ?? 'return to previous view'}
          color="inherit"
          component={Link}
          edge="start"
          to={back}
          size="small"
        >
          <ChevronLeft />
        </IconButton>
        {children}
      </Stack>
    </Toolbar>
  );
};
