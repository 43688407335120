import {
  CheckInMetrics,
  ScanErrorMetrics,
  ScanMethod,
  ScanTimeSeriesData,
} from '../../../gql/__generated__/graphql';
import { ScanService } from '../../services/ScanService';
import { TicketService } from '../../services/TicketService';
import { addTypename } from './addTypename';
import { Resolvers } from '@apollo/client';
import { Container } from 'typedi';

export const scanMetricsResolver: Resolvers = {
  ScanMetrics: {
    checkIns: async (): Promise<CheckInMetrics> => {
      const scanService = Container.get(ScanService);
      const ticketService = Container.get(TicketService);

      const manual = await scanService.countBy({ method: ScanMethod.Manual, error: null });
      const scan = await scanService.countBy({ method: ScanMethod.Scan, error: null });
      const none = (await ticketService.getSyncDownMetrics()).total - manual - scan;

      return {
        __typename: 'CheckInMetrics',
        manual,
        none,
        scan,
      };
    },
    errors: async (): Promise<ScanErrorMetrics[]> => {
      const scanService = Container.get(ScanService);

      return addTypename<ScanErrorMetrics[]>(scanService.getScanErrorsByType(), 'ScanErrorMetrics');
    },
    timeseries: async (): Promise<ScanTimeSeriesData[]> => {
      const scanService = Container.get(ScanService);

      return addTypename<ScanTimeSeriesData[]>(
        scanService.getScansOverTime(),
        'ScanTimeSeriesData',
      );
    },
  },
};
