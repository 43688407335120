import nleBannerUrl from '../../assets/nle_banner.jpg';
import { AppLayout } from '../../components/AppLayout';
import { CantScanButton } from '../../components/scanning/CantScanButton';
import { useEvents } from '../../hooks/useEvents';
import { useGuestData } from '../../hooks/useGuestData';
import { routes } from '../../routes';
import { ButtonStack } from '@greatcrowd/ui-button';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';
import { FormattedDate } from 'react-intl';
import { Link } from 'react-router-dom';

export const ScanningHomeView: FC = () => {
  const theme = useTheme();
  const { events, eventsToday, loading } = useEvents();
  const { appConfig } = useGuestData();
  const friendlyName = appConfig?.scanner?.friendlyName;

  if (loading) {
    return null;
  }

  return (
    <AppLayout showAppBar={false} showBottomNavigation>
      <Stack direction="column" sx={{ height: '100%', width: '100%' }}>
        <Container
          sx={{
            backgroundImage: `url(${nleBannerUrl})`,
            backgroundColor: theme.palette.grey[700],
            flexGrow: 1,
            p: 2,
          }}
        >
          <Paper elevation={0} sx={{ height: '100%', p: 1, width: '100%' }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Stack
                direction="column"
                spacing={2}
                sx={{
                  flexGrow: 0,
                  pt: 2,
                  textAlign: 'center',
                }}
              >
                <Typography variant="body1">Scanning Tickets For</Typography>
                <Stack direction="column" spacing={1}>
                  {events?.length ? (
                    eventsToday?.length ? (
                      eventsToday.map((event) => (
                        <Typography key={event.id} component="p" variant="h4">
                          {event.name}
                        </Typography>
                      ))
                    ) : (
                      <Typography component="p" variant="h4">
                        No events today
                      </Typography>
                    )
                  ) : (
                    <Typography component="p" variant="h4">
                      Not subscribed to any events
                    </Typography>
                  )}
                </Stack>
                <Typography
                  sx={{ fontWeight: theme.typography.fontWeightBold }}
                  variant="subtitle2"
                >
                  <FormattedDate dateStyle="long" value={new Date()} />
                </Typography>
              </Stack>
              <Stack direction="column" spacing={2} sx={{ alignItems: 'center', width: '100%' }}>
                {friendlyName && <Typography variant="caption">{friendlyName}</Typography>}
                <ButtonStack sx={{ flexGrow: 0 }}>
                  <Button
                    color="secondary"
                    component={Link}
                    disabled={!events?.length}
                    size="large"
                    sx={{ width: '100%' }}
                    variant="contained"
                    to={routes.scanningTicketsScanner()}
                  >
                    Scan Tickets
                  </Button>
                  <CantScanButton size="large" />
                </ButtonStack>
              </Stack>
            </Box>
          </Paper>
        </Container>
      </Stack>
    </AppLayout>
  );
};
