import {
  CheckInType,
  FetchTicketsDocument,
  FetchTicketsQuery,
  Guest,
  RecognizedScanData,
  RecognizedScanDataType,
  Scan,
  ScanError,
  ScanMethod,
  ScannerSettingName,
  ScannerSyncDirection,
  ScannerSyncType,
  SyncCountMetrics,
  Ticket,
  TicketStatus,
} from '../../gql/__generated__/graphql';
import { parseTicketData } from '../../utils/parseTicketData';
import { getSetting } from '../AppSettings';
import { AppConfigRepository } from '../repositories/AppConfigRepository';
import { TicketRepository } from '../repositories/TicketRepository';
import { PracticeData } from '../utils/PracticeData.enum';
import { getRecognizedScanDataConfigFromSettingString } from '../utils/recognizedScanData.util';
import { getValidScan, TicketFilter } from '../utils/ticket.util';
import { GraphQlClientService } from './GraphQlClientService';
import { GuestService } from './GuestService';
import { ScanService } from './ScanService';
import { SyncService } from './SyncService';
import { SyncableCollectionService } from './SyncableCollectionService';
import Apollo from '@apollo/client';
import { Logger } from '@greatcrowd/ui-logging';
import { Service } from 'typedi';

@Service()
export class TicketService extends SyncableCollectionService<Ticket> {
  protected readonly logger = new Logger(TicketService.name);
  protected readonly defaultSortField: string = 'id';

  protected readonly scannerSyncType = ScannerSyncType.Ticket;

  protected readonly downQuery: Apollo.DocumentNode = FetchTicketsDocument;
  protected readonly downDataSelector = (result: FetchTicketsQuery) => result.tickets;
  protected readonly settingDownEnabled: ScannerSettingName =
    ScannerSettingName.SyncTicketsDownEnabled;
  protected readonly settingDownLimit: ScannerSettingName = ScannerSettingName.SyncTicketsDownLimit;
  protected readonly settingDownInterval: ScannerSettingName =
    ScannerSettingName.SyncTicketsDownInterval;

  protected readonly upMutation: Apollo.DocumentNode = undefined;

  constructor(
    protected readonly repository: TicketRepository,
    private readonly scanService: ScanService,
    private readonly guestService: GuestService,
    readonly syncService: SyncService,
    readonly graphqlClientService: GraphQlClientService,
    readonly appConfigRepository: AppConfigRepository,
  ) {
    super(syncService, graphqlClientService, appConfigRepository);
  }

  /**
   * Reduces tickets from Great Crowd backend to ticket and guest state in IndexedDB
   * tables
   *
   * @param tickets
   */
  protected async reduce(tickets: Ticket[]): Promise<void> {
    const guests = tickets.reduce<{ [key: string]: Guest }>((acc, curr) => {
      const email = curr.claim?.email?.toLowerCase();
      const guestId = (
        email ||
        curr.ticketOrderLineItem?.lineItem?.order?.number ||
        '__NO_EMAIL__'
      ).toLowerCase();
      acc[guestId] = {
        email: guestId,
        guestId,
        givenName: curr.claim?.name?.split(' ')[0] || '',
        phone: curr.claim?.phone,
        surname: curr.claim?.name?.split(' ')[1] || '',
      };
      return acc;
    }, {});

    await this.guestService.createMany(Object.values(guests));

    for (const ticket of tickets) {
      const email = ticket.claim?.email?.toLowerCase();
      const guestId = (
        email ||
        ticket.ticketOrderLineItem?.lineItem?.order?.number ||
        '__NO_EMAIL__'
      ).toLowerCase();

      await this.repository.update({
        ...ticket,
        _externalId: ticket.externalResource?.externalId,
        guestId,
        ticketOrderLineItem: ticket.ticketOrderLineItem,
      });
      // Update scanned timestamp based on data on the device
      await this.scanService.updateScanTimestamp(ticket);
    }
  }

  private async checkIn(ticket: Ticket, scan?: Scan): Promise<void> {
    // TODO: this needs to be planned out more robustly
    // Ticket was marked as scanned by another scanner and the data arrived between the initiation
    // of the check-in process and completion
    if (ticket.scanned) {
      scan.error = ScanError.AlreadyScanned;
      await this.scanService.update(scan);
      return;
    }

    ticket.scanned = scan.timestamp;
    await this.update(ticket);
  }

  private async getRandomUnscannedTicketForGuest(_localGuestId: string): Promise<Ticket> {
    const tickets = await this.listByFk({ guestId: _localGuestId });
    const unscannedTickets = tickets.filter((ticket) => !ticket.scanned);

    if (!unscannedTickets?.length) {
      return null;
    }

    return unscannedTickets[0];
  }

  /**
   * Cancels a check-in for the given reason by creating another scan with an error
   * @param scanId
   * @param error
   */
  async abortCheckIn(scanId: string, error: ScanError = ScanError.Cancelled): Promise<Scan> {
    const scan = await this.scanService.findOneById(scanId);

    return this.scanService.createErrorScan(scan.url, error, scan.method, scan.type, scan.ticketId);
  }

  /**
   * Check-ins occur during the onSubmit event of the CheckInWorkflow component. Check-ins
   * can occur either through explicit scan or manual lookup.
   * @param scanId
   * @param quantity
   */
  async checkInGuest(scanId: string, quantity: number = 1): Promise<number> {
    const scan = await this.scanService.findOneById(scanId);
    const scannedTicket = await this.findOne(scan.ticketId);
    const tickets = await this.listByFk({ guestId: scannedTicket.guestId });
    const additionalTicketsToScan = quantity - 1;

    const unscannedTickets = tickets
      .filter((ticket) => !ticket.scanned && ticket.id !== scannedTicket.id)
      .slice(0, additionalTicketsToScan) as Ticket[];

    if (unscannedTickets.length < additionalTicketsToScan) {
      this.logger.error('Not enough additional tickets to check in', null, {
        available: unscannedTickets.length,
        requested: additionalTicketsToScan,
      });

      return 0;
    }

    // Mark the explicitly scanned ticket as checked in. The ticket was already "scanned"
    await this.checkIn(scannedTicket, scan);

    // Implicitly scan and check-in the other tickets
    for (const unscannedTicket of unscannedTickets) {
      const implicitScan = await this.scan(
        unscannedTicket,
        scan.method,
        CheckInType.Implicit,
        scan.url,
      );
      await this.checkIn(unscannedTicket, implicitScan);
    }

    return quantity;
  }

  async countBy(filter?: TicketFilter): Promise<number> {
    return this.repository.countBy(filter);
  }

  /**
   * Creates a scan of a ticket. DOES NOT mark the ticket as checked in
   * @param ticket
   * @param method
   * @param type
   * @param url
   */
  async scan(ticket: Ticket, method: ScanMethod, type: CheckInType, url?: string): Promise<Scan> {
    let error: ScanError = null;

    const timestamp = new Date().toISOString();

    if (ticket.scanned) {
      error = ScanError.AlreadyScanned;
    }

    this.logger.debug('New scan', { error, method, ticket: ticket.id, timestamp, type, url });
    const scanId = await this.scanService.create({
      error,
      method,
      ticketId: ticket.id,
      timestamp: timestamp,
      type,
      url,
    });

    return this.scanService.findOne(scanId);
  }

  async swapImplicitCheckIn(
    implicitlyScannedTicket: Ticket,
    unscannedTicket: Ticket,
    data: string,
  ): Promise<void> {
    // Clear "Ticket.scanned"
    implicitlyScannedTicket.scanned = null;
    await this.repository.update(implicitlyScannedTicket);

    // Mark unscanned ticket as implicitly scanned
    const implicitScan = await this.scan(
      unscannedTicket,
      ScanMethod.Scan,
      CheckInType.Implicit,
      data,
    );
    await this.checkIn(unscannedTicket, implicitScan);
  }

  /**
   * Create a scan for an explicitly scanned ticket
   * @param data
   */
  async scanRawTicketData(data: string): Promise<Scan> {
    const appConfig = await this.appConfigRepository.single();
    const recognizedScanDataSetting = getSetting<string>(
      appConfig,
      ScannerSettingName.ScanDataRecognized,
    );
    const recognizedScanDataSettings: RecognizedScanData[] =
      getRecognizedScanDataConfigFromSettingString(recognizedScanDataSetting);

    if (Object.values(PracticeData).includes(data as PracticeData)) {
      return this.scanService.createErrorScan(
        data,
        ScanError.PracticeTicket,
        ScanMethod.Scan,
        CheckInType.Explicit,
        null,
      );
    }

    const ticketData = recognizedScanDataSettings
      .filter((setting) => setting.type === RecognizedScanDataType.Ticket)
      .map((setting) => {
        try {
          return parseTicketData(data, new RegExp(setting.regex));
        } catch (error) {
          this.logger.error('Failed to test ticket data using recognizedScanDataSetting', error, {
            setting,
          });
        }
        return null;
      })
      .find((ticketData) => !!ticketData);

    // Ticket data could not be parsed
    if (!ticketData) {
      return this.scanService.createErrorScan(
        data,
        ScanError.InvalidTicket,
        ScanMethod.Scan,
        null,
        null,
      );
    }

    // Lookup ticket by the unique secret
    const ticket = ticketData.secret
      ? await this.findOneBy({ code: ticketData.secret })
      : await this.findOneBy({ _externalId: ticketData.externalTicketId });

    // Ticket was not found in local database
    if (!ticket) {
      return this.scanService.createErrorScan(
        data,
        ScanError.UnknownTicket,
        ScanMethod.Scan,
        null,
        null,
      );
    }

    if (ticket.status === TicketStatus.Canceled) {
      return this.scanService.createErrorScan(
        data,
        ScanError.InvalidTicket,
        ScanMethod.Scan,
        null,
        ticket.id,
      );
    }

    // Ticket was previously scanned
    if (ticket.scanned) {
      // If the ticket was implicitly scanned (either through party scan or manual check-in), then
      // we check if another ticket has not been scanned. If another ticket is available, we move
      // the implicit scan to that ticket and explicitly scan the current ticket.
      const scans = await this.scanService.listByFk({ ticketId: ticket.id });
      const scan = getValidScan(scans);
      const tickets = await this.listByFk({ guestId: ticket.guestId });
      const availableTickets = tickets.filter((ticket) => !ticket.scanned);

      // This ticket was explicitly scanned or all ticket have already been checked in
      if (scan.type === CheckInType.Explicit || !availableTickets.length) {
        return this.scanService.createErrorScan(
          data,
          ScanError.AlreadyScanned,
          ScanMethod.Scan,
          null,
          ticket.id,
        );
      }

      // Swap the implicit scan to another ticket
      await this.swapImplicitCheckIn(ticket, availableTickets[0], data);
    }

    // Ticket can be scanned
    return this.scan(ticket, ScanMethod.Scan, CheckInType.Explicit, data);
  }

  /**
   * Create a scan for one of the guest's unscanned tickets at random. Only used
   * if manual check-in is enabled
   * @param _localGuestId
   */
  async scanRandomTicketManually(_localGuestId: string): Promise<Scan> {
    const ticket = await this.getRandomUnscannedTicketForGuest(_localGuestId);

    return this.scan(ticket, ScanMethod.Manual, CheckInType.Implicit, null);
  }

  async getSyncDownMetrics(): Promise<SyncCountMetrics> {
    const lastSyncDown = await this.syncService.lastSuccessful(
      ScannerSyncType.Ticket,
      ScannerSyncDirection.Down,
    );

    return {
      total: lastSyncDown?.total ?? 0,
      totalSynced: parseInt(lastSyncDown?.cursor ?? '0') + (lastSyncDown?.returned ?? 0),
    };
  }
}
