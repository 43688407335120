import { Scan, ScanError, Ticket } from '../../gql/__generated__/graphql';
import { compareScans } from './scan.util';

export const getValidScan = (scans: Scan[]): Scan => {
  const validScans = scans?.filter((scan) => !scan.error);

  if (!validScans?.length) {
    return null;
  }

  validScans.sort(compareScans);

  return validScans[0];
};

export const getValidScanFromTicket = (ticket: Ticket) => {
  return getValidScan(ticket._localScans);
};

export interface TicketFilter {
  scanned?: boolean;
}

export type TicketPredicate = (ticket: Ticket) => boolean;

export const getPredicate = (filter: TicketFilter): TicketPredicate => {
  return (ticket: Ticket) =>
    (!filter.scanned && !ticket.scanned) || (filter.scanned && !!ticket.scanned);
};

export const getScannedTimestamp = (scans: Scan[]): string => {
  if (!scans?.length) {
    return null;
  }

  const scansByDevice = scans.reduce<{ [key: string]: string }>((acc, curr) => {
    acc[curr.scannerId as string] = null;
    return acc;
  }, {});

  scans.sort(
    (scan1: Scan, scan2: Scan) =>
      new Date(scan1.timestamp).getTime() - new Date(scan2.timestamp).getTime(),
  );

  scans.forEach((scan) => {
    switch (scan.error) {
      case null:
      case undefined:
      case ScanError.AlreadyScanned:
      case ScanError.UnknownTicket:
        scansByDevice[scan.scannerId] = scan.timestamp;
        break;
      case ScanError.Cancelled:
      case ScanError.CancelledNoWaiver:
        scansByDevice[scan.scannerId] = null;
        break;
      case ScanError.InvalidTicket:
      default:
        break;
    }
  });

  // Any timestamp here indicates that the ticket was checked in on at least one device
  return Object.values(scansByDevice).find((timestamp) => !!timestamp) ?? null;
};
