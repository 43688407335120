import { GetGuestsQueryVariables, Guest } from '../../../gql/__generated__/graphql';
import { GuestService } from '../../services/GuestService';
import { addTypename } from './addTypename';
import { Resolver } from '@apollo/client';
import { Container } from 'typedi';

export const getGuestsResolver: Resolver = async (
  _: null,
  { limit, offset, search }: GetGuestsQueryVariables,
): Promise<Guest[]> => {
  const guestService = Container.get(GuestService);

  return addTypename<Guest[]>(guestService.search(limit, offset, search), 'Guest');
};
