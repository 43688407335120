import { ScanMode } from './ScanMode.enum';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup, { ToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup';
import { FC } from 'react';

interface PartyScanToggleProps extends ToggleButtonGroupProps {
  value?: ScanMode;
}

export const PartyScanToggle: FC<PartyScanToggleProps> = ({
  onChange,
  value = 'party',
  ...props
}) => {
  return (
    <ToggleButtonGroup
      aria-label="Scanner mode"
      color="primary"
      exclusive
      onChange={onChange}
      size="small"
      value={value}
      {...props}
    >
      <ToggleButton value="single">Single Ticket</ToggleButton>
      <ToggleButton value="party">Multiple Tickets</ToggleButton>
    </ToggleButtonGroup>
  );
};
