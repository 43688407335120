import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { FC, ReactNode } from 'react';

export interface ScanningResultLayoutProps {
  children: ReactNode;

  title: string;
}

export const ScanningResultLayout: FC<ScanningResultLayoutProps> = ({ children, title }) => {
  const theme = useTheme();

  return (
    <>
      <Typography
        component="h1"
        sx={{ fontWeight: theme.typography.fontWeightMedium, mb: 2 }}
        variant="h4"
      >
        {title}
      </Typography>
      <Stack direction="column" spacing={2}>
        {children}
      </Stack>
    </>
  );
};
