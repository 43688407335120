import { WorkflowRunnerStepper } from './WorkflowRunnerStepper';
import { WorkflowStep } from './WorkflowStep.interface';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { FC } from 'react';

export interface WorkflowRunnerToolbarProps {
  darkMode?: boolean;

  disableStepper?: boolean;

  onBack?: () => void;

  stepIndex: number;

  steps: WorkflowStep[];
}

export const WorkflowRunnerToolbar: FC<WorkflowRunnerToolbarProps> = ({
  darkMode = false,
  disableStepper = false,
  onBack,
  stepIndex,
  steps,
}) => {
  return (
    <Toolbar sx={{ height: '40px', minHeight: '40px', px: 0 }}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        {onBack ? (
          <IconButton
            aria-label="return to previous step"
            color="inherit"
            edge="start"
            onClick={onBack}
            size="small"
          >
            <ChevronLeft />
          </IconButton>
        ) : (
          <span />
        )}
        {!disableStepper && (
          <WorkflowRunnerStepper darkMode={darkMode} steps={steps} stepIndex={stepIndex} />
        )}
      </Box>
    </Toolbar>
  );
};
