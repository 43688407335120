import { ScanTimeSeriesData } from '../../gql/__generated__/graphql';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { BarPlot, ChartContainer } from '@mui/x-charts';
import { FC } from 'react';
import { FormattedNumber } from 'react-intl';

export interface ScansOverTimeMetricProps {
  data: ScanTimeSeriesData[];
}

export const ScansOverTimeMetric: FC<ScansOverTimeMetricProps> = ({ data }) => {
  const theme = useTheme();

  return (
    <Paper sx={{ borderColor: theme.palette.divider, borderWidth: 1, borderStyle: 'solid', p: 2 }}>
      <Stack sx={{ alignItems: 'center', textAlign: 'center' }}>
        <Typography variant="subtitle1">Scans Over Time</Typography>
        <Typography component="h3" variant="h5">
          Last 3 Hours
        </Typography>
        <Typography sx={{ color: theme.palette.grey[600] }} variant="overline">
          All Scanners
        </Typography>

        <ChartContainer
          colors={[theme.palette.primary.main, theme.palette.error.main]}
          width={250}
          height={150}
          margin={{
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          }}
          series={[
            {
              data: data.map((scan) => scan.count),
              label: 'Check-in',
              stack: 'total',
              type: 'bar',
            },
            { data: data.map((scan) => scan.error), label: 'Error', stack: 'total', type: 'bar' },
          ]}
          xAxis={[{ scaleType: 'band', data: data.map((scan) => scan.time) }]}
        >
          <BarPlot />
        </ChartContainer>
      </Stack>
    </Paper>
  );
};
