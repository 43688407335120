import { AdminLayout } from '../../components/admin/AdminLayout';
import { AdminLayoutContent } from '../../components/admin/AdminLayoutContent';
import { AdminLayoutTitle } from '../../components/admin/AdminLayoutTitle';
import {
  CheckInMetrics,
  ScanErrorMetrics,
  ScanTimeSeriesData,
  useGetMetricsViewDataQuery,
} from '../../gql/__generated__/graphql';
import { routes } from '../../routes';
import { ErrorsByTypeMetric } from '../metrics/ErrorsByTypeMetric';
import { ScansByTypeMetric } from '../metrics/ScansByTypeMetric';
import { ScansOverTimeMetric } from '../metrics/ScansOverTimeMetric';
import { useLogger } from '@greatcrowd/ui-logging';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

export const AdminDebugMetricsView: FC = () => {
  const logger = useLogger(AdminDebugMetricsView.name);

  const { data, error, loading } = useGetMetricsViewDataQuery({
    variables: {
      scannerId: '9b39a24f-f03c-418a-a101-51e0077f3e2b',
    },
  });

  if (error) {
    logger.error('Failed to load MetricsView metrics', error);
  }

  if (!data?.metrics?.scan || loading) {
    return null;
  }

  return (
    <AdminLayout ToolbarProps={{ back: routes.adminDebug() }}>
      <AdminLayoutTitle>Metrics</AdminLayoutTitle>
      <AdminLayoutContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflowX: 'auto' }}>
          <Stack direction="column" spacing={2}>
            <ScansByTypeMetric
              data={data.metrics.scan.checkIns as CheckInMetrics}
              ticketCount={data.metrics.ticketCount}
            />
            <ScansOverTimeMetric data={data.metrics.scan.timeseries as ScanTimeSeriesData[]} />
            <ErrorsByTypeMetric data={data.metrics.scan.errors as ScanErrorMetrics[]} />
          </Stack>
        </Box>
      </AdminLayoutContent>
    </AdminLayout>
  );
};
