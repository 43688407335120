import {
  WorkflowStep,
  WorkflowStepProps,
} from '../../../components/workflow/WorkflowStep.interface';
import { WorkflowStepLayout } from '../../../components/workflow/WorkflowStepLayout';
import { SyncDataContext } from '../SyncDataContext.interface';
import { ButtonStack } from '@greatcrowd/ui-button';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, FC, useCallback } from 'react';

export interface UnsyncedDataStepProps extends WorkflowStepProps<SyncDataContext> {}

export const UnsyncedDataStep: FC<UnsyncedDataStepProps> = ({
  context,
  onAbort,
  onNext,
  onSubmit,
  setContext,
  stepIndex,
  steps,
}) => {
  const handleCheckboxChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      setContext({ ...context, ignoreUnsyncedData: event.target.checked });
    },
    [context, setContext],
  );

  return (
    <WorkflowStepLayout disableStepper steps={steps} stepIndex={stepIndex}>
      <Stack spacing={4} sx={{ flexGrow: 1, pt: 6 }}>
        <Typography color="error" component="h2" variant="h4">
          WARNING: Some data has not been saved
        </Typography>
        <Typography variant="body1">
          Some data has not been saved to Great Crowd. Press "Sync Data" to prevent data loss and
          save all event data.
        </Typography>
        <Stack spacing={1}>
          <Typography variant="body2">
            <i>
              To ignore this warning and continue without saving all event data, check the following
              box.
            </i>
          </Typography>
          <FormControlLabel
            control={
              <Checkbox value={context.ignoreUnsyncedData} onChange={handleCheckboxChange} />
            }
            label="I aknowledge that some event data will be lost."
          />
        </Stack>
      </Stack>
      <ButtonStack sx={{ flexGrow: 0, pb: 3 }}>
        <Button
          color={context.ignoreUnsyncedData ? 'error' : 'primary'}
          onClick={onNext}
          size="large"
          variant="contained"
        >
          {context.ignoreUnsyncedData ? 'Continue without saving' : 'Sync Data...'}
        </Button>
        <Button color="primary" onClick={onAbort} size="large" variant="outlined">
          Cancel
        </Button>
      </ButtonStack>
    </WorkflowStepLayout>
  );
};

export const UnsyncedDataStepDefinition: WorkflowStep = {
  Component: UnsyncedDataStep,
  id: 'unsynced',
};
