import GroupsIcon from '@mui/icons-material/Groups';
import Chip, { ChipProps } from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { FC } from 'react';

export interface CheckedInCountChipProps extends ChipProps {
  checkedIn?: number;

  total: number;
}

const getChipColor = (checkedIn: number, total: number): ChipProps['color'] => {
  if (!checkedIn) {
    return 'default';
  }

  return checkedIn > 0 && checkedIn === total ? 'success' : 'warning';
};

export const CheckedInCountChip: FC<CheckedInCountChipProps> = ({
  checkedIn = 0,
  total,
  ...props
}) => {
  const color = getChipColor(checkedIn, total);

  return (
    <Chip
      color={color}
      size="small"
      variant="filled"
      label={
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <GroupsIcon />
          <div>{`${checkedIn}/${total} guests checked in`}</div>
        </Stack>
      }
      {...props}
    />
  );
};
