import { Ticket } from '../../gql/__generated__/graphql';
import { generateTicketUrl } from '../../utils/generateTicketUrl';
import { GeneratedQrCode, GeneratedQrCodeProps } from '@greatcrowd/ui-qr';
import { FC } from 'react';

interface TicketQrCodeProps extends Partial<GeneratedQrCodeProps> {
  ticket: Ticket;
}

export const TicketQrCode: FC<TicketQrCodeProps> = ({ ticket, ...props }) => {
  return <GeneratedQrCode data={generateTicketUrl(ticket).toString()} {...props} />;
};
