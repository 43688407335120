import { ButtonStack } from '@greatcrowd/ui-button';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { MouseEventHandler } from 'react';

interface NoWaiverDialogProps extends DialogProps {
  onCancel: () => void;
}

export const NoWaiverDialog = ({ onCancel, ...props }: NoWaiverDialogProps) => {
  const theme = useTheme();

  return (
    <Dialog
      {...props}
      PaperProps={{ sx: { borderRadius: theme.shape.borderRadius, p: 2 } }}
      sx={{ textAlign: 'center' }}
    >
      <DialogTitle sx={{ p: 0, textAlign: 'center' }}>Guest Does Not Have a Waiver</DialogTitle>
      <DialogContent sx={{ p: 0, py: 3 }}>
        <DialogContentText>
          <Typography variant="body2">Each guest must fill out a waiver.</Typography>
          <br />
          <Typography variant="body2">
            Once the guest has filled out a waiver, re-scan their ticket to continue.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 0 }}>
        <ButtonStack>
          <Button color="secondary" onClick={onCancel} variant="contained" size="large">
            Back to Scanner
          </Button>
          <Button
            color="inherit"
            onClick={props.onClose as MouseEventHandler<HTMLButtonElement>}
            size="large"
          >
            Back
          </Button>
        </ButtonStack>
      </DialogActions>
    </Dialog>
  );
};
