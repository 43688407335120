import { routeFactory } from '@greatcrowd/ui-routing';

export const routes = {
  admin: routeFactory('/admin'),
  adminChangeEvent: routeFactory('/admin/event'),
  adminDebug: routeFactory('/admin/debug'),
  adminDebugAuth: routeFactory('/admin/debug/auth'),
  adminDebugDevice: routeFactory('/admin/debug/device'),
  adminDebugExport: routeFactory('/admin/debug/export'),
  adminDebugMetrics: routeFactory('/admin/debug/metrics'),
  adminDebugSettings: routeFactory('/admin/debug/settings'),
  adminDebugTicket: routeFactory('/admin/debug/tickets'),
  adminDebugUpdate: routeFactory('/admin/debug/update'),
  adminDebugWaiver: routeFactory('/admin/debug/waiver'),
  adminDebugWipe: routeFactory('/admin/debug/wipe'),
  adminLogin: routeFactory('/admin/login'),
  adminSettings: routeFactory('/admin/settings'),
  home: routeFactory('/'),
  linkScannerConfirm: routeFactory('/link/confirm'),
  linkScannerScan: routeFactory('/link/scan'),
  lookupGuests: routeFactory('/lookup'),
  lookupGuestsCheckIn: routeFactory('/lookup/:scanId/check-in'),
  lookupGuestsDetail: routeFactory('/lookup/:guestId'),
  metrics: routeFactory('/metrics'),
  scanningHome: routeFactory('/scan'),
  scanningError: routeFactory('/scanning/error/:error'),
  scanningTicketsScanner: routeFactory('/scanning'),
  scanningTicketsCheckIn: routeFactory('/scanning/:scanId/:scanMode/check-in'),
  scanningTicketsNote: routeFactory('/scanning/notes/:id'),
  scanningTicketsResult: routeFactory('/scanning/:scanId/result'),
};
