import Box from '@mui/material/Box';
import { FC, ReactNode } from 'react';

interface AdminLayoutFooterProps {
  children: ReactNode;
}

export const AdminLayoutFooter: FC<AdminLayoutFooterProps> = ({ children }) => {
  return <Box sx={{ justifySelf: 'end', pb: 6 }}>{children}</Box>;
};
