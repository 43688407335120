import {
  Guest,
  RecognizedScanData,
  RecognizedScanDataType,
  Ticket,
} from '../../gql/__generated__/graphql';
import { PracticeData } from './PracticeData.enum';
import snakeCase from 'lodash/snakeCase';

export const PRACTICE_RECOGNIZED_SCAN_DATA: RecognizedScanData[] = [
  {
    id: PracticeData.PRACTICE_VALID_TICKET,
    regex: `^${PracticeData.PRACTICE_VALID_TICKET}$`,
    type: RecognizedScanDataType.Practice,
    name: 'Practice valid ticket',
    message: null,
  },
  {
    id: PracticeData.PRACTICE_UNKNOWN_TICKET,
    regex: `^${PracticeData.PRACTICE_UNKNOWN_TICKET}$`,
    type: RecognizedScanDataType.Practice,
    name: 'Practice unknown ticket',
    message: null,
  },
  {
    id: PracticeData.PRACTICE_INVALID,
    regex: `^${PracticeData.PRACTICE_INVALID}$`,
    type: RecognizedScanDataType.Practice,
    name: 'Practice invalid',
    message: null,
  },
  {
    id: PracticeData.PRACTICE_ALREADY_SCANNED,
    regex: `^${PracticeData.PRACTICE_ALREADY_SCANNED}$`,
    type: RecognizedScanDataType.Practice,
    name: 'Practice already scanned',
    message: null,
  },
  {
    id: PracticeData.PRACTICE_SCAN_NOTE,
    regex: `^${PracticeData.PRACTICE_SCAN_NOTE}$`,
    type: RecognizedScanDataType.NonTicket,
    name: 'Practice Scan Note',
    message:
      'This is a scan note. When you see a screen like this, read and follow the instructions here 👈.',
  },
];

export const getRecognizedScanDataConfigFromSettingString = (
  recognizedScanSetting: string,
): RecognizedScanData[] => {
  const recognizedScanData = JSON.parse(recognizedScanSetting);

  return [
    ...recognizedScanData,
    // All scanners should have the static practice data
    ...PRACTICE_RECOGNIZED_SCAN_DATA,
  ];
};

export const getRecognizedScanDataFromRawData = (
  recognizedScanSetting: string,
  rawData: string,
): RecognizedScanData => {
  const settings = getRecognizedScanDataConfigFromSettingString(recognizedScanSetting);

  return settings.find((recognized: RecognizedScanData) => {
    const regex = new RegExp(recognized.regex);
    return regex.test(rawData);
  });
};

export const getRecognizedScanDataId = (recognizedScanData: RecognizedScanData): string => {
  return (recognizedScanData.id ?? snakeCase(recognizedScanData.name)).toUpperCase();
};

export const getRecognizedScanDataById = (
  recognizedScanSetting: string,
  id: string,
): RecognizedScanData => {
  const settings = getRecognizedScanDataConfigFromSettingString(recognizedScanSetting);

  return settings.find(
    (recognized: RecognizedScanData) => getRecognizedScanDataId(recognized) === id,
  );
};

const getPracticeTicket = (guest: Guest): Ticket => {
  return {
    __typename: 'Ticket',
    checkInStatus: undefined,
    claim: null,
    code: '4afa5b24de57',
    created: '2023-10-11T12:00:00.000Z',
    event: null,
    eventId: null,
    guest: guest,
    guestId: guest.email,
    // CAUTION: this ID is known by the backend services to as a practice ticket that should be
    // ignored. DO NOT change this value
    id: '8d97abb9-7b95-41d6-85ad-0c367414948a',
    organizationId: null,
    status: null,
    uniqueId: '',
    updated: '2023-10-11T12:00:00.000Z',
  };
};

export const PRACTICE_GUEST_EMAIL = 'practice@test.greatcrowd.io';

export const getPracticeGuest = (ticketCount: number = 5): Guest => {
  const guest: Guest = {
    email: PRACTICE_GUEST_EMAIL,
    givenName: 'Practice',
    guestId: PRACTICE_GUEST_EMAIL,
    surname: 'Guest',
  };

  guest.tickets = new Array(ticketCount).fill(0).map((_) => getPracticeTicket(guest));

  return guest;
};
