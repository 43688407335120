import { Ticket } from '../../gql/__generated__/graphql';
import { GreatCrowdScannerDatabase } from '../GreatCrowdScannerDatabase';
import { getPredicate, TicketFilter } from '../utils/ticket.util';
import { DexieRepository } from './DexieRepository';
import { Table } from 'dexie';
import { Service } from 'typedi';

@Service()
export class TicketRepository extends DexieRepository<Ticket> {
  protected readonly table: Table<Ticket>;

  constructor(db: GreatCrowdScannerDatabase) {
    super();

    this.table = db.tickets;
  }

  async countBy(filter?: TicketFilter): Promise<number> {
    if (!filter) {
      return this.count();
    }

    return this.table.filter(getPredicate(filter)).count();
  }

  async randomBy(filter: TicketFilter): Promise<Ticket> {
    const count = await this.countBy(filter);
    if (!count) {
      return null;
    }

    return (
      await this.listByPredicate(getPredicate(filter), Math.floor(Math.random() * count), 1)
    )[0];
  }
}
