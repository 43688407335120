import { AdditionalWaiverCollectionCard } from '../../../components/waiver/AdditionalWaiverCollectionCard';
import { Scan } from '../../../gql/__generated__/graphql';
import { ScanningResultErrorCard } from './ScanningResultErrorCard';
import { ScanningResultLayout } from './ScanningResultLayout';
import { FC } from 'react';

interface ScanningResultAlreadyScannedProps {
  scan: Scan;
}

export const ScanningResultAlreadyScanned: FC<ScanningResultAlreadyScannedProps> = ({ scan }) => {
  return (
    <ScanningResultLayout title="Already Scanned">
      <ScanningResultErrorCard />

      <AdditionalWaiverCollectionCard scanId={scan.id} />
    </ScanningResultLayout>
  );
};
