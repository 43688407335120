import { AppConfig } from '../../../gql/__generated__/graphql';
import { AppConfigService } from '../../services/AppConfigService';
import { addTypename } from './addTypename';
import { Resolver } from '@apollo/client';
import { Container } from 'typedi';

export const getAppConfigResolver: Resolver = async (parent: null): Promise<AppConfig> => {
  const appConfigService = Container.get(AppConfigService);

  return addTypename<AppConfig>(appConfigService.single(), 'AppConfiguration');
};
