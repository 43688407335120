import { ScanError } from '../../gql/__generated__/graphql';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { FormattedNumber } from 'react-intl';

export interface ErrorMetricLegendProps {
  color: string;

  percentage: number;

  total: number;

  type: ScanError;
}

export const ErrorMetricLegend: FC<ErrorMetricLegendProps> = ({
  color,
  percentage,
  total,
  type,
  ...props
}) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ alignItems: 'center', justifyContent: 'space-between', width: '100%' }}
    >
      <Box
        component="div"
        sx={{
          backgroundColor: color,
          borderRadius: 0.5,
          height: 16,
          flexBasis: 16,
          flexGrow: 0,
          flexShrink: 0,
          width: 16,
        }}
      />
      <Typography variant="overline" sx={{ flexGrow: 1, textAlign: 'left' }}>
        {type}
      </Typography>
      <Typography variant="overline" sx={{ flexShrink: 0, textAlign: 'end' }}>
        <FormattedNumber value={total} />
      </Typography>
    </Stack>
  );
};
