export const addTypename = async <T>(records: Promise<T>, __typename: string): Promise<T> => {
  const result = await records;

  if (!result) {
    return result;
  }

  if (Array.isArray(result)) {
    // @ts-ignore
    return result.map((record) => {
      record.__typename = __typename;
      return record;
    });
  }

  // @ts-ignore
  result.__typename = __typename;
  return result;
};
