import Stack from '@mui/material/Stack';
import { SxProps } from '@mui/material/styles';
import { FC, ReactNode } from 'react';

interface AdminLayoutContentProps {
  children: ReactNode;

  sx?: SxProps;
}

export const AdminLayoutContent: FC<AdminLayoutContentProps> = ({ children, sx }) => {
  return <Stack sx={{ flexGrow: 1, ...sx }}>{children}</Stack>;
};
