export interface ParseTicketDataResult {
  externalEventId?: string;
  externalTicketId?: string;
  secret: string;
}

export const parseTicketData = (data: string, regex: RegExp): ParseTicketDataResult => {
  if (!data) {
    return null;
  }

  const match = data.match(regex);
  if (!match) {
    return null;
  }

  if (!match.groups?.['secret'] && !match.groups?.['externalTicketId']) {
    throw new Error(
      'Invalid ticket data RegExp. Must contain a named capture group called "secret".',
    );
  }

  return {
    externalEventId: match.groups['externalEventId'],
    externalTicketId: match.groups['externalTicketId'],
    secret: match.groups['secret'],
  };
};
