import { AdminLayout } from '../../components/admin/AdminLayout';
import { AdminLayoutContent } from '../../components/admin/AdminLayoutContent';
import { AdminLayoutTitle } from '../../components/admin/AdminLayoutTitle';
import { RoutedSettingsListItem } from '../../components/admin/RoutedSettingsListItem';
import { routes } from '../../routes';
import List from '@mui/material/List';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

interface SettingButton {
  label: string;
  to: string;
}

const settingButtons: SettingButton[] = [
  { label: 'Auth', to: routes.adminDebugAuth() },
  { label: 'Device', to: routes.adminDebugDevice() },
  { label: 'Export', to: routes.adminDebugExport() },
  { label: 'Metrics', to: routes.adminDebugMetrics() },
  { label: 'Settings', to: routes.adminDebugSettings() },
  { label: 'QR Codes', to: routes.adminDebugTicket() },
  { label: 'Update Data', to: routes.adminDebugUpdate() },
  { label: 'Wipe Data', to: routes.adminDebugWipe() },
];

export const AdminDebugView: FC = () => {
  const theme = useTheme();

  // Alphabetize setting buttons
  settingButtons.sort((setting1, setting2) => setting1.label.localeCompare(setting2.label));

  return (
    <AdminLayout ToolbarProps={{ back: routes.adminSettings() }}>
      <AdminLayoutTitle>Debug</AdminLayoutTitle>
      <AdminLayoutContent>
        <List sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}>
          {settingButtons.map(({ label, to }) => (
            <RoutedSettingsListItem key={label} label={label} to={to} />
          ))}
        </List>
      </AdminLayoutContent>
    </AdminLayout>
  );
};
