import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

export interface ScanningResultErrorCardProps {
  message?: string;

  title?: string;
}

const DEFAULT_MESSAGE = 'Direct the guest to customer service to resolve the issue.';
const DEFAULT_TITLE = 'DO NOT SCAN AGAIN';

export const ScanningResultErrorCard: FC<ScanningResultErrorCardProps> = ({
  message = DEFAULT_MESSAGE,
  title = DEFAULT_TITLE,
}) => {
  const theme = useTheme();

  return (
    <Paper elevation={1} sx={{ p: 2, textAlign: 'center' }}>
      <Typography
        sx={{ fontWeight: theme.typography.fontWeightBold, textTransform: 'uppercase' }}
        variant="subtitle1"
      >
        {title}
      </Typography>
      <Typography sx={{ my: 2 }} variant="body1">
        {message}
      </Typography>
    </Paper>
  );
};
