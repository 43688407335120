import {
  AppConfig,
  CheckInType,
  CreateScanInput,
  FetchScansDocument,
  FetchScansQuery,
  PushScansDocument,
  PushScansMutation,
  Scan,
  ScanError,
  ScanErrorMetrics,
  ScanMethod,
  Scanner,
  ScannerSettingName,
  ScannerSyncDirection,
  ScannerSyncType,
  ScanTimeSeriesData,
  SyncCountMetrics,
  Ticket,
} from '../../gql/__generated__/graphql';
import { getSetting } from '../AppSettings';
import { AppConfigRepository } from '../repositories/AppConfigRepository';
import { ScanRepository } from '../repositories/ScanRepository';
import { TicketRepository } from '../repositories/TicketRepository';
import { PracticeData } from '../utils/PracticeData.enum';
import { getPracticeGuest } from '../utils/recognizedScanData.util';
import { ScanFilter } from '../utils/scan.util';
import { getScannedTimestamp } from '../utils/ticket.util';
import { AppConfigService } from './AppConfigService';
import { GraphQlClientService } from './GraphQlClientService';
import { SyncService } from './SyncService';
import { SyncableCollectionService } from './SyncableCollectionService';
import Apollo from '@apollo/client';
import { Logger } from '@greatcrowd/ui-logging';
import { Service } from 'typedi';
import { v4 as uuidV4 } from 'uuid';

const addPracticeTicketToScan = (scan: Scan): Scan => {
  // Return a valid ticket for practice purposes
  if (
    scan?.error === ScanError.PracticeTicket &&
    scan?.url === PracticeData.PRACTICE_VALID_TICKET
  ) {
    const ticket: Ticket = getPracticeGuest().tickets[0];
    return {
      ...scan,
      ticket: {
        ...ticket,
        guest: {
          ...ticket.guest,
          // Preventing a circular reference, which manifests as an ApolloError
          tickets: ticket.guest.tickets.map((ticket) => ({ ...ticket, guest: null })),
        },
      },
      ticketId: ticket.id,
    };
  }

  return scan;
};

@Service()
export class ScanService extends SyncableCollectionService<Scan, number> {
  protected readonly logger = new Logger(ScanService.name);
  protected readonly defaultSortField: string = 'id';

  protected readonly scannerSyncType = ScannerSyncType.Scan;

  protected readonly downQuery: Apollo.DocumentNode = FetchScansDocument;
  protected readonly downDataSelector = (result: FetchScansQuery) => result.scans;
  protected readonly settingDownEnabled: ScannerSettingName =
    ScannerSettingName.SyncScansDownEnabled;
  protected readonly settingDownLimit: ScannerSettingName = ScannerSettingName.SyncScansDownLimit;
  protected readonly settingDownInterval: ScannerSettingName =
    ScannerSettingName.SyncScansDownInterval;

  protected readonly upMutation: Apollo.DocumentNode = undefined;

  constructor(
    protected readonly repository: ScanRepository,
    private readonly ticketRepository: TicketRepository,
    private readonly appConfigService: AppConfigService,
    readonly syncService: SyncService,
    readonly graphqlClientService: GraphQlClientService,
    readonly appConfigRepository: AppConfigRepository,
  ) {
    super(syncService, graphqlClientService, appConfigRepository);
  }

  async countBy(filter?: ScanFilter): Promise<number> {
    return this.repository.countBy(filter);
  }

  async countByScanner(scannerId: string = null): Promise<number> {
    const localScannerId = await this.appConfigService.getScannerId();

    if (!localScannerId) {
      return 0;
    }

    return this.repository.countByScanner(scannerId ?? localScannerId);
  }

  async create(scan: Partial<Scan>): Promise<number> {
    const appConfig = await this.appConfigService.single();

    // Add common fields onto scan record
    return super.create({
      id: uuidV4(),
      scannerId: appConfig.scanner.id,
      ...scan,
    });
  }

  async createErrorScan(
    data: string,
    error: ScanError,
    method: ScanMethod,
    type: CheckInType,
    ticketId: string,
  ): Promise<Scan> {
    this.logger.debug('Recording invalid scan', { data, error });

    const _seq = await this.create({
      error: error,
      method,
      ticketId,
      timestamp: new Date().toISOString(),
      type,
      url: data,
    });

    return this.findOne(_seq);
  }

  async findOne(_seq: number): Promise<Scan> {
    const scan = await this.repository.findOne(_seq);

    return addPracticeTicketToScan(scan);
  }

  async findOneById(id: string): Promise<Scan> {
    const scan = await this.repository.findOneBy({ id });

    return addPracticeTicketToScan(scan);
  }

  // TODO: this is misnomer - fix the name
  async getUnsynced(cursor: number, limit: number = 100): Promise<Scan[]> {
    const appConfig = await this.appConfigService.single();

    return this.repository.listByScanner(appConfig.scanner.id, cursor, limit);
  }

  async listByTicket(ticketId: string): Promise<Scan[]> {
    return this.repository.listByFk({ ticketId });
  }

  private async push(scanner: Scanner, scans: Scan[]): Promise<void> {
    this.logger.debug('Pushing scans', { count: scans.length });

    await this.graphqlClientService.mutate<PushScansMutation>({
      mutation: PushScansDocument,
      variables: {
        scans: scans.map<CreateScanInput>((scan) => ({
          id: scan.id,
          error: scan.error,
          method: scan.method,
          ticketId: scan.ticketId,
          timestamp: scan.timestamp,
          type: scan.type,
          url: scan.url,
        })),
      },
    });
  }

  protected async reduce(scans: Scan[]): Promise<void> {
    // Store the scans
    for (const scan of scans) {
      const exists = await this.repository.findOneBy({ id: scan.id });
      if (!exists) {
        await this.create(scan);
      } else {
        // Update with restored ticket data, if any
        await this.repository.update({ ...exists, ...scan });
      }
    }

    for (const scan of scans) {
      if (!scan.ticketId) {
        continue;
      }

      const ticket = await this.ticketRepository.findOne(scan.ticketId);
      // Update scanned timestamp based on data on the device
      await this.updateScanTimestamp(ticket);
    }
  }

  async updateScanTimestamp(ticket: Ticket): Promise<void> {
    if (!ticket) {
      return;
    }

    const ticketScans = await this.listByFk({ ticketId: ticket.id });
    const scanned = getScannedTimestamp(ticketScans);
    await this.ticketRepository.updatePartial(ticket.id, { scanned });
  }

  async syncUp(appConfig: AppConfig, ignoreInterval = false, ignoreLimit = false): Promise<void> {
    const enabled = getSetting<boolean>(appConfig, ScannerSettingName.SyncScansUpEnabled);
    const limit = ignoreLimit
      ? 100
      : getSetting<number>(appConfig, ScannerSettingName.SyncScansUpLimit);

    if (!ignoreInterval && !enabled) {
      this.logger.debug('SYNC__SCANS_UP_ENABLED is false');
      return;
    }

    const cursor = await this.syncService.getLastCursor(
      ScannerSyncType.Scan,
      ScannerSyncDirection.Up,
      ScannerSettingName.SyncScansUpInterval,
      ignoreInterval,
    );
    if (cursor === null) {
      return;
    }

    const unsynced = await this.getUnsynced(cursor, limit);

    if (!unsynced?.length) {
      return;
    }

    let error = null;

    const start = new Date();
    try {
      await this.push(appConfig.scanner, unsynced);
    } catch (err) {
      this.logger.error('Failed to push scans', err, { count: unsynced.length });
      error = err.toString();
    }
    const end = new Date();

    await this.syncService.create({
      cursor: (cursor + unsynced.length).toString(),
      direction: ScannerSyncDirection.Up,
      end: end.toISOString(),
      error,
      requested: unsynced.length,
      returned: 0,
      start: start.toISOString(),
      type: ScannerSyncType.Scan,
    });
  }

  async getUnsyncedCount() {
    const metrics = await this.getSyncUpMetrics();
    return metrics.total - metrics.totalSynced;
  }

  async getSyncDownMetrics(): Promise<SyncCountMetrics> {
    const lastSyncDown = await this.syncService.lastSuccessful(
      ScannerSyncType.Scan,
      ScannerSyncDirection.Down,
    );

    return {
      total: lastSyncDown?.total ?? 0,
      totalSynced: parseInt(lastSyncDown?.cursor ?? '0') + (lastSyncDown?.returned ?? 0),
    };
  }

  async getSyncUpMetrics(): Promise<SyncCountMetrics> {
    const lastSyncUp = await this.syncService.lastSuccessful(
      ScannerSyncType.Scan,
      ScannerSyncDirection.Up,
    );

    return {
      total: (await this.countByScanner()) ?? 0,
      totalSynced: parseInt(lastSyncUp?.cursor ?? '0'),
    };
  }

  /**
   * Retrieve timeseries scan data for the past three hours
   */
  async getScansOverTime(): Promise<ScanTimeSeriesData[]> {
    const MINUTES = 180;
    const now = new Date();

    const times = Array.from({ length: MINUTES }, (_, index) => {
      const time = new Date(now.getTime() - (MINUTES - index) * 60_000);
      return time.toISOString().substring(0, 16);
    });

    const since = new Date(now.getTime() - MINUTES * 60_000);

    const groupedScans = await this.repository.getScansByMinute(since);

    return times.reduce<ScanTimeSeriesData[]>((acc, curr) => {
      const scans = groupedScans[curr] ?? [];
      const errorCount = scans.filter((scan) => scan.error).length;

      return acc.concat([
        {
          count: scans.length - errorCount,
          error: errorCount,
          time: curr,
        },
      ]);
    }, []);
  }

  async getScanErrorsByType(): Promise<ScanErrorMetrics[]> {
    return [
      {
        error: ScanError.AlreadyScanned,
        count: await this.countBy({ error: ScanError.AlreadyScanned }),
      },
      {
        error: ScanError.UnknownTicket,
        count: await this.countBy({ error: ScanError.UnknownTicket }),
      },
      {
        error: ScanError.InvalidTicket,
        count: await this.countBy({ error: ScanError.InvalidTicket }),
      },
    ];
  }
}
