import { GuestDataContext } from '../contexts/GuestDataContext';
import { AppConfig, GetAppConfigurationDocument } from '../gql/__generated__/graphql';
import { dispatchGuestAction } from '../workers/GuestDataWorkerActions';
import { GuestAction } from '../workers/actions/GuestAction';
import { useApolloClient } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';

interface useGuestDataHookReturn {
  appConfig: AppConfig;
  dispatch: (action: GuestAction) => void;
}

export const useGuestData = (): useGuestDataHookReturn => {
  const context = useContext(GuestDataContext);
  const client = useApolloClient();
  const [appConfig, setAppConfig] = useState<AppConfig>();

  useEffect(() => {
    (async () => {
      const result = await client.query({ query: GetAppConfigurationDocument });
      setAppConfig(result.data.appConfig);
    })();
  }, [client, setAppConfig]);

  return {
    appConfig,
    dispatch: (action: GuestAction) =>
      context.worker && dispatchGuestAction(context.worker, action),
  };
};
