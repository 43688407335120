import { AttendeeWaiverCollection } from '../../components/scanning/AttendeeWaiverCollection.enum';
import { ScanMode } from '../../components/scanning/ScanMode.enum';
import { WorkflowRunner } from '../../components/workflow/WorkflowRunner';
import { WorkflowStep } from '../../components/workflow/WorkflowStep.interface';
import { getUnscannedTickets } from '../../data/utils/guest.util';
import { PRACTICE_GUEST_EMAIL } from '../../data/utils/recognizedScanData.util';
import {
  AbortCheckInDocument,
  AbortCheckInMutation,
  AddWaiverDocument,
  AddWaiverMutation,
  CheckInGuestDocument,
  CheckInGuestMutation,
  Guest,
  ScanError,
} from '../../gql/__generated__/graphql';
import { CheckInWorkflowContext } from './CheckInWorkflowContext.interface';
import { PartyCheckInStepDefinition } from './PartyCheckInStep';
import { ScanResultStepDefinition } from './ScanResultStep';
import { WaiverCollectionStepDefinition } from './WaiverCollectionStep';
import { useApolloClient } from '@apollo/client';
import { FC, useCallback, useMemo } from 'react';

export interface CheckInWorkflowProps {
  scanId?: string;

  guest: Guest;

  onAbort: () => Promise<void>;

  scanMode?: ScanMode;

  waiverCollection: AttendeeWaiverCollection;
}

const getDefaultContext = (
  scanId: string,
  guest: Guest,
  waiverCollection: AttendeeWaiverCollection,
): Partial<CheckInWorkflowContext> => {
  return {
    _localGuestId: guest.email,
    scanId,
    guest,
    quantity: 1,
    unscannedTicketCount: getUnscannedTickets(guest)?.length,
    waiverCollection,
    waivers: [],
  };
};

const getEnabledSteps = (
  guest: Guest,
  scanMode: ScanMode,
  waiverCollection: AttendeeWaiverCollection,
): WorkflowStep[] => {
  const steps = [];

  if (!getUnscannedTickets(guest)?.length) {
    return [ScanResultStepDefinition];
  }

  if (scanMode === 'party' /* && getUnscannedTickets(guest)?.length > 1*/) {
    steps.push(PartyCheckInStepDefinition);
  }

  if (waiverCollection !== AttendeeWaiverCollection.NONE) {
    steps.push(WaiverCollectionStepDefinition);
  }

  steps.push(ScanResultStepDefinition);

  return steps;
};

export const CheckInWorkflow: FC<CheckInWorkflowProps> = ({
  scanId,
  guest,
  scanMode,
  onAbort,
  waiverCollection,
  ...props
}) => {
  const client = useApolloClient();

  const defaultContext = useMemo(
    () => getDefaultContext(scanId, guest, waiverCollection),
    [scanId, guest, waiverCollection],
  );

  const steps = useMemo(
    () => getEnabledSteps(guest, scanMode, waiverCollection),
    [guest, scanMode, waiverCollection],
  );

  const handleAbort = useCallback(
    async (context: CheckInWorkflowContext) => {
      await client.mutate<AbortCheckInMutation>({
        mutation: AbortCheckInDocument,
        variables: {
          scanId: context.scanId,
          error: context.abortReason ?? ScanError.Cancelled,
        },
      });

      // Even though the check-in is being cancelled, we still store the waivers just in case
      await Promise.all(
        context.waivers.map((waiver) =>
          client.mutate<AddWaiverMutation>({
            mutation: AddWaiverDocument,
            variables: { scanId: context.scanId, imageData: waiver.imageData },
          }),
        ),
      );

      await onAbort?.();
    },
    [client, onAbort],
  );

  const handleSubmit = useCallback(
    async (context: CheckInWorkflowContext) => {
      if (context._localGuestId === PRACTICE_GUEST_EMAIL) {
        return Promise.resolve();
      }

      await client.mutate<CheckInGuestMutation>({
        mutation: CheckInGuestDocument,
        variables: { scanId: context.scanId, quantity: context.quantity },
      });

      await Promise.all(
        context.waivers.map((waiver) =>
          client.mutate<AddWaiverMutation>({
            mutation: AddWaiverDocument,
            variables: { scanId: context.scanId, imageData: waiver.imageData },
          }),
        ),
      );
    },
    [client],
  );

  return (
    <WorkflowRunner
      {...props}
      defaultContext={defaultContext}
      onAbort={handleAbort}
      onSubmit={handleSubmit}
      steps={steps}
    />
  );
};
