import { AppLayout } from '../../components/AppLayout';
import { useEnvironmentVariable } from '../../hooks/useEnvironmentVariable';
import { useGuestData } from '../../hooks/useGuestData';
import { routes } from '../../routes';
import { ButtonStack } from '@greatcrowd/ui-button';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';
import { Link, Navigate } from 'react-router-dom';

export const HomeView: FC = () => {
  const theme = useTheme();
  const version = useEnvironmentVariable('REACT_APP_COMMIT_SHA', 'localhost').substring(0, 8);

  const { appConfig } = useGuestData();
  if (appConfig) {
    return <Navigate to={routes.scanningHome()} />;
  }

  return (
    <AppLayout>
      <Stack direction="column" spacing={2} sx={{ height: '100%', p: 3, width: '100%' }}>
        <Box sx={{ flexGrow: 1, mt: 16 }}>
          <Typography
            component="h2"
            variant="h2"
            sx={{ fontWeight: theme.typography.fontWeightBold }}
          >
            Welcome to Great Crowd Ticket Scanner.
          </Typography>
        </Box>
        <ButtonStack sx={{ flexGrow: 0 }}>
          <Button
            component={Link}
            color="primary"
            size="large"
            to={routes.linkScannerScan()}
            variant="contained"
          >
            Link This Scanner
          </Button>
        </ButtonStack>
        <Stack
          direction="row"
          spacing={2}
          sx={{ alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Typography color="text.disabled" variant="caption">
            &copy; Great Crowd {new Date().getFullYear()}
          </Typography>
          <Typography color="text.disabled" variant="caption">
            {version}
          </Typography>
        </Stack>
      </Stack>
    </AppLayout>
  );
};
