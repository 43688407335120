import {
  CheckInType,
  Guest,
  Scan,
  Ticket,
  TicketCheckInStatus,
} from '../../gql/__generated__/graphql';
import { getGuest } from '../fixtures';
import { GuestRepository } from '../repositories/GuestRepository';
import { ScanRepository } from '../repositories/ScanRepository';
import { TicketRepository } from '../repositories/TicketRepository';
import { getPracticeGuest, PRACTICE_GUEST_EMAIL } from '../utils/recognizedScanData.util';
import { DexieService } from './DexieService';
import { Logger } from '@greatcrowd/ui-logging';
import { Service } from 'typedi';

@Service()
export class GuestService extends DexieService<Guest> {
  protected readonly logger = new Logger(GuestService.name);
  protected readonly defaultSortField: string = 'surname';

  constructor(
    protected readonly repository: GuestRepository,
    private readonly ticketRepository: TicketRepository,
    private readonly scanRepository: ScanRepository,
  ) {
    super();
  }

  async countBy(search?: string): Promise<number> {
    if (!search?.trim()) {
      return this.count();
    }

    return this.repository.countBy(search.toLowerCase());
  }

  async findOneByEmail(email: string): Promise<Guest> {
    if (email === PRACTICE_GUEST_EMAIL) {
      return getPracticeGuest();
    }

    return this.repository.findOneBy({ guestId: email?.toLowerCase() });
  }

  async random(ticketStatus: TicketCheckInStatus): Promise<Guest> {
    let guestId: string;
    let ticketId: string;

    switch (ticketStatus) {
      case TicketCheckInStatus.Explicit:
        const explicitScan = await this.scanRepository.randomBy({
          error: null,
          type: CheckInType.Explicit,
        });
        ticketId = explicitScan?.ticketId;
        break;
      case TicketCheckInStatus.Implicit:
        const implicitScan = await this.scanRepository.randomBy({
          error: null,
          type: CheckInType.Implicit,
        });
        ticketId = implicitScan?.ticketId;
        break;
      case TicketCheckInStatus.Invalid:
        guestId = null;
        break;
      case TicketCheckInStatus.Unscanned:
        const unscannedTicket = await this.ticketRepository.randomBy({
          scanned: null,
        });
        guestId = unscannedTicket?.guestId;
        break;
    }

    // We looked up by Ticket
    if (guestId) {
      return this.repository.findOne(guestId);
    }

    // We looked up by Scan (Scan only has .ticketId)
    if (ticketId) {
      const ticket = await this.ticketRepository.findOne(ticketId);
      return this.repository.findOne(ticket.guestId);
    }

    // Couldn't find a guest with a ticket that matches the provided status. Returning
    // invalid guest
    return getGuest({}, { totalTickets: 1, totalScanned: 0 });
  }

  async search(limit: number, cursor: number, search: string): Promise<Guest[]> {
    return this.repository.search(limit, cursor, search?.toLowerCase());
  }
}
