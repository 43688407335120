import { Guest, Order, ScanError, Ticket } from '../../gql/__generated__/graphql';
import { getScan } from './getScan';
import { getTicketClaim } from './getTicketClaim';
import { faker } from '@faker-js/faker/locale/en_US';

export const getTicket = (
  guest: Guest,
  options: Partial<Ticket>,
  scanned?: boolean,
  error?: ScanError,
): Ticket => {
  const ticket: Ticket = {
    checkInStatus: null,
    claim: options.claim ?? getTicketClaim(),
    code: faker.datatype.hexadecimal({ prefix: '', length: 10, case: 'lower' }),
    created: faker.date.past(),
    event: null,
    eventId: null,
    guestId: guest.email,
    id: faker.datatype.uuid(),
    // lineItem: null,
    // order: {
    //   id: faker.datatype.uuid(),
    //   number: `NLE23-${faker.datatype.number({ min: 0, max: 12_000 })}`,
    // } as Order,
    organizationId: null,
    scanned: scanned
      ? faker.date.between('2023-03-03T12:00:00Z', '2023-03-03T18:00:00Z').toISOString()
      : null,
    status: null,
    uniqueId: null,
    updated: faker.date.past(),
    ...options,
  };

  ticket.guest = {
    ...guest,
    tickets: (guest.tickets ?? []).concat({
      ...ticket,
      // Must avoid circular references
      guest: null,
    }),
  };
  ticket._localScans = scanned ? [getScan(ticket, { error })] : [];

  return ticket;
};
