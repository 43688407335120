import { AttendeeWaiver } from '../../gql/__generated__/graphql';
import { AttendeeWaiverCollection } from '../scanning/AttendeeWaiverCollection.enum';
import Button, { ButtonProps } from '@mui/material/Button';
import { FC } from 'react';

export interface ScanWaiverButtonProps extends ButtonProps {
  additional: boolean;

  quantity: number;

  waiverCollection: AttendeeWaiverCollection;

  waivers: AttendeeWaiver[];
}

const getButtonLabel = (additional: boolean, waivers: AttendeeWaiver[]): string => {
  if (!waivers?.length) {
    return 'Scan waiver';
  }

  return additional ? 'Scan additional waivers' : 'Scan another waiver';
};

export const ScanWaiverButton: FC<ScanWaiverButtonProps> = ({
  additional,
  quantity,
  waiverCollection,
  waivers,
  ...props
}) => {
  const enoughWaiversSubmitted =
    additional ||
    (waiverCollection === AttendeeWaiverCollection.ONE_PER_PARTY && waivers.length) ||
    (waiverCollection === AttendeeWaiverCollection.ONE_PER_ATTENDEE && waivers.length >= quantity);

  return (
    <Button
      color="primary"
      sx={{ minHeight: 48 }}
      variant={enoughWaiversSubmitted ? 'outlined' : 'contained'}
      {...props}
    >
      {getButtonLabel(additional, waivers)}
    </Button>
  );
};
