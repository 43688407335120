import { MutationAbortCheckInArgs, Scan } from '../../../gql/__generated__/graphql';
import { TicketService } from '../../services/TicketService';
import { Resolver } from '@apollo/client';
import { Container } from 'typedi';

export const abortCheckInResolver: Resolver = async (
  _,
  input: MutationAbortCheckInArgs,
): Promise<Scan> => {
  const ticketService = Container.get(TicketService);

  return ticketService.abortCheckIn(input.scanId, input.error);
};
