import { styled } from '@mui/material/styles';

/**
 * View is responsible for ensuring that the app implements its own scrolling. This is done
 * by ensuring that the root of every View is always exactly 100vh x 100vw
 */
export const View = styled('div', { name: 'GcuiView' })(() => ({
  height: '100vh',
  maxHeight: '100vh',
  maxWidth: '100vw',
  minHeight: '100vh',
  minWidth: '100vw',
  width: '100vw',
}));
