import { AppLayout } from '../../components/AppLayout';
import { AttendeeWaiverCollection } from '../../components/scanning/AttendeeWaiverCollection.enum';
import { Guest, ScannerSettingName, useGetScanQuery } from '../../gql/__generated__/graphql';
import { useAppSetting } from '../../hooks/useAppSetting';
import { routes } from '../../routes';
import { CheckInWorkflow } from '../../workflows/CheckInWorkflow/CheckInWorkflow';
import { FC, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface LookupGuestsCheckInViewProps {}

export const LookupGuestsCheckInView: FC<LookupGuestsCheckInViewProps> = () => {
  const navigate = useNavigate();
  const routeParams = useParams();
  const scanId = routeParams.scanId;
  const waiverCollection = useAppSetting<AttendeeWaiverCollection>(
    ScannerSettingName.ScanWaiverCollection,
  );

  const handleAbort = useCallback(async () => {
    navigate(routes.lookupGuests());
  }, [navigate]);

  const { data, loading } = useGetScanQuery({
    variables: { scanId },
  });

  if (loading) {
    return null;
  }

  const scan = data?.scan;
  const ticket = scan?.ticket;

  // `scanMode` is hard-coded to "party" because in the scenario where we look up a guest
  // we don't have a way to individually select tickets for check-in
  return (
    <AppLayout showAppBar={false}>
      <CheckInWorkflow
        scanId={scanId}
        guest={ticket.guest as Guest}
        onAbort={handleAbort}
        scanMode="party"
        waiverCollection={waiverCollection}
      />
    </AppLayout>
  );
};
