import { WorkflowStep, WorkflowStepProps } from '../../components/workflow/WorkflowStep.interface';
import { WorkflowStepLayout } from '../../components/workflow/WorkflowStepLayout';
import { client } from '../../data/graph/client';
import {
  LinkScannerDocument,
  LinkScannerMutation,
  Event,
  Scanner,
  useGetAppConfigurationLazyQuery,
} from '../../gql/__generated__/graphql';
import { useGuestData } from '../../hooks/useGuestData';
import { delay } from '../../utils/delay.util';
import { InitializeApplicationAction } from '../../workers/actions/InitializeApplicationAction';
import { LinkScannerWorkflowContext } from './LinkScannerWorkflowContext.interface';
import { ButtonStack } from '@greatcrowd/ui-button';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { FC, useCallback, useState } from 'react';
import { FormattedDate } from 'react-intl';

export interface ConfirmEventStepProps extends WorkflowStepProps<LinkScannerWorkflowContext> {}

export const ConfirmEventStep: FC<ConfirmEventStepProps> = ({
  context,
  onAbort,
  onNext,
  onSubmit,
  setContext,
  stepIndex,
  steps,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { dispatch } = useGuestData();
  const events = context.scanner.eventSubscriptions.map((subscription) => subscription.event);
  const [getAppConfig] = useGetAppConfigurationLazyQuery();

  const handleNext = useCallback(async () => {
    setLoading(true);

    const result = await client.mutate<LinkScannerMutation>({
      context: { organizationId: context.organizationId, token: context.token },
      mutation: LinkScannerDocument,
      variables: { friendlyName: context.friendlyName, scannerId: context.scannerId },
    });

    dispatch(
      // Links the scanner and prepares the database
      InitializeApplicationAction.from({
        scanner: result?.data.linkScanner as Scanner,
        token: context.token,
      }),
    );

    let appConfig;
    do {
      appConfig = await getAppConfig();
      await delay(100);
    } while (result?.data.linkScanner.id !== appConfig?.data?.appConfig?.scanner?.id);

    await onNext();
  }, [
    context.friendlyName,
    context.organizationId,
    context.scannerId,
    context.token,
    dispatch,
    getAppConfig,
    onNext,
  ]);

  return (
    <WorkflowStepLayout disableStepper steps={steps} stepIndex={stepIndex}>
      <Stack spacing={4} sx={{ flexGrow: 1, pt: 6 }}>
        <Typography component="h2" variant="h4">
          Sync ticket data for these events?
        </Typography>
        <Stack spacing={2}>
          {events?.map((event: Event) => (
            <Stack spacing={1} key={event.id}>
              <Typography variant="subtitle1" sx={{ mt: 1 }}>
                {event.name}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                <FormattedDate day="2-digit" month="short" value={event.starts} year="numeric" />
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
      <ButtonStack sx={{ flexGrow: 0, pb: 3 }}>
        <Button
          color="primary"
          disabled={loading}
          onClick={handleNext}
          size="large"
          variant="contained"
        >
          Next
        </Button>
        <Button
          color="primary"
          disabled={loading}
          onClick={onAbort}
          size="large"
          variant="outlined"
        >
          Cancel
        </Button>
      </ButtonStack>
    </WorkflowStepLayout>
  );
};

export const ConfirmEventStepDefinition: WorkflowStep = {
  Component: ConfirmEventStep,
  id: 'confirm',
};
