import { IRepository } from '../repositories/IRepository';
import { Logger } from '@greatcrowd/ui-logging';

export interface ListInput<RecordType> {
  cursor?: string;
  filter?: (obj: RecordType) => boolean;
  limit?: number;
  orderBy: string;
  page?: number;
  query?: string;
  reverse?: boolean;
}

export abstract class DexieService<RecordType, IdType = string> {
  protected abstract readonly logger: Logger;
  protected abstract readonly defaultSortField: string;
  protected abstract readonly repository: IRepository<RecordType, IdType>;

  async clear(): Promise<void> {
    return this.repository.clear();
  }

  async count(): Promise<number> {
    return this.repository.count();
  }

  async create(obj: Partial<RecordType>): Promise<IdType> {
    return this.repository.create(obj);
  }

  async createMany(obj: Partial<RecordType>[], keys?: string[]): Promise<IdType> {
    return this.repository.createMany(obj, keys);
  }

  async findOne(id: IdType): Promise<RecordType> {
    return this.repository.findOne(id);
  }

  async findOneBy(where: Record<string, string>): Promise<RecordType> {
    return this.repository.findOneBy(where);
  }

  async lastByPredicate(filter: (record: RecordType) => boolean): Promise<RecordType> {
    return this.repository.lastByPredicate(filter);
  }

  async list(options: ListInput<RecordType> = null): Promise<RecordType[]> {
    const { filter, limit, orderBy, page, query } = options || {};

    return this.repository.listByPage(page, limit, orderBy ?? this.defaultSortField, filter, query);
  }

  async listByFk(conditions: Record<string, string>): Promise<RecordType[]> {
    return this.repository.listByFk(conditions);
  }

  async single(): Promise<RecordType> {
    return await this.repository.single();
  }

  async update(obj: RecordType): Promise<IdType> {
    return this.repository.update(obj);
  }
}
