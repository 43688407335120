import { GetGuestQueryVariables, Guest } from '../../../gql/__generated__/graphql';
import { GuestService } from '../../services/GuestService';
import { addTypename } from './addTypename';
import { Resolver } from '@apollo/client';
import { Container } from 'typedi';

export const getGuestResolver: Resolver = async (
  parent: null,
  { guestId }: GetGuestQueryVariables,
): Promise<Guest> => {
  const guestService = Container.get(GuestService);

  return addTypename<Guest>(guestService.findOneByEmail(guestId), 'Guest');
};
