import { ScanMetrics, SyncableMetrics } from '../../../gql/__generated__/graphql';
import { GuestService } from '../../services/GuestService';
import { ScanService } from '../../services/ScanService';
import { TicketService } from '../../services/TicketService';
import { ScanFilter } from '../../utils/scan.util';
import { TicketFilter } from '../../utils/ticket.util';
import { addTypename } from './addTypename';
import { Resolvers } from '@apollo/client';
import { Container } from 'typedi';

interface GuestCountInput {
  search?: string;
}

export const metricsResolver: Resolvers = {
  Metrics: {
    guestCount: async (_, input?: GuestCountInput): Promise<number> => {
      const guestService = Container.get(GuestService);
      return guestService.countBy(input.search);
    },
    scanCount: async (_, input?: ScanFilter): Promise<number> => {
      const scanService = Container.get(ScanService);
      return scanService.countBy(input);
    },
    scan: async (): Promise<ScanMetrics> => {
      return addTypename<ScanMetrics>(Promise.resolve({}), 'ScanMetrics');
    },
    sync: async (): Promise<SyncableMetrics> => {
      return addTypename<SyncableMetrics>(Promise.resolve({}), 'SyncableMetrics');
    },
    ticketCount: async (_, input?: TicketFilter): Promise<number> => {
      const ticketService = Container.get(TicketService);
      return ticketService.countBy(input);
    },
  },
};
