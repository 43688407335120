import { BasicToolbar } from '../../../components/BasicToolbar';
import { PracticeData } from '../../../data/utils/PracticeData.enum';
import {
  Scan,
  ScanError,
  ScannerSettingName,
  useGetScanQuery,
} from '../../../gql/__generated__/graphql';
import { useAppSetting } from '../../../hooks/useAppSetting';
import { routes } from '../../../routes';
import { ScanningResultAlreadyScanned } from './ScanningResultAlreadyScanned';
import { ScanningResultCheckInCancelled } from './ScanningResultCheckInCancelled';
import { ScanningResultInvalidTicket } from './ScanningResultInvalidTicket';
import { ScanningResultSuccess } from './ScanningResultSuccess';
import { ScanningResultUnknownTicketError } from './ScanningResultUnknownTicketError';
import { ScanningResultUnknownTicketInfo } from './ScanningResultUnknownTicketInfo';
import { ButtonStack } from '@greatcrowd/ui-button';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { PaletteColor, Theme, useTheme } from '@mui/material/styles';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';

const getScanResultColor = (
  scan: Scan,
  unknownTicketErrorLevel: 'info' | 'error',
  theme: Theme,
): PaletteColor => {
  if (
    scan?.error === ScanError.UnknownTicket ||
    (scan.url === PracticeData.PRACTICE_UNKNOWN_TICKET && unknownTicketErrorLevel === 'info')
  ) {
    return theme.palette.warning;
  }

  return scan?.error ? theme.palette.error : theme.palette.success;
};

const getScanResultContent = (scan: Scan, unknownTicketErrorLevel: 'info' | 'error') => {
  if (!scan.error) {
    return <ScanningResultSuccess scan={scan} />;
  }

  if (
    scan.error === ScanError.AlreadyScanned ||
    scan.url === PracticeData.PRACTICE_ALREADY_SCANNED
  ) {
    return <ScanningResultAlreadyScanned scan={scan} />;
  }
  if (scan.error === ScanError.UnknownTicket || scan.url === PracticeData.PRACTICE_UNKNOWN_TICKET) {
    return unknownTicketErrorLevel === 'info' ? (
      <ScanningResultUnknownTicketInfo scan={scan} />
    ) : (
      <ScanningResultUnknownTicketError scan={scan} />
    );
  }
  if (scan.error === ScanError.InvalidTicket || scan.url === PracticeData.PRACTICE_INVALID) {
    return <ScanningResultInvalidTicket scan={scan} />;
  }
  if (scan.error === ScanError.Cancelled) {
    return <ScanningResultCheckInCancelled scan={scan} />;
  }
  return <>Unsupported scan result</>;
};

export const ScanningTicketsResultView: FC = () => {
  const theme = useTheme();
  const routeParams = useParams();
  const scanId = routeParams.scanId;
  const unknownTicketErrorLevel = useAppSetting<'info' | 'error'>(
    ScannerSettingName.ErrorUnknownTicketLevel,
  );

  const { data, loading } = useGetScanQuery({
    variables: { scanId },
  });

  if (loading || !data) {
    return null;
  }

  const scan = data?.scan as Scan;
  const color = getScanResultColor(scan, unknownTicketErrorLevel, theme);

  return (
    <Stack
      direction="column"
      spacing={1}
      sx={{
        backgroundColor: color.main,
        color: theme.palette.common.white,
        height: '100%',
        p: 2,
        width: '100%',
      }}
    >
      <BasicToolbar back={routes.scanningHome()} />
      <Stack
        direction="column"
        spacing={2}
        sx={{
          height: '100%',
          justifyContent: 'space-between',
          pb: 3,
        }}
      >
        <Stack sx={{ flexGrow: 1 }}>{getScanResultContent(scan, unknownTicketErrorLevel)}</Stack>
        <ButtonStack sx={{ flexGrow: 0 }}>
          <Button
            color="primary"
            component={Link}
            size="large"
            to={routes.scanningTicketsScanner()}
            variant="contained"
          >
            Scan Another
          </Button>
        </ButtonStack>
      </Stack>
    </Stack>
  );
};
