import { Guest, Scan, Ticket } from '../../../gql/__generated__/graphql';
import { GuestService } from '../../services/GuestService';
import { ScanService } from '../../services/ScanService';
import { addTypename } from './addTypename';
import { Resolvers } from '@apollo/client';
import { Container } from 'typedi';

export const ticketResolver: Resolvers = {
  Ticket: {
    guest: async (parent: Ticket): Promise<Guest> => {
      const guestService = Container.get(GuestService);
      const guestId = parent?.guestId;

      if (!guestId) {
        return null;
      }

      return addTypename(guestService.findOne(guestId), 'Guest');
    },
    scans: async (parent: Ticket): Promise<Scan[]> => {
      const scanService = Container.get(ScanService);
      return addTypename(scanService.listByFk({ ticketId: parent.id }), 'Scan');
    },
  },
};
