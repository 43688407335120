import {
  AppConfig,
  AttendeeWaiver,
  Guest,
  Scan,
  ScannerSync,
  Ticket,
} from '../gql/__generated__/graphql';
import Dexie, { Table } from 'dexie';
import { Service } from 'typedi';

@Service()
export class GreatCrowdScannerDatabase extends Dexie {
  guests!: Table<Guest>;
  appConfig!: Table<AppConfig>;
  scans!: Table<Scan>;
  syncs!: Table<ScannerSync>;
  tickets!: Table<Ticket>;
  waivers!: Table<AttendeeWaiver>;

  constructor() {
    super('gc_Scanner');

    this.version(1).stores({
      appConfig: '++id',
      guests: '&email,givenName,phone,surname',
      scans: '++_seq,id,scannerId,[scannerId+timestamp],ticketId,timestamp',
      syncs: '++_seq,id,start',
      tickets: '&id,guestId,&code',
      waivers: '++_seq,id,scanId',
    });

    this.version(2).stores({
      appConfig: '++id',
      guests: '&email,givenName,phone,surname',
      scans: '++_seq,id,scannerId,[scannerId+timestamp],ticketId,timestamp',
      syncs: '++_seq,id,start',
      tickets: '&id,guestId,&code,_externalId',
      waivers: '++_seq,id,scanId',
    });

    this.version(3).stores({
      appConfig: '++id',
      guests: '&email,guestId,givenName,phone,surname',
      scans: '++_seq,id,scannerId,[scannerId+timestamp],ticketId,timestamp',
      syncs: '++_seq,id,start',
      tickets: '&id,guestId,&code,_externalId',
      waivers: '++_seq,id,scanId',
    });
  }
}
