import { GuestTicket } from '../../components/guest/GuestTicket';
import { WorkflowStep, WorkflowStepProps } from '../../components/workflow/WorkflowStep.interface';
import { WorkflowStepLayout } from '../../components/workflow/WorkflowStepLayout';
import { Guest, useGetGuestQuery } from '../../gql/__generated__/graphql';
import { routes } from '../../routes';
import { CheckInWorkflowContext } from './CheckInWorkflowContext.interface';
import { ButtonStack } from '@greatcrowd/ui-button';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';
import { Link } from 'react-router-dom';

export interface ScanResultStepProps extends WorkflowStepProps<CheckInWorkflowContext> {}

export const ScanResultStep: FC<ScanResultStepProps> = ({ context, steps }) => {
  const theme = useTheme();

  // Re-fetch guest on this step because the ticket status will have changed
  const { data, loading, error } = useGetGuestQuery({
    variables: { guestId: context._localGuestId },
  });

  if (loading || error) {
    return null;
  }

  return (
    <WorkflowStepLayout
      darkMode
      steps={steps}
      stepIndex={steps.length}
      sx={{ backgroundColor: theme.palette.success.main, color: theme.palette.common.white }}
      title="Check-in Successful"
    >
      <Stack sx={{ flexGrow: 1 }}>
        <GuestTicket guest={data.guest as Guest} />
      </Stack>
      <ButtonStack sx={{ flexGrow: 0, pb: 3 }}>
        <Button
          color="primary"
          component={Link}
          size="large"
          to={routes.scanningHome()}
          variant="contained"
        >
          Back to Ticket Scanner
        </Button>
      </ButtonStack>
    </WorkflowStepLayout>
  );
};

export const ScanResultStepDefinition: WorkflowStep = {
  Component: ScanResultStep,
  id: 'result',
};
