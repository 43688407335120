import { WorkflowStep, WorkflowStepProps } from '../../components/workflow/WorkflowStep.interface';
import { WorkflowStepLayout } from '../../components/workflow/WorkflowStepLayout';
import { useGetLinkScannerCompleteStepMetricsQuery } from '../../gql/__generated__/graphql';
import { LinkScannerWorkflowContext } from './LinkScannerWorkflowContext.interface';
import { ButtonStack } from '@greatcrowd/ui-button';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import React, { FC } from 'react';
import { FormattedDate, FormattedNumber } from 'react-intl';

export interface LinkScannerCompleteStepProps
  extends WorkflowStepProps<LinkScannerWorkflowContext> {}

export const LinkScannerCompleteStep: FC<LinkScannerCompleteStepProps> = ({
  context,
  onNext,
  onSubmit,
  setContext,
  stepIndex,
  steps,
}) => {
  const { data, loading } = useGetLinkScannerCompleteStepMetricsQuery();

  if (loading) {
    return null;
  }

  const ticketData = data?.metrics.sync.tickets.down;
  const events = data?.appConfig.scanner.eventSubscriptions.map(
    (subscription) => subscription.event,
  );

  return (
    <WorkflowStepLayout disableStepper steps={steps} stepIndex={stepIndex}>
      <Stack spacing={4} sx={{ flexGrow: 1, pt: 6 }}>
        <Typography component="h2" variant="h4">
          Successfully synced ticket data for <FormattedNumber value={ticketData?.totalSynced} />{' '}
          attendees!
        </Typography>
        <Typography variant="body1">Scanning is now available for the following events:</Typography>
        <Stack spacing={2}>
          {events?.map((event) => (
            <Stack spacing={1} key={event.id}>
              <Typography variant="subtitle1" sx={{ mt: 1 }}>
                {event.name}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                <FormattedDate day="2-digit" month="short" value={event.starts} year="numeric" />
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
      <ButtonStack sx={{ flexGrow: 0, pb: 3 }}>
        <Button color="primary" onClick={onSubmit} size="large" variant="contained">
          Start Scanning
        </Button>
      </ButtonStack>
    </WorkflowStepLayout>
  );
};

export const LinkScannerCompleteStepDefinition: WorkflowStep = {
  Component: LinkScannerCompleteStep,
  id: 'complete',
};
