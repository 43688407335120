import { AdminLayout } from '../../components/admin/AdminLayout';
import { AdminLayoutContent } from '../../components/admin/AdminLayoutContent';
import { AdminLayoutTitle } from '../../components/admin/AdminLayoutTitle';
import { useGuestData } from '../../hooks/useGuestData';
import { routes } from '../../routes';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

interface AuthToken {
  expiresAt: Date;
  issuer: string;
  issuedAt: Date;
  organizationId: string;
  roles: string[];
  raw: string;
  sub: string;
}

const parseJwt = (token: string): AuthToken => {
  if (!token) {
    return null;
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  const obj = JSON.parse(jsonPayload);

  const customClaims = Object.entries<string>(obj).find(([key, _]) => {
    return key.toString().endsWith('greatcrowd.io/jwt/claims');
  });

  return {
    expiresAt: new Date(obj.exp * 1000),
    issuedAt: new Date(obj.iat * 1000),
    issuer: obj.iss,
    organizationId: customClaims ? JSON.parse(customClaims[1]).organizationId : '',
    raw: token,
    roles: customClaims ? JSON.parse(customClaims[1]).roles : [],
    sub: obj.sub,
  };
};

export const AdminDebugAuthView: FC = () => {
  const { appConfig } = useGuestData();

  const authToken = parseJwt(appConfig?.token);

  return (
    <AdminLayout ToolbarProps={{ back: routes.adminDebug() }}>
      <AdminLayoutTitle>Auth</AdminLayoutTitle>
      <AdminLayoutContent>
        {authToken ? (
          <List dense disablePadding>
            <ListItem disableGutters>
              <ListItemText primary="Scanner ID" secondary={authToken.sub} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText
                primary="Organization ID"
                secondary={authToken.organizationId.toString()}
              />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary="Issuer" secondary={authToken.issuer} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary="Issued At" secondary={authToken.issuedAt.toString()} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary="Expires At" secondary={authToken.expiresAt.toString()} />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText
                primary="Roles"
                secondary={JSON.stringify(authToken.roles)}
                sx={{ wordBreak: 'break-word' }}
              />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText
                primary="Raw"
                secondary={authToken.raw}
                sx={{ wordBreak: 'break-all' }}
              />
            </ListItem>
          </List>
        ) : (
          <Typography>Not authenticated</Typography>
        )}
      </AdminLayoutContent>
    </AdminLayout>
  );
};
