import ChevronLeft from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { FC } from 'react';
import { Link } from 'react-router-dom';

export interface AdminToolbarProps {
  back?: string;
  onBack?: () => any;
}

export const AdminToolbar: FC<AdminToolbarProps> = ({ back, onBack }) => {
  return (
    <Toolbar sx={{ px: 1.5 }}>
      {(back || onBack) && (
        <IconButton
          aria-label="return to previous view"
          color="inherit"
          component={Link}
          edge="start"
          onClick={onBack}
          size="large"
          sx={{ mr: 2 }}
          to={back}
        >
          <ChevronLeft />
        </IconButton>
      )}
    </Toolbar>
  );
};
