import { Scan } from '../../gql/__generated__/graphql';
import { GreatCrowdScannerDatabase } from '../GreatCrowdScannerDatabase';
import { getPredicate, ScanFilter } from '../utils/scan.util';
import { DexieRepository } from './DexieRepository';
import { Table } from 'dexie';
import { Service } from 'typedi';

@Service()
export class ScanRepository extends DexieRepository<Scan, number> {
  protected readonly table: Table<Scan>;

  constructor(db: GreatCrowdScannerDatabase) {
    super();

    this.table = db.scans;
  }

  async listByScanner(scannerId: string, offset: number, limit: number): Promise<Scan[]> {
    return this.table
      .orderBy(['scannerId+timestamp'])
      .filter((scan) => scan.scannerId === scannerId)
      .offset(offset || 0)
      .limit(limit)
      .toArray();
  }

  async countBy(filter?: ScanFilter): Promise<number> {
    return this.table.filter(getPredicate(filter)).count();
  }

  async countByScanner(scannerId: string): Promise<number> {
    return this.table.where({ scannerId }).count();
  }

  async getScansByMinute(since: Date): Promise<Record<string, Scan[]>> {
    const result: Record<string, Scan[]> = {};

    await this.table
      .where('timestamp')
      .aboveOrEqual(since.toISOString())
      .each((scan) => {
        const key = new Date(scan.timestamp).toISOString().substring(0, 16);
        result[key] = (result[key] ?? []).concat(scan);
      });

    return result;
  }

  async randomBy(filter: ScanFilter): Promise<Scan> {
    const count = await this.countBy(filter);
    if (!count) {
      return null;
    }

    return (
      await this.listByPredicate(getPredicate(filter), Math.floor(Math.random() * count), 1)
    )[0];
  }
}
