import { GuestTicket } from '../../../components/guest/GuestTicket';
import { AdditionalWaiverCollectionCard } from '../../../components/waiver/AdditionalWaiverCollectionCard';
import { Guest, Scan } from '../../../gql/__generated__/graphql';
import { ScanningResultLayout } from './ScanningResultLayout';
import { FC } from 'react';

interface ScanningResultSuccessProps {
  scan: Scan;
}

/**
 * NOTE: this screen is NOT accessed through any means in the app. No
 *       need to display waivers here because they won't ever be collected in this
 *       view
 * @param scan
 * @constructor
 */
export const ScanningResultSuccess: FC<ScanningResultSuccessProps> = ({ scan }) => {
  const ticket = scan.ticket;
  const guest = ticket.guest;

  return (
    <ScanningResultLayout title="Successfully checked in">
      <GuestTicket guest={guest as Guest} ticket={ticket} />
      <AdditionalWaiverCollectionCard scanId={scan.id} />
    </ScanningResultLayout>
  );
};
