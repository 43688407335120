import { WorkflowRunner } from '../../components/workflow/WorkflowRunner';
import { WorkflowStep } from '../../components/workflow/WorkflowStep.interface';
import {
  GetLinkScannerWorkflowContextQuery,
  Scanner,
  useGetLinkScannerWorkflowContextQuery,
} from '../../gql/__generated__/graphql';
import { hasUnsyncedData } from '../../hooks/useSyncMetrics';
import { routes } from '../../routes';
import { FRIENDLY_NAME_LOCAL_STORAGE_KEY } from '../common/constants';
import { DownloadDataStepDefinition } from '../common/steps/DownloadDataStep';
import { UnsyncedDataStepDefinition } from '../common/steps/UnsyncedDataStep';
import { UploadDataStepDefinition } from '../common/steps/UploadDataStep';
import { ConfirmEventStepDefinition } from './ConfirmEventStep';
import { LinkScannerCompleteStepDefinition } from './LinkScannerCompleteStep';
import { LinkScannerWorkflowContext } from './LinkScannerWorkflowContext.interface';
import { NoEventSubscriptionsStepDefinition } from './NoEventSubscriptionsStep';
import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export interface LinkScannerWorkflowProps {
  organizationId: string;
  scannerId: string;
  token: string;
}

const getDefaultContext = (
  token: string,
  data: GetLinkScannerWorkflowContextQuery,
): Partial<LinkScannerWorkflowContext> => {
  if (!data) {
    return null;
  }

  return {
    friendlyName:
      data.appConfig?.scanner.friendlyName ?? localStorage.getItem(FRIENDLY_NAME_LOCAL_STORAGE_KEY),
    ignoreUnsyncedData: false,
    organizationId: data.scanner?.organizationId,
    scanner: data.scanner as Scanner,
    scannerId: data.scanner?.id,
    token,
    hasUnsyncedData: hasUnsyncedData(data.metrics?.sync),
  };
};

const getEnabledSteps = (context: Partial<LinkScannerWorkflowContext>): WorkflowStep[] => {
  if (!context) {
    return [];
  }

  const steps = [];

  if (context.hasUnsyncedData) {
    steps.push(UnsyncedDataStepDefinition);
    steps.push(UploadDataStepDefinition);
  }

  if (context.scanner?.eventSubscriptions?.length) {
    steps.push(ConfirmEventStepDefinition);
    steps.push(DownloadDataStepDefinition);
    steps.push(LinkScannerCompleteStepDefinition);
  } else {
    steps.push(NoEventSubscriptionsStepDefinition);
  }

  return steps;
};

export const LinkScannerWorkflow: FC<LinkScannerWorkflowProps> = ({
  organizationId,
  scannerId,
  token,
  ...props
}) => {
  const navigate = useNavigate();
  const result = useGetLinkScannerWorkflowContextQuery({
    context: { organizationId, token },
    variables: { scannerId },
  });

  const defaultContext = useMemo(
    () => getDefaultContext(token, result?.data),
    [token, result?.data],
  );

  const steps = useMemo(() => getEnabledSteps(defaultContext), [defaultContext]);

  const handleAbort = useCallback(
    async (context: LinkScannerWorkflowContext) => {
      navigate(routes.scanningHome());
    },
    [navigate],
  );
  const handleSubmit = useCallback(
    async (context: LinkScannerWorkflowContext) => {
      navigate(routes.scanningHome());
    },
    [navigate],
  );

  if (!defaultContext) {
    return null;
  }

  return (
    <WorkflowRunner
      {...props}
      defaultContext={defaultContext}
      onAbort={handleAbort}
      onSubmit={handleSubmit}
      steps={steps}
    />
  );
};
