import {
  AppConfig,
  CollectionResultMeta,
  Scanner,
  ScannerSettingName,
  ScannerSyncDirection,
  ScannerSyncType,
} from '../../gql/__generated__/graphql';
import { getSetting } from '../AppSettings';
import { AppConfigRepository } from '../repositories/AppConfigRepository';
import { DexieService } from './DexieService';
import { GraphQlClientService } from './GraphQlClientService';
import { SyncService } from './SyncService';
import Apollo from '@apollo/client';

export interface CollectionResult<CollectionType> {
  data: CollectionType[];
  meta: CollectionResultMeta;
}

export abstract class SyncableCollectionService<
  CollectionType,
  IdType = string,
> extends DexieService<CollectionType, IdType> {
  protected abstract readonly scannerSyncType: ScannerSyncType;

  protected abstract readonly downDataSelector: (data: any) => CollectionResult<any>;
  protected abstract readonly downQuery: Apollo.DocumentNode;
  protected abstract readonly settingDownEnabled: ScannerSettingName;
  protected abstract readonly settingDownLimit: ScannerSettingName;
  protected abstract readonly settingDownInterval: ScannerSettingName;

  protected abstract readonly upMutation: Apollo.DocumentNode;

  protected constructor(
    protected readonly syncService: SyncService,
    protected readonly graphqlClientService: GraphQlClientService,
    protected readonly appConfigRepository: AppConfigRepository,
  ) {
    super();
  }

  protected abstract reduce(records: CollectionType[]): Promise<void>;

  protected async fetch(
    scanner: Scanner,
    cursor: number,
    limit: number,
  ): Promise<CollectionType[]> {
    this.logger.debug('Fetching records', { cursor, limit });

    const eventIds = scanner.eventSubscriptions.map((subscription) => subscription.eventId);
    if (!eventIds?.length) {
      return null;
    }

    return this.graphqlClientService.query({
      query: this.downQuery,
      variables: {
        cursor,
        eventIds,
        limit,
      },
    });
  }

  /**
   * Sync a page from the collection from Great Crowd to the scanner
   * @param appConfig
   * @param ignoreInterval Used for forced synchronization in the Debug views
   * @param ignoreLimit Used for forced synchronization in the Debug views
   */
  async syncDown(
    appConfig: AppConfig,
    ignoreInterval = false,
    ignoreLimit: boolean = false,
  ): Promise<number> {
    const enabled =
      getSetting<boolean>(appConfig, this.settingDownEnabled) &&
      appConfig.scanner?.eventSubscriptions?.length;
    const limit = ignoreLimit ? 100 : getSetting<number>(appConfig, this.settingDownLimit);

    if (!ignoreInterval && !enabled) {
      this.logger.debug(`${this.settingDownEnabled} is false. Skipping`);
      return;
    }

    const cursor = await this.syncService.getLastCursor(
      this.scannerSyncType,
      ScannerSyncDirection.Down,
      this.settingDownInterval,
      ignoreInterval,
    );

    if (cursor === null) {
      // Interval has not passed, ignore this sync
      return;
    }

    const start = new Date();
    const result = await this.fetch(appConfig.scanner, cursor, limit);

    if (!result) {
      return;
    }

    // Select the records from the query results
    const { data, meta } = this.downDataSelector(result);

    if (data?.length) {
      await this.reduce(data);
    }

    const end = new Date();

    await this.syncService.create({
      cursor: cursor.toString(10),
      direction: ScannerSyncDirection.Down,
      end: end.toISOString(),
      error: null,
      requested: limit,
      returned: data.length,
      start: start.toISOString(),
      total: meta.totalRecords,
      type: this.scannerSyncType,
    });

    // Return whether there may be more tickets available
    return data.length;
  }
}
