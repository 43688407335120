import { CantScanDialog } from './CantScanDialog';
import Button, { ButtonProps } from '@mui/material/Button';
import { FC, useCallback, useState } from 'react';

interface CantScanButtonProps extends ButtonProps {}

export const CantScanButton: FC<CantScanButtonProps> = (props) => {
  const [showCantScanDialog, setShowCantScanDialog] = useState(false);

  const handleCantScanClick = useCallback(() => {
    setShowCantScanDialog(true);
  }, [setShowCantScanDialog]);

  const handleCantScanDialogClose = useCallback(() => {
    setShowCantScanDialog(false);
  }, [setShowCantScanDialog]);

  return (
    <>
      <Button color="inherit" onClick={handleCantScanClick} {...props}>
        Can't Scan?
      </Button>
      <CantScanDialog onClose={handleCantScanDialogClose} open={showCantScanDialog} />
    </>
  );
};
