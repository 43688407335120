import { AppLayout } from '../../components/AppLayout';
import {
  CheckInMetrics,
  ScanErrorMetrics,
  ScanTimeSeriesData,
  useGetMetricsViewDataQuery,
} from '../../gql/__generated__/graphql';
import { ErrorsByTypeMetric } from './ErrorsByTypeMetric';
import { ScansByTypeMetric } from './ScansByTypeMetric';
import { ScansOverTimeMetric } from './ScansOverTimeMetric';
import { useLogger } from '@greatcrowd/ui-logging';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

export const MetricsView: FC = () => {
  const logger = useLogger(MetricsView.name);

  const { data, error, loading } = useGetMetricsViewDataQuery({
    variables: {
      scannerId: '9b39a24f-f03c-418a-a101-51e0077f3e2b',
    },
  });

  if (error) {
    logger.error('Failed to load MetricsView metrics', error);
  }

  if (!data?.metrics?.scan || loading) {
    return null;
  }

  return (
    <AppLayout showBottomNavigation>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflowX: 'auto', p: 2 }}
      >
        <Stack direction="column" spacing={2}>
          <Typography component="h1" variant="h4">
            Metrics
          </Typography>
          <ScansByTypeMetric
            data={data.metrics.scan.checkIns as CheckInMetrics}
            ticketCount={data.metrics.ticketCount}
          />
          <ScansOverTimeMetric data={data.metrics.scan.timeseries as ScanTimeSeriesData[]} />
          <ErrorsByTypeMetric data={data.metrics.scan.errors as ScanErrorMetrics[]} />
        </Stack>
      </Box>
    </AppLayout>
  );
};
