import { AttendeeWaiverCollection } from '../scanning/AttendeeWaiverCollection.enum';
import { messages } from './messages';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

export interface WaiverCollectionInstructionsProps {
  additional: boolean;

  quantity: number;

  waiverCollection: AttendeeWaiverCollection;
}

const getMessage = (
  additional: boolean,
  waiverCollection: AttendeeWaiverCollection,
): MessageDescriptor => {
  if (additional) {
    return messages.waiverScanInstructionsAdditional;
  }

  return waiverCollection === AttendeeWaiverCollection.ONE_PER_ATTENDEE
    ? messages.wavierScanInstructionsOnePerAttendee
    : messages.wavierScanInstructionsOnePerParty;
};

export const WaiverCollectionInstructions: FC<WaiverCollectionInstructionsProps> = ({
  additional,
  quantity,
  waiverCollection,
}) => {
  const { formatMessage } = useIntl();

  const message = getMessage(additional, waiverCollection);

  return <Typography variant="body2">{formatMessage(message, { quantity })}</Typography>;
};
