interface ScannerEnvironment {
  REACT_APP_COMMIT_SHA: string;
  REACT_APP_GRAPH_URL: string;
  REACT_APP_GREAT_CROWD_HOST: string;
}

const getEnv = (): ScannerEnvironment => {
  try {
    return process.env as unknown as ScannerEnvironment;
  } catch {
    return {} as ScannerEnvironment;
  }
};

/**
 * Provides typing for and safe access to environment variables. This hook is helpful
 * for avoiding `process is not defined` errors in Storybook and other test/dev environments
 *
 * @param name
 * @param defaultValue
 */
export const useEnvironmentVariable = (
  name: keyof ScannerEnvironment,
  defaultValue: string,
): string => {
  return getEnv()[name] || defaultValue;
};
