import { isToday } from '../data/utils/event.utils';
import { Event, useGetAppConfigurationQuery } from '../gql/__generated__/graphql';

const sortOnStart = (event1: Event, event2: Event) => event1.starts.localeCompare(event2.starts);

interface UseEventsReturn {
  events: Event[];
  eventsToday: Event[];
  loading: boolean;
}

export const useEvents = (): UseEventsReturn => {
  const { data, loading } = useGetAppConfigurationQuery();

  const events = (
    data?.appConfig.scanner?.eventSubscriptions?.map(
      (subscription) => subscription.event,
    ) as Event[]
  )?.sort(sortOnStart);

  if (!events) {
    return {
      events: [],
      eventsToday: [],
      loading,
    };
  }

  const eventsToday = events?.filter(isToday);

  return {
    events,
    eventsToday,
    loading,
  };
};
