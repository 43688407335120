import { AppLayout } from '../../components/AppLayout';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';

export const AdminView: FC = () => {
  return (
    <AppLayout showAppBar={false} showBottomNavigation>
      <Outlet />
    </AppLayout>
  );
};
