import { MutationCreateManualScanArgs, Scan } from '../../../gql/__generated__/graphql';
import { TicketService } from '../../services/TicketService';
import { Resolver } from '@apollo/client';
import { Container } from 'typedi';

export const createManualScanResolver: Resolver = async (
  _,
  input: MutationCreateManualScanArgs,
): Promise<Scan> => {
  const ticketService = Container.get(TicketService);

  return ticketService.scanRandomTicketManually(input._localGuestId);
};
