import { WorkflowRunner } from '../../components/workflow/WorkflowRunner';
import { WorkflowStep } from '../../components/workflow/WorkflowStep.interface';
import '../../gql/__generated__/graphql';
import { routes } from '../../routes';
import { DownloadDataStepDefinition } from '../common/steps/DownloadDataStep';
import { UpdateDataCompleteStepDefinition } from './UpdateDataCompleteStep';
import { UpdateDataConfirmStepDefinition } from './UpdateDataConfirmStep';
import { UpdateDataWorkflowContext } from './UpdateDataWorkflowContext.interface';
import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export interface UpdateDataWorkflowProps {}

const getDefaultContext = (): Partial<UpdateDataWorkflowContext> => {
  return {};
};

const getEnabledSteps = (context: Partial<UpdateDataWorkflowContext>): WorkflowStep[] => {
  if (!context) {
    return [];
  }

  return [
    UpdateDataConfirmStepDefinition,
    DownloadDataStepDefinition,
    UpdateDataCompleteStepDefinition,
  ];
};

export const UpdateDataWorkflow: FC<UpdateDataWorkflowProps> = (props) => {
  const navigate = useNavigate();
  const defaultContext = useMemo(() => getDefaultContext(), []);
  const steps = useMemo(() => getEnabledSteps(defaultContext), [defaultContext]);

  const handleAbort = useCallback(
    async (context: UpdateDataWorkflowContext) => {
      navigate(routes.adminDebug());
    },
    [navigate],
  );

  const handleSubmit = useCallback(
    async (context: UpdateDataWorkflowContext) => {
      navigate(routes.scanningHome());
    },
    [navigate],
  );

  if (!defaultContext) {
    return null;
  }

  return (
    <WorkflowRunner
      {...props}
      defaultContext={defaultContext}
      onAbort={handleAbort}
      onSubmit={handleSubmit}
      steps={steps}
    />
  );
};
