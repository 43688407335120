import { IRepository } from './IRepository';
import { Collection, Table } from 'dexie';

export abstract class DexieRepository<RecordType, IdType = string>
  implements IRepository<RecordType, IdType>
{
  protected abstract readonly table: Table<RecordType, any>;

  async clear(): Promise<void> {
    return this.table.clear();
  }

  async count(): Promise<number> {
    return this.table.count();
  }

  async create(obj: any): Promise<IdType> {
    return this.table.add(obj);
  }

  async createMany(obj: any[], keys?: string[]): Promise<IdType> {
    return this.table.bulkPut(obj, keys);
  }

  async findOne(id: IdType): Promise<RecordType> {
    return this.table.get(id);
  }

  async findOneBy(where: Record<string, string>): Promise<RecordType> {
    return this.table.get(where);
  }

  async lastByPredicate(filter: (record: RecordType) => boolean): Promise<RecordType> {
    return this.table.reverse().filter(filter).first();
  }

  async listByOffset(offset: number, take: number): Promise<RecordType[]> {
    return this.table
      .orderBy('_seq')
      .offset(offset || 0)
      .limit(take)
      .toArray();
  }

  async listByPredicate(
    filter: (record: RecordType) => boolean,
    offset: number,
    take: number,
  ): Promise<RecordType[]> {
    return this.table
      .filter(filter)
      .offset(offset || 0)
      .limit(take)
      .toArray();
  }

  async listByPage(
    page?: number,
    limit?: number,
    orderBy?: string,
    filter?: (obj: RecordType) => boolean,
    query?: string,
  ): Promise<RecordType[]> {
    let collection: Collection<RecordType, any>;

    if (filter) {
      // Must perform a full scan of the table when using advanced filtering
      collection = orderBy ? this.table.orderBy(orderBy).filter(filter) : this.table.filter(filter);
    } else {
      collection = orderBy ? this.table.orderBy(orderBy) : this.table.toCollection();
    }

    if (page) {
      collection.offset(page * limit);
    }

    if (limit) {
      collection.limit(limit);
    }

    return collection.toArray();
  }

  async listByFk(conditions: Record<string, string>): Promise<RecordType[]> {
    return this.table.where(conditions).toArray();
  }

  async single(): Promise<RecordType> {
    const records = await this.listByPage(0, 1);

    return records[0];
  }

  async update(obj: RecordType): Promise<IdType> {
    return this.table.put(obj);
  }

  async updatePartial(id: IdType, obj: Partial<RecordType>): Promise<void> {
    const count = await this.table.update(id, obj);

    if (!count) {
      throw new Error(`Object with ID "${id}" not found`);
    }
  }
}
