import { AttendeeWaiverCollection } from '../scanning/AttendeeWaiverCollection.enum';
import { WaiverCollectionProgressProps } from './WaiverCollectionCard';
import { messages } from './messages';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';
import { useIntl } from 'react-intl';

export const WaiverCollectionProgress: FC<WaiverCollectionProgressProps> = ({
  additional,
  quantity,
  waiverCollection,
  waivers,
}) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  return (
    <Paper
      sx={{
        alignItems: 'center',
        backgroundColor: theme.palette.grey['300'],
        display: 'flex',
        minHeight: 48,
        paddingX: 2,
      }}
    >
      <Typography variant="subtitle2">
        {additional || waiverCollection === AttendeeWaiverCollection.ONE_PER_PARTY
          ? formatMessage(messages.waiversScannedLabelOnePerParty, {
              quantity,
              count: waivers.length,
            })
          : formatMessage(messages.waiversScannedLabelOnePerAttendee, {
              quantity,
              count: waivers.length,
            })}
      </Typography>
    </Paper>
  );
};
