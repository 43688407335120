import { MutationCreateScanArgs, Scan } from '../../../gql/__generated__/graphql';
import { TicketService } from '../../services/TicketService';
import { Resolver } from '@apollo/client';
import { Container } from 'typedi';

export const createScanResolver: Resolver = async (
  _,
  input: MutationCreateScanArgs,
): Promise<Scan> => {
  const ticketService = Container.get(TicketService);

  return ticketService.scanRawTicketData(input.data);
};
