import { AdminLayout } from '../../components/admin/AdminLayout';
import { AdminLayoutContent } from '../../components/admin/AdminLayoutContent';
import { AdminLayoutTitle } from '../../components/admin/AdminLayoutTitle';
import { routes } from '../../routes';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const AdminLoginView: FC = () => {
  const navigate = useNavigate();
  const handleSubmit = useCallback(() => {
    navigate(routes.adminSettings());
  }, [navigate]);

  return (
    <AdminLayout ToolbarProps={{ back: routes.adminSettings() }}>
      <AdminLayoutTitle>Access Event Manager Panel</AdminLayoutTitle>
      <AdminLayoutContent sx={{ pt: 6 }}>
        <form onSubmit={handleSubmit} style={{ height: '100%' }}>
          <Stack spacing={2} sx={{ height: '100%', pb: 6 }}>
            <Box sx={{ flexGrow: 1 }}>
              <TextField
                id="passcode"
                helperText="This passcode was given to you by the event manager"
                label="Passcode"
                variant="outlined"
                size="small"
                sx={{ width: '100%' }}
                type="password"
              />
            </Box>
            <Button
              color="primary"
              size="large"
              sx={{ width: '100%' }}
              type="submit"
              variant="contained"
            >
              Log In
            </Button>
          </Stack>
        </form>
      </AdminLayoutContent>
    </AdminLayout>
  );
};
