import { getScannedTickets } from '../../data/utils/guest.util';
import { Guest } from '../../gql/__generated__/graphql';
import { routes } from '../../routes';
import { GuestStatusChip } from './GuestStatusChip';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import { CSSProperties, FC } from 'react';
import { Link } from 'react-router-dom';

interface GuestListItemProps {
  guest: Guest;

  index: number;

  style: CSSProperties;
}

export const GuestListItem: FC<GuestListItemProps> = ({ guest, index, style }) => {
  const theme = useTheme();

  const fullName = [guest.givenName, guest.surname].filter((namePart) => !!namePart).join(' ');
  const disabled = false;

  return (
    <ListItem
      component={disabled ? undefined : Link}
      disabled={disabled}
      disableGutters
      secondaryAction={
        <GuestStatusChip
          totalTickets={guest.tickets.length}
          totalTicketsScanned={getScannedTickets(guest).length}
        />
      }
      style={style}
      sx={{ color: 'inherit' }}
      to={routes.lookupGuestsDetail({ guestId: guest.guestId })}
    >
      <ListItemText
        primary={fullName || '-'}
        primaryTypographyProps={{
          variant: 'body2',
          sx: { fontWeight: theme.typography.fontWeightBold },
        }}
        secondary={guest.guestId}
        secondaryTypographyProps={{
          variant: 'body2',
        }}
      />
    </ListItem>
  );
};
