import { useGuestData } from '../hooks/useGuestData';
import { DownloadDataAction } from '../workers/actions/DownloadDataAction';
import { SyncDataAction } from '../workers/actions/SyncDataAction';
import { UploadDataAction } from '../workers/actions/UploadDataAction';
import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';

export enum DataSyncType {
  /**
   * Rapidly download all data
   */
  Download = 'DataSyncType__Download',

  /**
   * No sync
   */
  None = 'DataSyncType__NoSync',

  /**
   * Normal sync loop
   */
  Sync = 'DataSyncType__Sync',

  /**
   * Rapidly upload all data
   */
  Upload = 'DataSyncType__Upload',
}

export interface DataSyncContextValue {
  currentSync: DataSyncType;
  setCurrentSync: ReturnType<typeof useState<DataSyncType>>[1];
}

export const DataSyncContext = createContext<DataSyncContextValue>(null);

export const useDataSyncContext = () => useContext(DataSyncContext);

export const DataSyncContextProvider = ({ children }: PropsWithChildren) => {
  const [currentSync, setCurrentSync] = useState<DataSyncType>();
  const { dispatch } = useGuestData();

  useEffect(() => {
    switch (currentSync) {
      case DataSyncType.Download:
        dispatch(DownloadDataAction.from({}));
        return;
      case DataSyncType.Upload:
        dispatch(UploadDataAction.from({}));
        return;
      case DataSyncType.None:
        return;
      // Continuously sync data to/from the backend when we're not initializing or dumping an event
      default:
        const timer = setInterval(() => {
          dispatch(new SyncDataAction());
        }, 1_000);

        return () => {
          clearInterval(timer);
        };
    }
  }, [currentSync]);

  return (
    <DataSyncContext.Provider value={{ currentSync, setCurrentSync }}>
      {children}
    </DataSyncContext.Provider>
  );
};
