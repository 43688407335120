import Typography from '@mui/material/Typography';
import { FC } from 'react';

interface AdminLayoutTitleProps {
  children: string;
}

export const AdminLayoutTitle: FC<AdminLayoutTitleProps> = ({ children }) => {
  return (
    <Typography component="h2" variant="h4">
      {children}
    </Typography>
  );
};
