import {
  AppConfig,
  AttendeeWaiver,
  PushWaiversDocument,
  PushWaiversMutation,
  ScannerSettingName,
  ScannerSyncDirection,
  ScannerSyncType,
  SyncCountMetrics,
} from '../../gql/__generated__/graphql';
import { getSetting } from '../AppSettings';
import { WaiverRepository } from '../repositories/WaiverRepository';
import { AppConfigService } from './AppConfigService';
import { DexieService } from './DexieService';
import { GraphQlClientService } from './GraphQlClientService';
import { ScanService } from './ScanService';
import { SyncService } from './SyncService';
import { Logger } from '@greatcrowd/ui-logging';
import { Service } from 'typedi';
import { v4 as uuidV4 } from 'uuid';

@Service()
export class WaiverService extends DexieService<AttendeeWaiver, number> {
  protected readonly logger = new Logger(WaiverService.name);
  protected readonly defaultSortField: string = 'id';

  constructor(
    protected readonly repository: WaiverRepository,
    private readonly appConfigService: AppConfigService,
    private readonly graphqlClientService: GraphQlClientService,
    private readonly scanService: ScanService,
    private readonly syncService: SyncService,
  ) {
    super();
  }

  async addWaiver(scanId: string, imageData: string): Promise<AttendeeWaiver> {
    const scan = await this.scanService.findOneById(scanId);

    const _localWaiverId = await this.create({
      // TODO: Some older versions of the scanner neglected to add a UUID to the waiver record. If you're
      //  reading this, line 64 can be deleted.
      id: uuidV4(),
      imageData,
      scanId: scan.id,
      timestamp: new Date().toISOString(),
    });

    return this.findOne(_localWaiverId);
  }

  async getUnsynced(cursor: number, limit: number = 100): Promise<AttendeeWaiver[]> {
    return this.repository.listByOffset(cursor, limit);
  }

  async findByLocalScanId(scanId: string): Promise<AttendeeWaiver[]> {
    return this.repository.listByScanId(scanId);
  }

  private async push(waivers: AttendeeWaiver[]): Promise<void> {
    this.logger.debug('Pushing waivers', { count: waivers.length });

    for (const waiver of waivers) {
      // TODO: Some older versions of the scanner neglected to add a UUID to the waiver record. If you're
      //  reading this, the next line can be deleted.
      if (!waiver.id) {
        const id = uuidV4();
        waiver.id = id;
        await this.repository.updatePartial(waiver._seq, { id });
      }
    }

    const result = await this.graphqlClientService.mutate<PushWaiversMutation>({
      mutation: PushWaiversDocument,
      variables: {
        waivers: waivers.map((waiver) => ({
          id: waiver.id,
          scanId: waiver.scanId,
          timestamp: waiver.timestamp,
        })),
      },
    });
  }

  async syncUp(appConfig: AppConfig, ignoreInterval = false, ignoreLimit = false): Promise<void> {
    const enabled = getSetting<boolean>(appConfig, ScannerSettingName.SyncWaiversUpEnabled);
    const limit = ignoreLimit
      ? 100
      : getSetting<number>(appConfig, ScannerSettingName.SyncWaiversUpLimit);

    if (!enabled) {
      this.logger.debug('SYNC__WAIVERS_UP_ENABLED is false');
      return;
    }

    const cursor = await this.syncService.getLastCursor(
      ScannerSyncType.Waiver,
      ScannerSyncDirection.Up,
      ScannerSettingName.SyncWaiversUpInterval,
      ignoreInterval,
    );
    if (cursor === null) {
      return;
    }

    const unsynced = await this.getUnsynced(cursor, limit);

    if (!unsynced?.length) {
      return;
    }

    let error = null;

    const start = new Date();
    try {
      await this.push(unsynced);
    } catch (err) {
      this.logger.error('Failed to push waivers', err, { count: unsynced.length });
      error = err.toString();
    }
    const end = new Date();

    await this.syncService.create({
      cursor: (cursor + unsynced.length).toString(),
      direction: ScannerSyncDirection.Up,
      end: end.toISOString(),
      error,
      requested: unsynced.length,
      returned: 0,
      start: start.toISOString(),
      type: ScannerSyncType.Waiver,
    });
  }

  async getUnsyncedCount() {
    const metrics = await this.getSyncUpMetrics();
    return metrics.total - metrics.totalSynced;
  }

  async getSyncUpMetrics(): Promise<SyncCountMetrics> {
    const lastSyncUp = await this.syncService.lastSuccessful(
      ScannerSyncType.Waiver,
      ScannerSyncDirection.Up,
    );

    return {
      total: await this.count(),
      totalSynced: parseInt(lastSyncUp?.cursor ?? '0'),
    };
  }
}
