import { Scan, Ticket } from '../../gql/__generated__/graphql';

export const getScan = (ticket: Ticket, scan: Partial<Scan>): Scan => {
  return {
    _seq: 0,
    created: null,
    error: null,
    id: null,
    ipAddress: null,
    method: null,
    organizationId: null,
    scannerId: '54fd6bb1-1791-4cd5-908d-4684b9169384',
    scanner: null,
    ticketId: ticket?.id,
    ticket: null,
    timestamp: ticket?.scanned,
    type: null,
    updated: null,
    url: `https://cart.nightlightsevent.com/?event_qr_code=1&ticket_id=3198&event_id=338&security_code=${ticket?.code}&path=wp-json%2Ftribe%2Ftickets%2Fv1%2Fqr`,
    __typename: 'Scan',
    ...scan,
  };
};
