import { AttendeeWaiver } from '../../gql/__generated__/graphql';
import { AttendeeWaiverCollection } from '../scanning/AttendeeWaiverCollection.enum';
import { NoWaiverButton } from './NoWaiverButton';
import { ScanWaiverButton } from './ScanWaiverButton';
import { ScanWaiverDialog } from './ScanWaiverDialog';
import { WaiverCollectionInstructions } from './WaiverCollectionInstructions';
import { WaiverCollectionProgress } from './WaiverCollectionProgress';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { FC, useCallback, useState } from 'react';

interface WaiverProps {
  additional?: boolean;

  collection?: AttendeeWaiverCollection;

  disabled?: boolean;

  loading?: boolean;

  onCancel?: () => Promise<void>;

  onConfirm?: (imageData: string) => Promise<void>;

  quantity?: number;

  waivers?: any[];
}

export interface WaiverCollectionProgressProps {
  additional: boolean;

  quantity: number;

  waiverCollection: AttendeeWaiverCollection;

  waivers: AttendeeWaiver[];
}

export const WaiverCollectionCard: FC<WaiverProps> = ({
  additional = false,
  collection = AttendeeWaiverCollection.ONE_PER_PARTY,
  disabled = false,
  loading,
  onCancel,
  onConfirm,
  quantity,
  waivers = [],
}) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleScanClick = useCallback(() => {
    setDialogOpen(true);
  }, [setDialogOpen]);

  const handleClose = useCallback(() => {
    setDialogOpen(false);
  }, [setDialogOpen]);

  return (
    <Box
      sx={{
        textAlign: 'center',
        width: '100%',
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <Stack direction="column" spacing={2}>
          <WaiverCollectionInstructions
            additional={additional}
            quantity={quantity}
            waiverCollection={collection}
          />
          {(additional || !!waivers.length) && (
            <WaiverCollectionProgress
              additional={additional}
              quantity={quantity}
              waiverCollection={collection}
              waivers={waivers}
            />
          )}
          <ScanWaiverButton
            additional={additional}
            disabled={disabled}
            onClick={handleScanClick}
            quantity={quantity}
            waiverCollection={collection}
            waivers={waivers}
          />
          {!additional && <NoWaiverButton onCancel={onCancel} />}
          <ScanWaiverDialog onClose={handleClose} onConfirm={onConfirm} open={dialogOpen} />
        </Stack>
      )}
    </Box>
  );
};
