import { AdditionalWaiverCollectionCard } from '../../../components/waiver/AdditionalWaiverCollectionCard';
import { Scan } from '../../../gql/__generated__/graphql';
import { ScanningResultErrorCard } from './ScanningResultErrorCard';
import { ScanningResultLayout } from './ScanningResultLayout';
import { FC } from 'react';

interface ScanningResultUnknownTicketErrorProps {
  scan: Scan;
}

export const ScanningResultUnknownTicketError: FC<ScanningResultUnknownTicketErrorProps> = ({
  scan,
}) => {
  return (
    <ScanningResultLayout title="Unknown Ticket">
      <ScanningResultErrorCard />

      <AdditionalWaiverCollectionCard scanId={scan.id} />
    </ScanningResultLayout>
  );
};
