import {
  AddWaiverDocument,
  AddWaiverMutation,
  AttendeeWaiver,
  ScannerSettingName,
  useGetScanLazyQuery,
} from '../../gql/__generated__/graphql';
import { useAppSetting } from '../../hooks/useAppSetting';
import { AttendeeWaiverCollection } from '../scanning/AttendeeWaiverCollection.enum';
import { WaiverCollectionCard } from './WaiverCollectionCard';
import { useApolloClient } from '@apollo/client';
import { useLogger } from '@greatcrowd/ui-logging';
import Paper from '@mui/material/Paper';
import { FC, useCallback, useEffect } from 'react';

interface AdditionalWaiverCollectionCardProps {
  scanId: number;
}

export const AdditionalWaiverCollectionCard: FC<AdditionalWaiverCollectionCardProps> = ({
  scanId,
}) => {
  const waiverCollection = useAppSetting<AttendeeWaiverCollection>(
    ScannerSettingName.ScanWaiverCollection,
  );
  const client = useApolloClient();
  const logger = useLogger(AdditionalWaiverCollectionCard.name);
  const [getScan, { called, data, loading, error }] = useGetScanLazyQuery({
    variables: { scanId: scanId },
  });

  const scan = data?.scan;
  const waivers = scan?.waivers as AttendeeWaiver[];

  useEffect(() => {
    (async () => {
      await getScan();
    })();
  }, [getScan]);

  const handleConfirm = useCallback(
    async (imageData: string) => {
      await client.mutate<AddWaiverMutation>({
        mutation: AddWaiverDocument,
        variables: {
          scanId: scanId,
          imageData,
        },
      });

      // Because we don't use cache for local queries, we are not able to update cache
      await getScan();
    },
    [scanId, client, getScan],
  );

  const waiversRequired = waiverCollection !== AttendeeWaiverCollection.NONE;
  if (!waiversRequired) {
    return null;
  }

  if (error) {
    logger.error('Failed to fetch scan', error, { scanId: scanId });
  }

  if (!called) {
    return null;
  }

  return (
    <Paper sx={{ p: 2 }}>
      <WaiverCollectionCard
        additional
        loading={loading}
        onConfirm={handleConfirm}
        waivers={waivers}
      />
    </Paper>
  );
};
