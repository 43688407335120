import { AttendeeWaiver, Scan, Ticket } from '../../../gql/__generated__/graphql';
import { TicketService } from '../../services/TicketService';
import { WaiverService } from '../../services/WaiverService';
import { addTypename } from './addTypename';
import { Resolvers } from '@apollo/client';
import { Container } from 'typedi';

export const scanResolver: Resolvers = {
  Scan: {
    ticket: async (parent: Scan): Promise<Ticket> => {
      const ticketService = Container.get(TicketService);
      const ticketId = parent?.ticketId;

      if (!ticketId) {
        return null;
      }

      return addTypename(ticketService.findOne(ticketId), 'Ticket');
    },
    waivers: async (parent: Scan): Promise<AttendeeWaiver[]> => {
      const waiverService = Container.get(WaiverService);

      return addTypename<AttendeeWaiver[]>(waiverService.findByLocalScanId(parent.id), 'Waiver');
    },
  },
};
