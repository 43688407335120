import { AdminLayout } from '../../components/admin/AdminLayout';
import { AdminLayoutContent } from '../../components/admin/AdminLayoutContent';
import { AdminLayoutFooter } from '../../components/admin/AdminLayoutFooter';
import { AdminLayoutTitle } from '../../components/admin/AdminLayoutTitle';
import { RoutedSettingsListItem } from '../../components/admin/RoutedSettingsListItem';
import { useGetAppConfigurationQuery } from '../../gql/__generated__/graphql';
import { useEnvironmentVariable } from '../../hooks/useEnvironmentVariable';
import { routes } from '../../routes';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';

export const AdminSettingsView: FC = () => {
  const theme = useTheme();
  const handleLogoutClick = useCallback(() => {}, []);
  const greatCrowdHost = useEnvironmentVariable(
    'REACT_APP_GREAT_CROWD_HOST',
    'https://test.app.greatcrowd.io',
  );
  const version = useEnvironmentVariable('REACT_APP_COMMIT_SHA', 'localhost').substring(0, 8);

  const { data } = useGetAppConfigurationQuery();
  const scanner = data?.appConfig.scanner;
  const scannerSettingsUrl = `${greatCrowdHost}/${scanner?.organizationId}/scanners/${scanner?.id}/settings`;

  return (
    <AdminLayout>
      <AdminLayoutTitle>Settings</AdminLayoutTitle>
      <AdminLayoutContent>
        <List sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}>
          {/*<RoutedSettingsListItem label="Access Event Manager Panel" to={routes.adminLogin()} />*/}
          <RoutedSettingsListItem label="Scanner Settings" to={scannerSettingsUrl} />
          <RoutedSettingsListItem label="Change Event" to={routes.linkScannerScan()} />
        </List>
      </AdminLayoutContent>
      <AdminLayoutFooter>
        <Stack direction="column" spacing={1} sx={{ alignItems: 'start' }}>
          <Button color="error" onClick={handleLogoutClick}>
            Logout
          </Button>
          <Button
            component={Link}
            color="inherit"
            sx={{ textTransform: 'none' }}
            to={routes.adminDebug()}
          >
            Version: {version.toLowerCase()}
          </Button>
        </Stack>
      </AdminLayoutFooter>
    </AdminLayout>
  );
};
