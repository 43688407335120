import { useCleanup } from './useCleanup';
import { useLogger } from '@greatcrowd/ui-logging';
import QrScanner from 'qr-scanner';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export enum QrCodeScannerState {
  ERROR = 'ERROR',
  INITIALIZING = 'INITIALIZING',
  RENDERING = 'RENDERING',
  SCANNING = 'SCANNING',
}

export enum QrCodeScannerError {
  NO_CAMERA = 'NO_CAMERA',
}

export interface UseQrCodeScannerHookOptions {
  onResult: (data: string) => void;
  qrScannerOptions?: {
    calculateScanRegion?: (video: HTMLVideoElement) => QrScanner.ScanRegion;
  };
  setSecondVideoRef?: (video: HTMLVideoElement) => void;
}

type UseQrCodeScannerHookResponse = {
  error: QrCodeScannerError;
  state: QrCodeScannerState;
  videoRef: (node: HTMLVideoElement) => void;
};

export const useQrCodeScanner = ({
  onResult,
  qrScannerOptions,
  setSecondVideoRef = () => {},
}: UseQrCodeScannerHookOptions): UseQrCodeScannerHookResponse => {
  const logger = useLogger(useQrCodeScanner.name);
  const [error, setError] = useState<QrCodeScannerError>();
  const [scannerState, setScannerState] = useState<QrCodeScannerState>(
    QrCodeScannerState.RENDERING,
  );
  const videoRef = useRef<HTMLVideoElement>();
  const setVideoRef = useCallback(
    (node: HTMLVideoElement) => {
      if (!node) {
        return;
      }

      setScannerState(QrCodeScannerState.INITIALIZING);
      videoRef.current = node;
      setSecondVideoRef(node);
    },
    [setSecondVideoRef],
  );

  const handleResult = useCallback(
    (result: QrScanner.ScanResult) => {
      onResult?.(result.data);
    },
    [onResult],
  );

  const handleError = useCallback((err: Error | string) => {
    if (err === 'No QR code found') {
      return;
    } else {
      logger.warn('Failed to decode QR code', err);
    }
  }, []);

  const scanner = useMemo(() => {
    if (scannerState !== QrCodeScannerState.INITIALIZING) {
      return;
    }

    return new QrScanner(videoRef.current, handleResult, {
      highlightCodeOutline: true,
      highlightScanRegion: true,
      maxScansPerSecond: 4,
      onDecodeError: handleError,
      preferredCamera: 'environment',
      returnDetailedScanResult: true,
      ...qrScannerOptions,
    });
  }, [videoRef.current?.id]);

  useCleanup(scanner, (scanner) => {
    if (!scanner) {
      return;
    }

    scanner.stop();
    scanner.destroy();
  });

  useEffect(() => {
    if (!scanner || scannerState !== QrCodeScannerState.INITIALIZING) {
      return;
    }

    const initializeScanner = async () => {
      const hasCamera = await QrScanner.hasCamera();
      if (!hasCamera) {
        logger.error('Camera is not available');
        setScannerState(QrCodeScannerState.ERROR);
        setError(QrCodeScannerError.NO_CAMERA);
      }

      // const cameras = await QrScanner.listCameras();
      //
      // const camera = cameras[0];
      // await scanner.setCamera(camera.id);

      setScannerState(QrCodeScannerState.SCANNING);
      await scanner.start();
    };

    // noinspection JSIgnoredPromiseFromCall
    initializeScanner();
  }, [scanner]);

  return {
    error,
    state: scannerState,
    videoRef: setVideoRef,
  };
};
