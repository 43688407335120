import { Guest } from '../../gql/__generated__/graphql';
import { GreatCrowdScannerDatabase } from '../GreatCrowdScannerDatabase';
import { DexieRepository } from './DexieRepository';
import { Table } from 'dexie';
import { Service } from 'typedi';

@Service()
export class GuestRepository extends DexieRepository<Guest> {
  protected readonly table: Table<Guest>;

  constructor(db: GreatCrowdScannerDatabase) {
    super();

    this.table = db.guests;
  }

  async countBy(search: string): Promise<number> {
    if (!search?.trim()) {
      return this.table.count();
    }

    return this.table.where('email').startsWith(search).count();
  }

  async search(limit: number, cursor: number, search: string): Promise<Guest[]> {
    if (!search?.trim()) {
      return this.table.offset(cursor).limit(limit).toArray();
    }

    return this.table.where('email').startsWith(search).offset(cursor).limit(limit).toArray();
  }
}
