import { BasicToolbar } from '../../components/BasicToolbar';
import { getRecognizedScanDataById } from '../../data/utils/recognizedScanData.util';
import { RecognizedScanData, ScannerSettingName } from '../../gql/__generated__/graphql';
import { useAppSetting } from '../../hooks/useAppSetting';
import { routes } from '../../routes';
import { ButtonStack } from '@greatcrowd/ui-button';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';

export interface ScanningNoteViewProps {}

export const ScanningNoteView: FC<ScanningNoteViewProps> = () => {
  const theme = useTheme();
  const recognizedScanDataSettings = useAppSetting<string>(ScannerSettingName.ScanDataRecognized);
  const routeParams = useParams();
  const id = routeParams.id;

  const recognizedScanData: RecognizedScanData = getRecognizedScanDataById(
    recognizedScanDataSettings,
    id,
  );

  if (!recognizedScanData) {
    return null;
  }

  const color = theme.palette.warning;

  return (
    <Stack
      direction="column"
      spacing={1}
      sx={{
        backgroundColor: color.main,
        color: theme.palette.common.white,
        height: '100%',
        p: 2,
        width: '100%',
      }}
    >
      <BasicToolbar back={routes.scanningHome()} />
      <Stack
        direction="column"
        spacing={2}
        sx={{
          height: '100%',
          justifyContent: 'space-between',
          pb: 3,
        }}
      >
        <Stack sx={{ flexGrow: 1 }}>
          <Typography
            component="h1"
            sx={{ fontWeight: theme.typography.fontWeightMedium, mb: 2 }}
            variant="h4"
          >
            {recognizedScanData.name}
          </Typography>
          <Stack direction="column" spacing={2}>
            <Paper elevation={1} sx={{ p: 2, textAlign: 'center' }}>
              <Typography variant="subtitle1">
                <strong>NOT A TICKET</strong>
              </Typography>
              <Typography sx={{ my: 2 }} variant="body1">
                {recognizedScanData.message}
              </Typography>
            </Paper>
          </Stack>
        </Stack>
        <ButtonStack sx={{ flexGrow: 0 }}>
          <Button
            color="primary"
            component={Link}
            size="large"
            to={routes.scanningTicketsScanner()}
            variant="contained"
          >
            Back to Ticket Scanner
          </Button>
        </ButtonStack>
      </Stack>
    </Stack>
  );
};
