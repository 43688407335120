import { getScannedTickets } from '../../data/utils/guest.util';
import { Guest, Ticket } from '../../gql/__generated__/graphql';
import { useEvents } from '../../hooks/useEvents';
import { TicketQrCode } from '../ticket/TicketQrCode';
import { CheckedInCountChip } from './CheckedInCountChip';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SxProps, useTheme } from '@mui/material/styles';
import { FC } from 'react';

const QR_CODE_SIZE = 88;

interface GuestTicketProps {
  guest?: Guest;

  loading?: boolean;

  qrCode?: boolean;

  ticket?: Ticket;

  sx?: SxProps;
}

export const GuestTicket: FC<GuestTicketProps> = ({
  guest,
  loading,
  qrCode = false,
  ticket,
  sx,
}) => {
  const theme = useTheme();
  const { events } = useEvents();

  const fullName = `${guest.givenName} ${guest.surname || ''}`;

  const qrCodeGraphic = qrCode ? (
    <TicketQrCode ticket={ticket} width={QR_CODE_SIZE * 2} errorCorrectionLevel="L" />
  ) : null;

  const event = ticket ? events?.find((event) => event.id === ticket.eventId) : null;

  return (
    <Stack direction="column" spacing={0} sx={{ ...sx, width: '100%' }}>
      <Paper>
        <Box
          sx={{
            borderColor: theme.palette.divider,
            borderRadius: 1,
            borderStyle: 'solid',
            borderWidth: 1,
            p: 2,
            textAlign: 'center',
          }}
        >
          {qrCodeGraphic || loading ? (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                m: 1,
                minHeight: 176,
              }}
            >
              {loading ? <CircularProgress /> : qrCodeGraphic}
            </Box>
          ) : null}
          <Stack direction="column" spacing={1} sx={{ alignItems: 'center' }}>
            <Typography
              sx={{ fontWeight: theme.typography.fontWeightMedium }}
              component="h2"
              variant="h3"
            >
              {fullName}
            </Typography>
            <CheckedInCountChip
              checkedIn={getScannedTickets(guest).length}
              total={guest.tickets.length}
            />

            {event?.name && <Typography variant="h4">{event.name}</Typography>}
          </Stack>
        </Box>
      </Paper>
    </Stack>
  );
};
