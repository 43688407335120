import { CheckInMetrics } from '../../gql/__generated__/graphql';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { PieChart } from '@mui/x-charts';
import { FC } from 'react';
import { FormattedNumber } from 'react-intl';

export interface ScansByTypeMetricProps {
  data: CheckInMetrics;

  ticketCount: number;
}

export const ScansByTypeMetric: FC<ScansByTypeMetricProps> = ({ data, ticketCount }) => {
  const theme = useTheme();
  const checkedInCount = data.scan + data.manual;
  const percentCheckedIn = (data.scan + data.manual) / ticketCount;

  return (
    <Paper sx={{ backgroundColor: theme.palette.grey[200], p: 2, pb: 0 }}>
      <Stack sx={{ alignItems: 'center', textAlign: 'center' }}>
        <Typography variant="subtitle1">Scans By Type</Typography>
        <Typography component="h3" variant="h5">
          <FormattedNumber value={checkedInCount} />/<FormattedNumber value={ticketCount} /> Tickets
          Scanned
        </Typography>
        <Typography sx={{ color: theme.palette.grey[600] }} variant="overline">
          All Scanners
        </Typography>

        <Box sx={{ position: 'relative' }}>
          <PieChart
            colors={[
              theme.palette.primary.light,
              theme.palette.primary.main,
              // @ts-ignore
              theme.palette.primary.lighter,
            ]}
            height={150}
            series={[
              {
                cornerRadius: 0,
                cx: 145,
                cy: 150,
                data: [
                  { id: 1, value: data.manual },
                  { id: 2, value: data.scan },
                  { id: 0, value: data.none },
                ],
                endAngle: 90,
                innerRadius: 100,
                outerRadius: 135,
                paddingAngle: 0,
                startAngle: -90,
              },
            ]}
            width={300}
          />
          <Paper
            elevation={2}
            sx={{
              bottom: theme.spacing(1),
              left: '50%',
              p: 1,
              position: 'absolute',
              transform: 'translate(-50%)',
            }}
          >
            <Typography variant="h5">
              <FormattedNumber minimumFractionDigits={2} style="percent" value={percentCheckedIn} />
            </Typography>
            <Typography variant="overline">Checked In</Typography>
          </Paper>
        </Box>
      </Stack>
    </Paper>
  );
};
