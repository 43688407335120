import { WorkflowStep, WorkflowStepProps } from '../../components/workflow/WorkflowStep.interface';
import { WorkflowStepLayout } from '../../components/workflow/WorkflowStepLayout';
import { ClearDataWorkflowContext } from './ClearDataWorkflowContext.interface';
import { ButtonStack } from '@greatcrowd/ui-button';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';

export interface ClearDataCompleteStepProps extends WorkflowStepProps<ClearDataWorkflowContext> {}

export const ClearDataConfirmStep: FC<ClearDataCompleteStepProps> = ({
  context,
  onAbort,
  onNext,
  onSubmit,
  setContext,
  stepIndex,
  steps,
}) => {
  const dataSaved: boolean = context.hasUnsyncedData && !context.ignoreUnsyncedData;

  return (
    <WorkflowStepLayout disableStepper steps={steps} stepIndex={stepIndex}>
      <Stack spacing={4} sx={{ flexGrow: 1, pt: 6 }}>
        <Typography color="error" component="h2" variant="h4">
          Clear all data from this device?
        </Typography>
        <Stack spacing={2}>
          {dataSaved && (
            <Typography variant="body1">All data has been saved to Great Crowd.</Typography>
          )}
          <Typography variant="body1">
            After continuing,{!dataSaved && ' all un-saved data will be lost and'} scanning will be
            unavailable until this scanner is linked to a new event.
          </Typography>
        </Stack>
      </Stack>
      <ButtonStack sx={{ flexGrow: 0, pb: 3 }}>
        <Button color="error" onClick={onSubmit} size="large" variant="contained">
          Clear Data
        </Button>
        <Button color="primary" onClick={onAbort} size="large" variant="outlined">
          Cancel
        </Button>
      </ButtonStack>
    </WorkflowStepLayout>
  );
};

export const ClearDataConfirmStepDefinition: WorkflowStep = {
  Component: ClearDataConfirmStep,
  id: 'confirm',
};
