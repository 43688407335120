import { WorkflowStep, WorkflowStepProps } from '../../components/workflow/WorkflowStep.interface';
import { WorkflowStepLayout } from '../../components/workflow/WorkflowStepLayout';
import { UpdateDataWorkflowContext } from './UpdateDataWorkflowContext.interface';
import { ButtonStack } from '@greatcrowd/ui-button';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';

export interface UpdateDataCompleteStepProps extends WorkflowStepProps<UpdateDataWorkflowContext> {}

export const UpdateDataConfirmStep: FC<UpdateDataCompleteStepProps> = ({
  context,
  onAbort,
  onNext,
  onSubmit,
  setContext,
  stepIndex,
  steps,
}) => {
  return (
    <WorkflowStepLayout disableStepper steps={steps} stepIndex={stepIndex}>
      <Stack spacing={4} sx={{ flexGrow: 1, pt: 6 }}>
        <Typography component="h2" variant="h4">
          Update data on this device?
        </Typography>
        <Stack spacing={2}>
          <Typography variant="body1">
            This process uses cellular data and may prevent other devices from fetching scan data if
            they're sharing the same WiFi access point.
          </Typography>
          <Typography variant="body1">
            NOTE: This process can be safely restarted at any time.
          </Typography>
        </Stack>
      </Stack>
      <ButtonStack sx={{ flexGrow: 0, pb: 3 }}>
        <Button color="primary" onClick={onNext} size="large" variant="contained">
          Update Data
        </Button>
        <Button color="primary" onClick={onAbort} size="large" variant="outlined">
          Cancel
        </Button>
      </ButtonStack>
    </WorkflowStepLayout>
  );
};

export const UpdateDataConfirmStepDefinition: WorkflowStep = {
  Component: UpdateDataConfirmStep,
  id: 'confirm',
};
