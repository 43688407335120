import { client } from './client';
import { ApolloProvider } from '@apollo/client';
import { FC, ReactNode } from 'react';

interface GuestDataApolloProviderProps {
  children: ReactNode;
}

export const GuestDataApolloProvider: FC<GuestDataApolloProviderProps> = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
