import { useEffect, useRef } from 'react';

export const useCleanup = <T>(val: T, cleanupFn: (val: T) => void): void => {
  const ref = useRef<T>(val);

  useEffect(() => {
    ref.current = val;
  }, [val]);

  useEffect(() => {
    return () => {
      cleanupFn(ref.current);
    };
  }, []);
};
