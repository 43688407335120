import { MutationCheckInGuestArgs } from '../../../gql/__generated__/graphql';
import { TicketService } from '../../services/TicketService';
import { Resolver } from '@apollo/client';
import { Container } from 'typedi';

export const checkInGuestResolver: Resolver = async (
  _,
  input: MutationCheckInGuestArgs,
): Promise<number> => {
  const ticketService = Container.get(TicketService);

  return ticketService.checkInGuest(input.scanId, input.quantity);
};
