import {
  SyncableMetrics,
  SyncCountMetrics,
  useGetFinalizeSyncMetricsLazyQuery,
} from '../gql/__generated__/graphql';
import { useEffect } from 'react';

export interface UseSyncMetricsHookReturnValue {
  hasUnsyncedData: boolean;
  loading: boolean;
  scanUpData: SyncCountMetrics;
  syncUpData: SyncCountMetrics;
  waiverImageUpData: SyncCountMetrics;
  waiverUpData: SyncCountMetrics;
}

export const allSynced = (data: SyncCountMetrics): boolean => {
  return data ? data.totalSynced >= data.total : true;
};

export interface UseSyncMetricsHookOptions {
  pollInterval?: number;
}

export const hasUnsyncedData = (data: Partial<SyncableMetrics>): boolean => {
  const scanUpData = data?.scans.up;
  const syncUpData = data?.syncs.up;
  const waiverImageUpData = data?.waiverImages.up;
  const waiverUpData = data?.waivers.up;

  return (
    !allSynced(scanUpData) ||
    !allSynced(syncUpData) ||
    !allSynced(waiverImageUpData) ||
    !allSynced(waiverUpData)
  );
};

export const useSyncMetrics = ({
  pollInterval,
}: UseSyncMetricsHookOptions): UseSyncMetricsHookReturnValue => {
  const [_, { data, loading, startPolling, stopPolling }] = useGetFinalizeSyncMetricsLazyQuery();
  const scanUpData = data?.metrics?.sync?.scans.up;
  const syncUpData = data?.metrics?.sync?.syncs.up;
  const waiverImageUpData = data?.metrics?.sync?.waiverImages.up;
  const waiverUpData = data?.metrics?.sync?.waivers.up;

  useEffect(() => {
    startPolling(pollInterval ?? 1000);
  }, [pollInterval, startPolling, stopPolling]);

  if (!data) {
    return {
      hasUnsyncedData: undefined,
      loading,
      scanUpData: null,
      syncUpData: null,
      waiverImageUpData: null,
      waiverUpData: null,
    };
  }

  return {
    hasUnsyncedData: hasUnsyncedData(data?.metrics?.sync),
    loading,
    scanUpData,
    syncUpData,
    waiverImageUpData,
    waiverUpData,
  };
};
