import { ScanError, ScanErrorMetrics } from '../../gql/__generated__/graphql';
import { ErrorMetricLegend } from './ErrorMetricLegend';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { PieChart } from '@mui/x-charts';
import { FC } from 'react';

export interface ErrorsByTypeMetricProps {
  data: ScanErrorMetrics[];
}

export const ErrorsByTypeMetric: FC<ErrorsByTypeMetricProps> = ({ data }) => {
  const theme = useTheme();

  const total = data.reduce((acc, error) => {
    return acc + error.count;
  }, 0);

  const alreadyScanned = data.find((error) => error.error === ScanError.AlreadyScanned).count ?? 0;
  const unknownTicket = data.find((error) => error.error === ScanError.UnknownTicket).count ?? 0;
  const invalidTicket = data.find((error) => error.error === ScanError.InvalidTicket).count ?? 0;

  return (
    <Paper sx={{ borderColor: theme.palette.divider, borderWidth: 1, borderStyle: 'solid', p: 2 }}>
      <Stack spacing={2} sx={{ alignItems: 'center', textAlign: 'center' }}>
        <Stack>
          <Typography variant="subtitle1">Errors</Typography>
          <Typography sx={{ color: theme.palette.grey[600] }} variant="overline">
            All Scanners
          </Typography>
        </Stack>
        <PieChart
          colors={
            total > 0
              ? [
                  // @ts-ignore
                  theme.palette.error.lighter,
                  theme.palette.error.light,
                  theme.palette.error.main,
                ]
              : [theme.palette.grey[300]]
          }
          height={250}
          series={[
            {
              cornerRadius: 0,
              cx: 120,
              cy: 125,
              data:
                total > 0
                  ? data.map((error, index) => ({
                      id: error.error,
                      value: error.count,
                    }))
                  : [{ id: 'none', value: 1 }],
              outerRadius: 120,
              paddingAngle: 0,
            },
          ]}
          width={250}
        />

        <Stack spacing={1} sx={{ width: '100%' }}>
          <ErrorMetricLegend
            color={
              // @ts-ignore
              theme.palette.error.lighter
            }
            total={alreadyScanned}
            percentage={alreadyScanned / total}
            type={ScanError.AlreadyScanned}
          />
          <ErrorMetricLegend
            color={theme.palette.error.light}
            total={unknownTicket}
            percentage={unknownTicket / total}
            type={ScanError.UnknownTicket}
          />
          <ErrorMetricLegend
            color={theme.palette.error.main}
            total={invalidTicket}
            percentage={invalidTicket / total}
            type={ScanError.InvalidTicket}
          />
        </Stack>
      </Stack>
    </Paper>
  );
};
