import { AttendeeWaiverCollection } from '../components/scanning/AttendeeWaiverCollection.enum';
import { PartyMode } from '../components/scanning/PartyMode.enum';
import {
  AppConfig,
  RecognizedScanData,
  RecognizedScanDataType,
  ScannerSettingName,
} from '../gql/__generated__/graphql';

export type AppSettings = {
  [key in ScannerSettingName]: string | number | boolean | null;
};

const DEFAULT_RECOGNIZED_DATA: RecognizedScanData[] = [
  {
    name: 'Event Tickets Plus',
    // Note the lack of domain name. This will vary by client and should be overridden on a
    // per-organization or per-event basis
    regex:
      '/?event_qr_code=1&ticket_id=(?<wpTicketId>\\d+)&event_id=(?<wpEventId>\\d+)&security_code=(?<secret>[a-f0-9]{10})&path=wp-json%2Ftribe%2Ftickets%2Fv1%2Fqr$',
    type: RecognizedScanDataType.Ticket,
  },
  {
    name: 'Alphanumeric Ticket ID',
    // This is a general-purpose ticket type used as a default to allow any alphanumeric string
    regex: '^(?<secret>[a-zA-Z0-9]+)$',
    type: RecognizedScanDataType.Ticket,
  },
];

export const DefaultAppSettings: AppSettings = {
  [ScannerSettingName.AuthSessionDuration]: undefined,

  [ScannerSettingName.ErrorUnknownTicketLevel]: 'error',

  [ScannerSettingName.ScanDataRecognized]: JSON.stringify(DEFAULT_RECOGNIZED_DATA),
  [ScannerSettingName.ScanPartyEnabled]: 'always' as PartyMode,
  [ScannerSettingName.ScanPartySticky]: false,
  [ScannerSettingName.ScanTimeout]: 20_000, // 20 seconds
  [ScannerSettingName.ScanWaiverCollection]: 'none' as AttendeeWaiverCollection,

  [ScannerSettingName.SyncTimeout]: 10_000, // 10 seconds
  [ScannerSettingName.SyncScannerSettingsInterval]: 60_000, // 1 minutes

  [ScannerSettingName.SyncScansDownEnabled]: true,
  [ScannerSettingName.SyncScansDownInterval]: 60_000, // 60 seconds
  [ScannerSettingName.SyncScansDownLimit]: 100,

  [ScannerSettingName.SyncScansUpEnabled]: true,
  [ScannerSettingName.SyncScansUpInterval]: 60_000, // 60 seconds
  [ScannerSettingName.SyncScansUpLimit]: 20,

  [ScannerSettingName.SyncSyncsUpEnabled]: true,
  [ScannerSettingName.SyncSyncsUpInterval]: 120_000, // 2 minutes
  [ScannerSettingName.SyncSyncsUpLimit]: 0,

  [ScannerSettingName.SyncTicketsDownEnabled]: true,
  [ScannerSettingName.SyncTicketsDownInterval]: 120_000, // 2 minutes
  [ScannerSettingName.SyncTicketsDownLimit]: 100,

  [ScannerSettingName.SyncWaiversUpEnabled]: true,
  [ScannerSettingName.SyncWaiversUpInterval]: 60_000, // 1 minute
  [ScannerSettingName.SyncWaiversUpLimit]: 20,

  [ScannerSettingName.SyncWaiversPurgeImage]: true,

  [ScannerSettingName.TicketUse]: 'single',
};

export const getSetting = <T>(appConfig: AppConfig, setting: ScannerSettingName): T => {
  return JSON.parse(appConfig.settings)[setting] as T;
};

export const getSettingSafe = <T>(appConfig: AppConfig, setting: ScannerSettingName): T => {
  let value: T;

  try {
    value = getSetting(appConfig, setting);
    return value ?? (DefaultAppSettings[setting] as T);
  } catch {
    return DefaultAppSettings[setting] as T;
  }
};
