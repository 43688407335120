import { Guest, Ticket } from '../../../gql/__generated__/graphql';
import { TicketService } from '../../services/TicketService';
import { addTypename } from './addTypename';
import { Resolvers } from '@apollo/client';
import { Container } from 'typedi';

export const guestResolver: Resolvers = {
  Guest: {
    tickets: async (parent: Guest): Promise<Ticket[]> => {
      const ticketService = Container.get(TicketService);

      return addTypename(ticketService.listByFk({ guestId: parent.email }), 'Ticket');
    },
  },
};
