import { AppRoutes } from './components/AppRoutes';
import { GuestDataProvider } from './contexts/GuestDataProvider';
import { ThemeProvider } from '@greatcrowd/ui-theme';
import { FC } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';

export const App: FC = () => (
  <BrowserRouter>
    <ThemeProvider>
      <IntlProvider locale="en">
        <GuestDataProvider>
          <AppRoutes />
        </GuestDataProvider>
      </IntlProvider>
    </ThemeProvider>
  </BrowserRouter>
);
