import { MessageDescriptor } from 'react-intl';

export const messages = {
  waiverScanInstructionsAdditional: {
    id: 'Waiver__waiverScanInstructionsAdditional',
    defaultMessage: `Scan additional waivers`,
    description: `Instructions for scanning additional waivers after check-in has been completed or when a scan isn't valid`,
  },
  wavierScanInstructionsOnePerAttendee: {
    id: 'Waiver__waiversScanInstructionsOnePerAttendee',
    defaultMessage: `Take a photo of {quantity, plural, =0 {each guest's} =1 {the guest's} other {each guest's}} completed waiver`,
    description:
      'Instructions for scanning attendee waivers when ScannerSettingName.ScanWaiverCollection === AttendeeWaiverCollection.ONE_PER_ATTENDEE',
  } as MessageDescriptor,
  wavierScanInstructionsOnePerParty: {
    id: 'Waiver__waiversScanInstructionsOnePerParty',
    defaultMessage: `Take a photo of {quantity, plural, =0 {each guest's} =1 {the guest's} other {each guest's}} completed waiver`,
    description:
      'Instructions for scanning attendee waivers when ScannerSettingName.ScanWaiverCollection === AttendeeWaiverCollection.ONE_PER_PARTY',
  } as MessageDescriptor,
  waiversScannedLabelOnePerAttendee: {
    id: 'Waiver__waiversScannedLabelOnePerAttendee',
    defaultMessage: `{count}/{quantity} {quantity, plural,
      =0 {waivers}
      =1 {waiver}
      other {waivers}
    } scanned`,
    description:
      'Indicates how many waivers out of how many required have been scanned. Only used when ScannerSettingName.ScanWaiverCollection === AttendeeWaiverCollection.ONE_PER_ATTENDEE',
  } as MessageDescriptor,
  waiversScannedLabelOnePerParty: {
    id: 'Waiver__waiversScannedLabelOnePerParty',
    defaultMessage: `{count, plural, 
       =0 {No waivers}
       =1 {1 waiver}
       other {# waivers}
    } scanned`,
    description:
      'Indicates how many waivers have been scanned for a party. Only used when ScannerSettingName.ScanWaiverCollection === AttendeeWaiverCollection.ONE_PER_PARTY',
  } as MessageDescriptor,
};
