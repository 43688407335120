import { ActionData } from './ActionData';
import { GuestAction } from './GuestAction';
import { GuestActionType } from './GuestActionType.enum';

export interface DownloadDataActionData extends ActionData {}

export class DownloadDataAction extends GuestAction<DownloadDataActionData> {
  data: DownloadDataActionData;
  type = GuestActionType.DOWNLOAD_DATA;

  constructor() {
    super();

    this.data = {};
  }

  static from(data: DownloadDataActionData): DownloadDataAction {
    return new DownloadDataAction();
  }
}
