import { AppLayout } from '../../components/AppLayout';
import { CantScanButton } from '../../components/scanning/CantScanButton';
import { PartyMode } from '../../components/scanning/PartyMode.enum';
import { QrCodeScanner } from '../../components/scanning/QrCodeScanner';
import { ScanMode } from '../../components/scanning/ScanMode.enum';
import { PracticeData } from '../../data/utils/PracticeData.enum';
import {
  getRecognizedScanDataFromRawData,
  getRecognizedScanDataId,
} from '../../data/utils/recognizedScanData.util';
import {
  RecognizedScanDataType,
  ScanError,
  ScannerSettingName,
  useCreateScanMutation,
} from '../../gql/__generated__/graphql';
import { useAppSetting } from '../../hooks/useAppSetting';
import { routes } from '../../routes';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { FC, useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export interface ScanningTicketsScannerViewProps {}

export const getScanMode = (partyMode: PartyMode, scanMode: ScanMode): ScanMode => {
  if (partyMode === 'always') {
    return 'party';
  }

  if (partyMode === 'never') {
    return 'single';
  }

  return scanMode;
};

export const ScanningTicketsScannerView: FC<ScanningTicketsScannerViewProps> = (props) => {
  const navigate = useNavigate();
  const partyMode = useAppSetting<PartyMode>(ScannerSettingName.ScanPartyEnabled);
  const scanTimeout = useAppSetting<number>(ScannerSettingName.ScanTimeout);
  const recognizedScanDataSetting = useAppSetting<string>(ScannerSettingName.ScanDataRecognized);
  const [scanMode, setScanMode] = useState<ScanMode>('party');
  const lastUrl = useRef<string>('');
  const [createScan] = useCreateScanMutation();

  // The handleScan callback refuses to update when this setting updates. Instead, we use a ref to
  // gain access to the latest value
  const recognizedScanDataSettingRef = useRef<string>(recognizedScanDataSetting);
  recognizedScanDataSettingRef.current = recognizedScanDataSetting;

  // The callbacks that depend on this state value which are created in useQrCodeScanner() are
  // not updated when the state is mutated. Instead, we use a ref to gain access to the latest value
  const scanModeRef = useRef<ScanMode>();
  scanModeRef.current = getScanMode(partyMode, scanMode);

  const handleScan = useCallback(
    async (url: string) => {
      const recognizedScanData = getRecognizedScanDataFromRawData(
        recognizedScanDataSettingRef.current,
        url,
      );

      // We recognize the scanned data, but we recognize it as something other than a ticket. For
      // example, extra add-ons might have a static QR code so the guest feels like the add-on is
      // "official". This feature gives us the opportunity to present instructions to the gate
      // attendant.
      if (recognizedScanData?.type === RecognizedScanDataType.NonTicket) {
        navigate(routes.scanningTicketsNote({ id: getRecognizedScanDataId(recognizedScanData) }));
        return;
      }

      if (url === lastUrl.current) {
        return;
      }
      lastUrl.current = url;

      const { data } = await createScan({
        variables: { data: url },
      });

      const scan = data.createScan;

      if (scan.error === ScanError.PracticeTicket) {
        switch (scan.url.toUpperCase() as PracticeData) {
          case PracticeData.PRACTICE_ALREADY_SCANNED:
          case PracticeData.PRACTICE_INVALID:
          case PracticeData.PRACTICE_UNKNOWN_TICKET:
            navigate(routes.scanningTicketsResult({ scanId: scan.id }));
            return;
          case PracticeData.PRACTICE_SCAN_NOTE:
            // NOOP: This is handled above
            break;
          case PracticeData.PRACTICE_VALID_TICKET:
            navigate(
              routes.scanningTicketsCheckIn({ scanId: scan.id, scanMode: scanModeRef.current }),
            );
            return;
        }
      }

      // Ticket is invalid or was previously scanned
      if (scan.error) {
        navigate(routes.scanningTicketsResult({ scanId: scan.id }));
        return;
      }

      // Ticket is valid and has not been previously scanned
      navigate(routes.scanningTicketsCheckIn({ scanId: scan.id, scanMode: scanModeRef.current }));
      return;
    },
    [createScan, navigate],
  );

  const handleTimeout = useCallback(() => {
    navigate(routes.scanningHome());
  }, [navigate]);

  return (
    <AppLayout
      AppBarProps={{
        action: <CantScanButton />,
        title: 'Scan Tickets',
      }}
    >
      <Stack direction="column" spacing={2} sx={{ height: '100%', py: 2, width: '100%' }}>
        <Container sx={{ flexGrow: 1 }}>
          <QrCodeScanner
            onScan={handleScan}
            onScanModeChange={setScanMode}
            onTimeout={handleTimeout}
            partyMode={partyMode}
            scanMode={scanMode}
            timeoutAfter={scanTimeout}
          />
        </Container>
      </Stack>
    </AppLayout>
  );
};
