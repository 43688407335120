import { SyncCountMetrics } from '../../gql/__generated__/graphql';
import Box from '@mui/material/Box';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

export interface DataSyncProgressProps {
  label: string;

  metrics: SyncCountMetrics;
}

export const DataSyncProgress: FC<DataSyncProgressProps> = ({ label, metrics }) => {
  const percentage = metrics ? (100 * metrics?.totalSynced) / metrics.total : 0;
  const color: LinearProgressProps['color'] =
    percentage >= 100 || metrics?.total === 0 ? 'success' : 'info';

  return (
    <Box>
      <Typography component="h2" variant="subtitle2">
        {label}
      </Typography>
      <LinearProgress color={color} variant="determinate" value={percentage} />
    </Box>
  );
};
