import { AdminLayout } from '../../components/admin/AdminLayout';
import { AdminLayoutContent } from '../../components/admin/AdminLayoutContent';
import { AdminLayoutTitle } from '../../components/admin/AdminLayoutTitle';
import { useGuestData } from '../../hooks/useGuestData';
import { routes } from '../../routes';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

export const AdminDebugDeviceView: FC = () => {
  const { appConfig } = useGuestData();
  const deviceType = appConfig?.scanner.deviceType;
  const friendlyName = appConfig?.scanner.friendlyName;

  return (
    <AdminLayout ToolbarProps={{ back: routes.adminDebug() }}>
      <AdminLayoutTitle>Device</AdminLayoutTitle>
      <AdminLayoutContent>
        <List dense disablePadding>
          <ListItem disableGutters>
            <ListItemText primary="Friendly Name" secondary={friendlyName || '-'} />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary="User Agent (UA)" secondary={navigator.userAgent} />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary="Detected Device Type (from UA)" secondary={deviceType} />
          </ListItem>
        </List>
      </AdminLayoutContent>
    </AdminLayout>
  );
};
