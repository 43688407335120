import { AppBar, AppBarProps } from './AppBar';
import { AppBottomNavigation } from './AppBottomNavigation';
import { View } from './View';
import Stack from '@mui/material/Stack';
import { SxProps } from '@mui/material/styles';
import React, { FC, ReactNode } from 'react';

interface AppLayoutProps {
  AppBarProps?: AppBarProps;

  children: ReactNode;

  showAppBar?: boolean;

  showBottomNavigation?: boolean;

  title?: string;

  sx?: SxProps;
}

export const AppLayout: FC<AppLayoutProps> = ({
  AppBarProps,
  children,
  showAppBar = true,
  showBottomNavigation = false,
  sx,
}) => {
  return (
    <View>
      <Stack
        direction="column"
        spacing={0}
        sx={{ display: 'flex', flexDirection: 'column', height: '100%', ...sx }}
      >
        {showAppBar && <AppBar {...AppBarProps} />}
        <main style={{ flexGrow: 1, flexShrink: 1, minHeight: 0 }}>{children}</main>
        {showBottomNavigation && <AppBottomNavigation />}
      </Stack>
    </View>
  );
};
