import { DefaultAppSettings, getSetting } from '../data/AppSettings';
import {
  AppConfig,
  ScannerSettingName,
  useGetAppConfigurationQuery,
} from '../gql/__generated__/graphql';

export const useAppSetting = <T>(setting: ScannerSettingName): T => {
  const { data, loading } = useGetAppConfigurationQuery();

  if (loading) {
    return DefaultAppSettings[setting] as T;
  }

  return getSetting<T>(data?.appConfig as AppConfig, setting);
};
