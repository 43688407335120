import { Guest } from '../../gql/__generated__/graphql';
import { getTicket } from './getTicket';
import { faker } from '@faker-js/faker/locale/en_US';

export const getGuest = (
  options?: Partial<Guest>,
  tickets?: { totalTickets?: number; totalScanned?: number },
): Guest => {
  const givenName = options.givenName ?? faker.name.firstName();
  const surname = options.surname ?? faker.name.lastName();
  const email = faker.internet.email(givenName, surname);

  const guest: Guest = {
    __typename: 'Guest',
    email,
    givenName,
    guestId: email,
    phone: faker.phone.number('+1##########'),
    surname,
    ...options,
  };

  const totalTickets = tickets?.totalTickets ?? faker.datatype.number({ min: 1, max: 8 });
  const totalScanned =
    tickets?.totalScanned ?? faker.datatype.number({ min: 0, max: totalTickets });

  guest.tickets = [
    // Scanned tickets
    ...new Array(totalScanned).fill(0).map(() => getTicket(guest, {}, true)),
    // Un-scanned tickets
    ...new Array(totalTickets - totalScanned).fill(0).map(() => getTicket(guest, {}, false)),
  ];

  return guest;
};
