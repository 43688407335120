import { AppConfig } from '../../gql/__generated__/graphql';
import { GreatCrowdScannerDatabase } from '../GreatCrowdScannerDatabase';
import { DexieRepository } from './DexieRepository';
import { Table } from 'dexie';
import { Service } from 'typedi';

@Service()
export class AppConfigRepository extends DexieRepository<AppConfig, number> {
  protected readonly table: Table<AppConfig>;

  constructor(db: GreatCrowdScannerDatabase) {
    super();

    this.table = db.appConfig as any;
  }
}
