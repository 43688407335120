import { WorkflowStep } from './WorkflowStep.interface';
import { FC, useCallback, useState } from 'react';

export interface WorkflowRunnerProps<ContextType = any> {
  defaultContext?: ContextType;

  onAbort?: (context: ContextType) => Promise<void>;

  onSubmit?: (context: ContextType) => Promise<void>;

  steps: WorkflowStep[];
}

export const WorkflowRunner: FC<WorkflowRunnerProps> = ({
  defaultContext = {},
  onAbort,
  onSubmit,
  steps,
}) => {
  const [context, setContext] = useState(defaultContext);
  const [stepIndex, setStepIndex] = useState<number>(0);

  const step = steps[stepIndex];

  const handleAbort = useCallback(async () => {
    await onAbort?.(context);
  }, [context, onAbort]);

  const handleBack = useCallback(async () => {
    if (stepIndex > 0) {
      setStepIndex(stepIndex - 1);
    } else {
      await handleAbort();
    }
  }, [handleAbort, stepIndex]);

  /**
   * onSubmit can be called at any time by any step in the workflow. It's the
   * responsibility of the onComplete handler to manage navigation away from the workflow.
   */
  const handleSubmit = useCallback(async () => {
    return onSubmit?.(context);
  }, [context, onSubmit]);

  const handleNext = useCallback(async () => {
    if (stepIndex + 1 < steps.length) {
      setStepIndex(stepIndex + 1);
    }
  }, [stepIndex, steps]);

  return (
    <step.Component
      context={context}
      currentStep={step}
      onAbort={handleAbort}
      onBack={handleBack}
      onSubmit={handleSubmit}
      onNext={handleNext}
      setContext={setContext}
      stepIndex={stepIndex}
      steps={steps}
    />
  );
};
