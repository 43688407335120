import { WorkflowRunnerToolbar } from './WorkflowRunnerToolbar';
import { WorkflowStep } from './WorkflowStep.interface';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SxProps, useTheme } from '@mui/material/styles';
import { FC, ReactNode } from 'react';

export interface WorkflowLayoutProps {
  children: ReactNode;

  darkMode?: boolean;

  disableStepper?: boolean;

  onBack?: () => void;

  stepIndex: number;

  steps: WorkflowStep[];

  sx?: SxProps;

  title?: string;
}

export const WorkflowStepLayout: FC<WorkflowLayoutProps> = ({
  children,
  darkMode = false,
  disableStepper = false,
  onBack,
  stepIndex,
  steps,
  sx,
  title,
}) => {
  const theme = useTheme();

  return (
    <Stack
      direction="column"
      sx={{
        display: 'flex',
        height: '100%',
        p: 2,
        width: '100%',
        ...sx,
      }}
    >
      <WorkflowRunnerToolbar
        darkMode={darkMode}
        disableStepper={disableStepper}
        onBack={onBack}
        stepIndex={stepIndex}
        steps={steps}
      />
      <Typography
        component="h1"
        sx={{ fontWeight: theme.typography.fontWeightMedium, mb: 2 }}
        variant="h4"
      >
        {title}
      </Typography>
      <Stack
        direction="column"
        spacing={2}
        sx={{ display: 'flex', height: '100%', justifyContent: 'space-between', width: '100%' }}
      >
        {children}
      </Stack>
    </Stack>
  );
};
