import { QueryLocalScansArgs, Scan } from '../../../gql/__generated__/graphql';
import { ScanService } from '../../services/ScanService';
import { addTypename } from './addTypename';
import { Resolver } from '@apollo/client';
import { Container } from 'typedi';

export const getLocalScansResolver: Resolver = async (
  parent: null,
  { limit, offset }: QueryLocalScansArgs,
): Promise<Scan[]> => {
  const scanService = Container.get(ScanService);

  return addTypename<Scan[]>(scanService.getUnsynced(offset, limit), 'Scan');
};
