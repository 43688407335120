import { AttendeeWaiver } from '../../gql/__generated__/graphql';
import { GreatCrowdScannerDatabase } from '../GreatCrowdScannerDatabase';
import { DexieRepository } from './DexieRepository';
import { Table } from 'dexie';
import { Service } from 'typedi';

@Service()
export class WaiverRepository extends DexieRepository<AttendeeWaiver, number> {
  protected readonly table: Table<AttendeeWaiver>;

  constructor(db: GreatCrowdScannerDatabase) {
    super();

    this.table = db.waivers;
  }

  async listByScanId(scanId: string): Promise<AttendeeWaiver[]> {
    return this.table.where({ scanId }).toArray();
  }
}
