import { AppLayout } from '../../components/AppLayout';
import { Search } from '../../components/Search';
import { TableStatus } from '../../components/TableStatus';
import { GuestListItem } from '../../components/guest/GuestListItem';
import {
  Guest,
  useGetGuestCountLazyQuery,
  useGetGuestsLazyQuery,
} from '../../gql/__generated__/graphql';
import { useLogger } from '@greatcrowd/ui-logging';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import debounce from 'lodash/debounce';
import { FC, useCallback, useEffect, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

const PAGE_SIZE = 25;

const overwriteAtIndex = (original: Guest[], page: Guest[], index: number): Guest[] => {
  const guests = [...original];

  for (const guest of page) {
    guests[index++] = guest;
  }

  return guests;
};

export const LookupGuestsView: FC = () => {
  const logger = useLogger(LookupGuestsView.name);
  const [search, setSearch] = useState<string>('');
  const [guests, setGuests] = useState<Guest[]>([]);
  const [getGuestCount, { data: countData }] = useGetGuestCountLazyQuery();
  const [getGuests, { data, error, loading }] = useGetGuestsLazyQuery({
    variables: { limit: PAGE_SIZE },
  });

  if (error) {
    logger.error('Failed to list guests', error, { search });
  }

  const handleSearch = useCallback(
    async (value: string) => {
      setSearch(value);
    },
    [setSearch],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchGuests = useCallback(
    debounce(async (value: string) => {
      // noinspection ES6MissingAwait
      getGuestCount({ variables: { search: value } });

      const { data } = await getGuests({
        variables: { offset: 0, limit: PAGE_SIZE, search: value },
      });
      setGuests(data?.guests as Guest[]);
    }, 1000),
    [getGuestCount, getGuests, setGuests],
  );

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    searchGuests(search);
  }, [search, searchGuests]);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    getGuestCount();
  }, [getGuestCount]);

  const renderRow: FC<ListChildComponentProps<Guest[]>> = (props) => {
    const { data, index, style } = props;
    const guest = data[index] as Guest;
    if (!guest) {
      return;
    }
    return <GuestListItem guest={guest} index={index} style={style} />;
  };

  const loadMoreItems = useCallback(
    async (startIndex: number, stopIndex: number) => {
      const { data } = await getGuests({
        variables: { limit: PAGE_SIZE, offset: startIndex, search },
      });
      setGuests(overwriteAtIndex(guests, data.guests as Guest[], startIndex));
    },
    [getGuests, guests, search, setGuests],
  );

  const itemCount = countData?.metrics.guestCount ?? 0;
  if (!Number.isInteger(itemCount)) {
    return <TableStatus Icon={SearchOffIcon} message="No guests" />;
  }

  let resultContent;
  if (search && !guests.length && !loading) {
    resultContent = <TableStatus Icon={SearchOffIcon} message="No results" />;
  } else {
    resultContent = (
      <AutoSizer>
        {({ height, width }) => (
          <InfiniteLoader
            isItemLoaded={(index) => !!data?.guests[index]}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered, ref }) => (
              <FixedSizeList
                itemCount={itemCount}
                itemData={guests}
                itemSize={64}
                height={height}
                onItemsRendered={onItemsRendered}
                ref={ref}
                style={{ listStyleType: 'none' }}
                width={width}
              >
                {renderRow}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    );
  }

  return (
    <AppLayout showBottomNavigation>
      <Stack
        direction="column"
        spacing={2}
        sx={{ minHeight: 'calc(100vh - 10rem)', height: '100%', p: 2, pb: 0 }}
      >
        <Stack direction="column" spacing="2" sx={{ flexGrow: 0, flexShrink: 0 }}>
          <Typography component="h2" variant="h4">
            Guest List
          </Typography>
          <Search onChange={handleSearch} placeholder="Search by email" value={search} />
          {!loading ? null : <LinearProgress />}
        </Stack>
        <Box
          sx={{
            overflowY: 'auto',
            flexGrow: 1,
            flexShrink: 1,
          }}
        >
          {resultContent}
        </Box>
      </Stack>
    </AppLayout>
  );
};
