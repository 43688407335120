import { AttendeeWaiverCollection } from '../../../components/scanning/AttendeeWaiverCollection.enum';
import { AdditionalWaiverCollectionCard } from '../../../components/waiver/AdditionalWaiverCollectionCard';
import { Scan, ScannerSettingName } from '../../../gql/__generated__/graphql';
import { useAppSetting } from '../../../hooks/useAppSetting';
import { ScanningResultErrorCard } from './ScanningResultErrorCard';
import { ScanningResultLayout } from './ScanningResultLayout';
import { FC } from 'react';

interface ScanningResultUnknownTicketInfoProps {
  scan: Scan;
}

export const ScanningResultUnknownTicketInfo: FC<ScanningResultUnknownTicketInfoProps> = ({
  scan,
}) => {
  const waiverCollection = useAppSetting<AttendeeWaiverCollection>(
    ScannerSettingName.ScanWaiverCollection,
  );

  const message =
    waiverCollection === AttendeeWaiverCollection.NONE
      ? "Inspect the guest's ticket. If the ticket has the correct day and looks official, continue scanning each of the guest's tickets."
      : "Inspect the guest's ticket. If the ticket has the correct day and looks official, add a waiver and continue scanning each of the guest's tickets.";

  return (
    <ScanningResultLayout title="Unknown Ticket">
      <ScanningResultErrorCard title="Inspect the ticket" message={message} />

      <AdditionalWaiverCollectionCard scanId={scan.id} />
    </ScanningResultLayout>
  );
};
