import { routes } from '../routes';
import { Logo } from './icons/Logo';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { SxProps, useTheme } from '@mui/material/styles';
import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

export interface AppBarProps {
  action?: ReactNode;

  logoIcon?: ReactNode;

  logoRoute?: string;

  sx?: SxProps;

  title?: string;
}

export const AppBar: FC<AppBarProps> = ({
  action,
  logoIcon = <Logo />,
  logoRoute = routes.scanningHome(),
  sx,
  title,
}) => {
  const theme = useTheme();

  return (
    <>
      <MuiAppBar
        elevation={0}
        position="sticky"
        sx={{
          backgroundColor: '#ffffff',
          borderBottomColor: theme.palette.divider,
          borderBottomStyle: 'solid',
          borderBottomWidth: 1,
          color: theme.palette.text.primary,
          flexShrink: 0,
          ...sx,
        }}
      >
        <Toolbar sx={{ height: '40px', minHeight: '40px', px: 1.5 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box sx={{ flexBasis: 120 }}>
              <Link to={logoRoute}>{logoIcon}</Link>
            </Box>

            {title && (
              <Box sx={{ flexBasis: 120, textAlign: 'center' }}>
                <Typography
                  component="h1"
                  sx={{ fontWeight: theme.typography.fontWeightBold }}
                  variant="body1"
                >
                  {title}
                </Typography>
              </Box>
            )}

            <Box sx={{ flexBasis: 120, textAlign: 'end' }}>{action}</Box>
          </Box>
        </Toolbar>
      </MuiAppBar>
    </>
  );
};
