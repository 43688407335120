import { FC } from 'react';

interface LogoProps {
  height?: number;
  width?: number;
}

export const Logo: FC<LogoProps> = ({ height = 24, width = 24 }) => {
  return (
    <div style={{ width, height }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        x="0"
        y="0"
        enableBackground="new 0 0 300 300"
        version="1.1"
        viewBox="0 0 300 300"
        xmlSpace="preserve"
      >
        <path
          d="M272.2 300H27.8C12.4 300 0 287.6 0 272.2V27.8C0 12.4 12.4 0 27.8 0h244.4C287.6 0 300 12.4 300 27.8v244.4c0 15.4-12.4 27.8-27.8 27.8z"
          className="st0"
          style={{ fill: '#1A1A1A' }}
        ></path>
        <path
          d="M153.8 80.8c4.2-1.1 8-.5 11.6 2.3l8.1-11.4c-2.7-3.6-10.5-5.1-17-3.9-.7.1-1.3.3-1.9.5-19.9 5.4-30.7 33.9-37.5 57-1.7-10.5-2-21.3-.3-31.4 1.7-.6 3.2-1.3 4.4-2h.1l-3.9-6.7c-1.8.5-3.5 1.3-5.2 2.6-1.9.6-4.2 1.3-6.6 1.9L97 92c-18.3 4.9-40.8 12.3-41.3 31.6-.1 1.4.1 2.8.3 4.1 1.8 9.2 11 14.9 21.3 12.1.9-.2 1.7-.5 2.6-.9l2.3-16c-.9.4-1.7.7-2.7 1-4.9 1.3-9.8-.1-10.7-4.4-.2-.8-.2-1.6-.1-2.5 1.2-9.1 12.2-13.7 24.1-16.9 4-1.1 8.1-2 12-2.9-2.8 4.8-5.9 11.4-9.4 20.4-5.7 14.2-14.9 30.8-26.2 33.9-.7.2-1.5.3-2.2.4-2.9.5-6.5-.9-8.8-2.9l-8.4 13.5c2.7 3.6 10.5 5.1 17 3.9.9-.2 1.7-.3 2.5-.5 17.2-4.6 25.1-22.8 32.5-43.1 2.3-6.5 4.2-11.5 5.9-15.4-.1 8.4.5 20.3 3.9 37.4l-.1.4 10.4.7c4.3-32.3 14.3-60.1 31.9-65.1z"
          className="st1"
          style={{ fill: '#F5F4F2' }}
        ></path>
        <path
          d="M146 88.2c-2.1.6-4.4 2.3-6.1 4.6-1.5 2.3-2.1 4.7-1.7 6.6.2 1.2.9 2.2 2 2.7.9.5 2.1.6 3.4.2 2.2-.6 4.5-2.3 6.1-4.7 1.5-2.2 2.1-4.7 1.7-6.6-.3-1.3-.9-2.2-2.1-2.7-1-.4-2.1-.4-3.3-.1zM183.8 94.5l-11 2.9c-.4 1.2-.7 2.3-1 3.5-1.8-1.5-4.5-2-7.4-1.2-7.5 2.2-12.3 10.9-13.9 17.5l-.3 1.8c-2.3 4.4-5.4 8.9-7.2 8.9-1.5 0-2.2-1.1 1-11.3l3.9-12.5-11.4 3.1-4.4 19.8c-.6 2.4-.6 4.5-.3 6.2.7 3.5 3.3 5.2 7.3 4.1 3.7-1 7.8-4.2 11.5-10 1.3 3.8 4.5 5.8 9.2 4.6 4.1-1 7-4.2 8.9-7.4-1.4 7.5-4.4 13.8-12.4 16.4-2.8 1-6.8.5-9.5-.9l-5.6 11.4c3 2.3 9.3 2.5 14.9 1 1.1-.3 2.1-.6 3.1-1 25.2-10.5 16.1-41.9 24.6-56.9zm-14.2 12.7c.5 2.5-1 8.7-3.1 11.4-1 1.2-2 1.9-2.9 2.2-1.2.3-2.2-.1-2.5-1.5-.8-3.1 1-8.9 3.3-11.6.9-1.1 1.9-1.8 2.8-2.1 1.1-.3 2.1.2 2.4 1.6zM238.3 63.3l-11.8 3.2c-1.7 7.7-3 13.3-3.9 17.5l-3.4.9-1 5.6 3.1-.8c-.9 4.1-1.2 6.4-1.4 8.5v.2c-2.3 4.8-6.2 11-8.2 11-1.5 0-1.5-1.1 1-11.3.6-2.2.7-4.2.4-5.7-.5-2.7-2.3-4.1-4.9-4.1-.7.1-1.5.1-2.5.4-2.4.6-5.7 2.2-8.7 5.6l5.1-21.5-11.9 3.2c.1.2-6.8 34-8.7 49.8l10.1-2.7 3.4-19.6c.9-2.7 2.9-4.5 4.5-4.9 1.7-.4 3 .5 2.6 2.7l-2 8.3c-.5 2.1-.5 3.9-.3 5.3.7 3.6 3.8 4.9 8.1 3.8 4.1-1.1 8.6-5 12.7-12.1 1.4 5.2 5.2 8.8 13.3 6l2.6-10.6c-2.6 1.1-5.7 1-6.5-2.6-.2-1-.2-2.2 0-3.8.1-.8.9-3.8 2.2-9l4.4-1.2 1.2-5.7-4.3 1.1 4.8-17.5z"
          className="st1"
          style={{ fill: '#F5F4F2' }}
        ></path>
        <g>
          <path
            d="M102.3 211.8c-5.4 1.4-10.9 3.7-16.1 6.1l-6.9 3.3c.3-2.1.7-4.5 1.3-7 4.3-1.4 8.6-3.5 12.9-6.2 14.3-9.3 29.1-30.7 30.5-43.8.2-1.7.2-3.3-.1-4.7-1.1-5.3-5.6-7.8-13.7-5-18 6.4-33 29.3-40.1 51.2-3.2-.4-5.5-1.9-6.2-4.6-1.3-3.7 1.6-8.7 7.4-11l-10.5-10.3c-8.8 4.2-13.8 13.9-12 22.8.5 2.5 1.5 4.9 3.1 7.1 2.9 4.2 8.5 6.5 15.3 6.5-.9 4.5-1.5 8.7-1.7 12.7-4.9 3.1-8.4 5.6-10.3 6.7l2.6 5.1c4.1-2.4 12.6-7.3 27-13.4 3.6-1.5 7.1-2.8 10.4-3.7 8.9-2.4 16.8-2.2 22 2.8l11.5-11c-7.6-6.2-16.9-6.1-26.4-3.6zm-14.3-19c10.1-20.3 21.2-30.9 26.2-32.3.9-.2 1.6-.2 2.1.2 3.9 2.9-7.3 27.2-25.1 38.5-2.4 1.6-5 2.9-7.5 4 1.4-3.8 2.8-7.4 4.3-10.4zM135.2 171.9c2.2-.6 4.5-2.3 6.1-4.7 1.5-2.2 2.1-4.7 1.7-6.6-.3-1.3-.9-2.2-2.1-2.7-1-.5-2.2-.5-3.4-.2-2.1.6-4.4 2.3-6.1 4.7-1.5 2.3-2.1 4.7-1.7 6.6.2 1.2.9 2.2 2 2.7 1.1.6 2.3.6 3.5.2z"
            className="st1"
            style={{ fill: '#F5F4F2' }}
          ></path>
          <path
            d="M175.4 164.1l-11 2.9c-.4 1.2-.7 2.3-1 3.5-1.8-1.5-4.5-2-7.4-1.2-7.5 2.2-12.3 10.9-13.9 17.5l-.3 1.8c-2.3 4.4-5.4 8.9-7.2 8.9-1.5 0-2.2-1.1 1-11.3l3.9-12.5-11.4 3.1-4.4 19.8c-.6 2.4-.6 4.5-.3 6.2.7 3.5 3.3 5.2 7.3 4.1 3.7-1 7.8-4.2 11.5-10 1.3 3.8 4.5 5.8 9.2 4.6 4.1-1 7-4.2 8.9-7.4-1.4 7.5-4.4 13.8-12.4 16.4-2.8 1-6.8.5-9.5-.9l-5.6 11.4c3 2.3 9.3 2.5 14.9 1 1.1-.3 2.1-.6 3.1-1 25.2-10.5 16.1-41.9 24.6-56.9zm-14.2 12.7c.5 2.5-1 8.7-3.1 11.4-.9 1.2-2 1.9-2.9 2.2-1.2.3-2.2-.1-2.5-1.5-.8-3.1 1-8.9 3.3-11.6.9-1.1 1.9-1.8 2.8-2.1 1.1-.3 2.1.2 2.4 1.6zM244.3 150.1c-.1-.6-.1-1.2.1-2l-8.8-1.9-2.4 7.4c-.9 2.5-1.9 5.2-2.9 7.7-.2.5-.4.9-.6 1.4-2.3 4.9-6.3 11.5-8.5 11.5-1.5 0-1.8-1.5.7-11.1l1.8-6.6 4.6-1.2 1.2-5.7-4.3 1.2L230 133l-11.8 3.2-3.7 17.5-3.6 1-1 5.6 3.3-.9-2 9.6c-2.3 4.7-5.9 10.2-7.8 10.2-1.5 0-1.5-1.1 1-11.3.6-2.2.7-4.2.4-5.7-.5-2.7-2.3-4.1-4.9-4.1-.7.1-1.5.1-2.5.4-2.4.6-5.7 2.2-8.7 5.6l5.1-21.5-11.9 3.2c.1.2-6.8 34-8.7 49.8l10.1-2.7 3.4-19.6c.9-2.7 2.9-4.5 4.5-4.9 1.7-.4 3 .5 2.6 2.7l-2 8.3c-.5 2.1-.5 3.9-.3 5.3.7 3.6 3.8 4.9 8.1 3.8 3.4-.9 7.2-3.8 10.7-9 .7 3.6 3.2 4.9 6.7 4.3 3.3-.5 6.9-3.2 10.3-7.9 3.1 2.8 8.1 3.5 12.6 2.2 7-1.9 12.1-8.2 11.7-15.2-.5-7-6.5-9.1-7.3-12.8zm-7 19c-1.8.5-3.7 0-5.1-1.5.8-1.7 1.6-3.5 2.4-5.4.5-1.2 1.3-3.2 1.9-5 1.6 1.6 3.3 3.4 3.9 4.6 1.1 3 .1 6.4-3.1 7.3z"
            className="st1"
            style={{ fill: '#F5F4F2' }}
          ></path>
        </g>
      </svg>
    </div>
  );
};
