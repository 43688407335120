import { AdminLayout } from '../../components/admin/AdminLayout';
import { GuestTicket } from '../../components/guest/GuestTicket';
import { getPredicate as getScanPredicate, ScanPredicate } from '../../data/utils/scan.util';
import { getPredicate as getTicketPredicate, TicketPredicate } from '../../data/utils/ticket.util';
import {
  CheckInType,
  Guest,
  Ticket,
  TicketCheckInStatus,
  useGetRandomGuestLazyQuery,
  useGetScanMetricsLazyQuery,
} from '../../gql/__generated__/graphql';
import { routes } from '../../routes';
import { ButtonStack } from '@greatcrowd/ui-button';
import { useLogger } from '@greatcrowd/ui-logging';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FC, useCallback, useEffect, useState } from 'react';

const getTicket = (guest: Guest, ticketStatus: TicketCheckInStatus): Ticket => {
  switch (ticketStatus) {
    case TicketCheckInStatus.Explicit:
      return getTicketByScanPredicate(
        guest,
        getScanPredicate({ error: null, type: CheckInType.Explicit }),
      );

    case TicketCheckInStatus.Implicit:
      return getTicketByScanPredicate(
        guest,
        getScanPredicate({ error: null, type: CheckInType.Explicit }),
      );

    case TicketCheckInStatus.Invalid:
      return guest.tickets[0];

    case TicketCheckInStatus.Unscanned:
      return getTicketByTicketPredicate(
        guest,
        getTicketPredicate({
          scanned: false,
        }),
      );
  }
};

const getLabel = (ticketStatus: TicketCheckInStatus): string => {
  switch (ticketStatus) {
    case TicketCheckInStatus.Explicit:
      return 'Explicitly scanned';
    case TicketCheckInStatus.Implicit:
      return 'Party scanned';
    case TicketCheckInStatus.Invalid:
      return 'Invalid';
    case TicketCheckInStatus.Unscanned:
      return 'Unscanned';
  }
};

const getTicketByTicketPredicate = (guest: Guest, predicate: TicketPredicate): Ticket => {
  return guest.tickets.find(predicate);
};

const getTicketByScanPredicate = (guest: Guest, predicate: ScanPredicate): Ticket => {
  return guest.tickets.find((ticket) => ticket._localScans.some(predicate));
};

export const AdminDebugQRView: FC = () => {
  const logger = useLogger(AdminDebugQRView.name);
  const [getRandomGuest, { data, error, loading }] = useGetRandomGuestLazyQuery();
  const [getScanMetrics, { data: metricData, error: metricError }] = useGetScanMetricsLazyQuery();
  const [ticketStatus, setTicketStatus] = useState(TicketCheckInStatus.Unscanned);

  const handleClick = useCallback(
    (ticketStatus: TicketCheckInStatus): void => {
      setTicketStatus(ticketStatus);

      // TODO: figure out how to randomly query IndexedDB for ticket (+ guest) of the
      //  appropriate ticketStatus

      // noinspection JSIgnoredPromiseFromCall
      getScanMetrics();
      // noinspection JSIgnoredPromiseFromCall
      getRandomGuest({ variables: { ticketStatus } });
    },
    [getRandomGuest, getScanMetrics, setTicketStatus],
  );

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    getScanMetrics();
    handleClick(TicketCheckInStatus.Unscanned);
  }, [getScanMetrics, handleClick]);

  if (error) {
    logger.error('Failed to fetch random guest data', error);
    return null;
  }

  if (metricError) {
    logger.error('Failed to fetch scan metrics', metricError);
    return null;
  }

  if (!data || !metricData) {
    return null;
  }

  const guest = data.randomGuest as Guest;
  let ticket: Ticket = getTicket(guest, ticketStatus);

  return (
    <AdminLayout ToolbarProps={{ back: routes.adminDebug() }}>
      <Stack
        direction="column"
        spacing={2}
        sx={{ height: '100%', justifyContent: 'space-between', textAlign: 'center' }}
      >
        <Box>
          <Typography component="h2" variant="h3">
            {getLabel(ticketStatus)}
          </Typography>
          <GuestTicket guest={guest} loading={loading} qrCode ticket={ticket} />
        </Box>
        <ButtonStack sx={{ flexGrow: 1, justifyContent: 'end' }}>
          <Button
            color="success"
            disabled={loading || !metricData?.metrics?.unscannedTickets}
            onClick={() => handleClick(TicketCheckInStatus.Unscanned)}
            size="large"
            variant="contained"
          >
            Get Un-scanned Ticket ({metricData?.metrics?.unscannedTickets})
          </Button>
          <Button
            color="warning"
            disabled={loading || !metricData?.metrics?.implicitScans}
            onClick={() => handleClick(TicketCheckInStatus.Implicit)}
            size="large"
            variant="contained"
          >
            Get Party-Scanned Ticket ({metricData?.metrics?.implicitScans})
          </Button>
          <Button
            color="error"
            disabled={loading || !metricData?.metrics?.explicitScans}
            onClick={() => handleClick(TicketCheckInStatus.Explicit)}
            size="large"
            variant="contained"
          >
            Get Scanned Ticket ({metricData?.metrics?.explicitScans})
          </Button>
          <Button
            color="error"
            disabled={loading}
            onClick={() => handleClick(TicketCheckInStatus.Invalid)}
            size="large"
            variant="contained"
          >
            Get Invalid Ticket
          </Button>
        </ButtonStack>
      </Stack>
    </AdminLayout>
  );
};
