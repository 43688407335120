import { AdminLayout } from '../../components/admin/AdminLayout';
import { AdminLayoutContent } from '../../components/admin/AdminLayoutContent';
import { AdminLayoutTitle } from '../../components/admin/AdminLayoutTitle';
import { getSettingSafe } from '../../data/AppSettings';
import { ScannerSettingName } from '../../gql/__generated__/graphql';
import { useGuestData } from '../../hooks/useGuestData';
import { routes } from '../../routes';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { FC } from 'react';

export const AdminDebugSettingsView: FC = () => {
  const { appConfig } = useGuestData();

  const settings = Object.values(ScannerSettingName).map((key) => {
    return {
      key,
      value: getSettingSafe(appConfig, key)?.toString(),
    };
  });
  settings.sort((setting1, setting2) => setting1.key.localeCompare(setting2.key));

  return (
    <AdminLayout ToolbarProps={{ back: routes.adminDebug() }}>
      <AdminLayoutTitle>Settings</AdminLayoutTitle>
      <AdminLayoutContent>
        <List dense disablePadding>
          {settings.map((setting) => (
            <ListItem disableGutters key={setting.key}>
              <ListItemText primary={setting.key} secondary={setting.value} />
            </ListItem>
          ))}
        </List>
      </AdminLayoutContent>
    </AdminLayout>
  );
};
