import { useGetAppConfigurationQuery } from '../gql/__generated__/graphql';
import { routes } from '../routes';
import { useLogger } from '@greatcrowd/ui-logging';
import React, { FC, ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface AuthenticationProviderProps {
  children?: ReactNode;
}

export const AuthenticatedRoute: FC<AuthenticationProviderProps> = ({ children }) => {
  const { data, error, loading } = useGetAppConfigurationQuery();
  const logger = useLogger(AuthenticatedRoute.name);

  if (loading) {
    return null;
  }

  if (error || !data?.appConfig?.token) {
    logger.info('Scanner not linked to event');
    return <Navigate to={routes.home()} replace />;
  }

  return <Outlet />;
};
