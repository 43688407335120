import { ScannerSync } from '../../gql/__generated__/graphql';
import { GreatCrowdScannerDatabase } from '../GreatCrowdScannerDatabase';
import { DexieRepository } from './DexieRepository';
import { Table } from 'dexie';
import { Service } from 'typedi';

@Service()
export class SyncRepository extends DexieRepository<ScannerSync, number> {
  protected readonly table: Table<ScannerSync>;

  constructor(db: GreatCrowdScannerDatabase) {
    super();

    this.table = db.syncs;
  }
}
