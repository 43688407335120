import { AdminToolbar, AdminToolbarProps } from './AdminToolbar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { FC, ReactNode } from 'react';

interface AdminLayoutProps {
  children: ReactNode;

  ToolbarProps?: AdminToolbarProps;
}

export const AdminLayout: FC<AdminLayoutProps> = ({ children, ToolbarProps }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <AdminToolbar {...ToolbarProps} />
      <Stack spacing={2} sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, p: 2 }}>
        {children}
      </Stack>
    </Box>
  );
};
