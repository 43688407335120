import { ActionData } from './ActionData';
import { GuestAction } from './GuestAction';
import { GuestActionType } from './GuestActionType.enum';

export interface UploadDataActionData extends ActionData {}

export class UploadDataAction extends GuestAction<UploadDataActionData> {
  data: UploadDataActionData;
  type = GuestActionType.UPLOAD_DATA;

  constructor() {
    super();

    this.data = {};
  }

  static from(data: UploadDataActionData): UploadDataAction {
    return new UploadDataAction();
  }
}
