import { ActionData } from './ActionData';
import { GuestAction } from './GuestAction';
import { GuestActionType } from './GuestActionType.enum';

export interface SyncDataActionData extends ActionData {}

export class SyncDataAction extends GuestAction<SyncDataActionData> {
  data: SyncDataActionData;
  type = GuestActionType.SYNC_DATA;

  constructor() {
    super();

    this.data = {};
  }

  static from(data: SyncDataActionData): SyncDataAction {
    return new SyncDataAction();
  }
}
