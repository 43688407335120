import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { SxProps, useTheme } from '@mui/material/styles';
import { FC, ReactNode } from 'react';

export interface SettingsListItemProps extends ListItemButtonProps {
  children?: ReactNode;

  label: string;

  sx?: SxProps;
}

export const SettingsListItem: FC<SettingsListItemProps> = ({ children, label, sx, ...props }) => {
  const theme = useTheme();

  return (
    <ListItemButton
      sx={{
        borderColor: theme.palette.divider,
        borderRadius: 1,
        borderStyle: 'solid',
        borderWidth: 1,
        color: 'inherit',
        ...sx,
      }}
      {...props}
    >
      <ListItemText>{label}</ListItemText>
      {children}
    </ListItemButton>
  );
};
