import { routes } from '../../routes';
import { ButtonStack } from '@greatcrowd/ui-button';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';

interface CantScanDialogProps extends DialogProps {
  onClose: () => void;
}

export const CantScanDialog: FC<CantScanDialogProps> = ({ onClose, ...props }) => {
  const theme = useTheme();

  const handleClose = useCallback(() => onClose?.(), [onClose]);

  return (
    <Dialog
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: theme.shape.borderRadius, p: 2 } }}
      sx={{ textAlign: 'center' }}
      {...props}
    >
      <DialogTitle sx={{ p: 0, textAlign: 'center' }}>Can't Scan Ticket?</DialogTitle>
      <DialogContent sx={{ p: 0, py: 3 }}>
        <DialogContentText>
          If you can’t scan a ticket, you can look up a ticketholder in the{' '}
          <strong>guest list</strong> to see if they’re registered.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 0 }}>
        <ButtonStack>
          <Button
            color="secondary"
            component={Link}
            variant="contained"
            size="large"
            to={routes.lookupGuests()}
          >
            Search Guest List
          </Button>
          <Button color="inherit" onClick={handleClose} size="large">
            Go Back
          </Button>
        </ButtonStack>
      </DialogActions>
    </Dialog>
  );
};
