import { Scan } from '../../../gql/__generated__/graphql';
import { ScanningResultErrorCard } from './ScanningResultErrorCard';
import { ScanningResultLayout } from './ScanningResultLayout';
import { FC } from 'react';

interface ScanningResultInvalidTicketProps {
  scan: Scan;
}

export const ScanningResultInvalidTicket: FC<ScanningResultInvalidTicketProps> = ({ scan }) => {
  return (
    <ScanningResultLayout title="Invalid Ticket">
      <ScanningResultErrorCard />
    </ScanningResultLayout>
  );
};
