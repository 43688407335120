import { ScanSizer } from './ScanSizer';
import { ButtonStack } from '@greatcrowd/ui-button';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useTheme } from '@mui/material/styles';
import { useCallback } from 'react';

interface ScanWaiverDialogReviewContentProps {
  imageData: string;

  onConfirm: (imageData: string) => void;

  onRetake: () => void;
}

export const ScanWaiverDialogReviewContent = ({
  imageData,
  onConfirm,
  onRetake,
}: ScanWaiverDialogReviewContentProps) => {
  const theme = useTheme();

  const handleConfirm = useCallback(() => {
    onConfirm(imageData);
  }, [imageData, onConfirm]);

  return (
    <>
      <DialogContent sx={{ p: 2, pt: 0 }}>
        <ScanSizer style={{ flexGrow: 1, height: '100%' }}>
          <img
            alt="Preview of waiver"
            src={imageData}
            style={{
              borderRadius: theme.shape.borderRadius,
              height: '100%',
              objectFit: 'cover',
              width: '100%',
            }}
          />
        </ScanSizer>
      </DialogContent>
      <DialogActions sx={{ p: 2, pt: 0 }}>
        <ButtonStack sx={{ flexGrow: 0, justifyContent: 'end' }}>
          <Button
            color="primary"
            onClick={handleConfirm}
            size="large"
            sx={{ color: theme.palette.common.white }}
            variant="contained"
          >
            Confirm Waiver
          </Button>
          <Button onClick={onRetake} size="large" variant="outlined">
            Retake Photo
          </Button>
        </ButtonStack>
      </DialogActions>
    </>
  );
};
