import { NoWaiverDialog } from './NoWaiverDialog';
import Button, { ButtonProps } from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { useCallback, useState } from 'react';

interface NoWaiverButtonProps extends ButtonProps {
  onCancel: () => void;
}

export const NoWaiverButton = ({ onCancel, ...props }: NoWaiverButtonProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleCloseClick = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  return (
    <>
      <NoWaiverDialog open={open} onCancel={onCancel} onClose={handleClose} />
      <Button
        disableElevation
        fullWidth
        onClick={handleCloseClick}
        size="small"
        sx={{ fontWeight: theme.typography.fontWeightRegular, textTransform: 'none' }}
        variant="text"
      >
        Guest does not have a waiver?
      </Button>
    </>
  );
};
