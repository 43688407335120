export enum AttendeeWaiverCollection {
  /**
   * Waiver collection is disabled
   */
  NONE = 'none',

  /**
   * Each party must present a waiver signed by each attendee. This value is
   * only applied if party-scan mode is enabled
   */
  ONE_PER_PARTY = 'party',

  /**
   * Each attendee must present a waiver. This value disallows party-scan
   */
  ONE_PER_ATTENDEE = 'attendee',
}
