import { TicketClaim } from '../../gql/__generated__/graphql';
import { faker } from '@faker-js/faker/locale/en_US';

export const getTicketClaim = (options: Partial<TicketClaim> = {}): TicketClaim => {
  return {
    created: faker.date.past(),
    email: faker.internet.email(),
    id: faker.datatype.uuid(),
    name: faker.name.fullName(),
    phone: faker.phone.number('+1##########'),
    updated: faker.date.past(),
    __typename: 'TicketClaim',
  };
};
