let timeout = 0;

export const setCustomTimeout = (newTimeout: number) => {
  timeout = newTimeout;
};

export const customFetch = async (uri: RequestInfo, options: RequestInit = {}) => {
  try {
    if (timeout) {
      options.signal = AbortSignal.timeout(timeout);
    }

    return fetch(uri, options);
  } catch (error) {
    if (error instanceof DOMException) {
      throw new Error('Request timeout error');
    }
    throw error;
  }
};
