import Chip, { ChipProps } from '@mui/material/Chip';
import { FC } from 'react';

interface GuestStatusChipProps {
  totalTickets: number;
  totalTicketsScanned: number;
}

const getLabel = (totalTickets: number, totalTicketsScanned: number): string => {
  return `${totalTicketsScanned}/${totalTickets}`;
};

const getColor = (totalTickets: number, totalTicketsScanned: number): ChipProps['color'] => {
  if (totalTicketsScanned >= totalTickets) {
    return 'success';
  }

  return 'default';
};

export const GuestStatusChip: FC<GuestStatusChipProps> = ({
  totalTickets,
  totalTicketsScanned,
}) => {
  return (
    <Chip
      color={getColor(totalTickets, totalTicketsScanned)}
      label={getLabel(totalTickets, totalTicketsScanned)}
      size="small"
      variant="filled"
    />
  );
};
