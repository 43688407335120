import { QueryLocalSyncsArgs, ScannerSync } from '../../../gql/__generated__/graphql';
import { SyncService } from '../../services/SyncService';
import { addTypename } from './addTypename';
import { Resolver } from '@apollo/client';
import { Container } from 'typedi';

export const getLocalSyncsResolver: Resolver = async (
  parent: null,
  { limit, offset }: QueryLocalSyncsArgs,
): Promise<ScannerSync[]> => {
  const syncService = Container.get(SyncService);

  return addTypename<ScannerSync[]>(syncService.getUnsynced(offset, limit), 'ScannerSync');
};
