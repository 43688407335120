import { GuestDataApolloProvider } from '../data/graph/GuestDataApolloProvider';
import { GRAPHQL_CLIENT_TOKEN } from '../data/graph/InjectGraphQLClient';
import { client } from '../data/graph/client';
import { dispatchGuestAction } from '../workers/GuestDataWorkerActions';
import { InitializeWorkerAction, WorkerMode } from '../workers/actions/InitializeWorkerAction';
import { DataSyncContextProvider } from './DataSyncContext';
import { GuestDataContext, SyncedDataContextValue } from './GuestDataContext';
import { FC, ReactNode, useEffect, useMemo } from 'react';
import { Container } from 'typedi';

// Make the client available for ine
Container.set(GRAPHQL_CLIENT_TOKEN, client);

interface SyncedDataProviderProps {
  children: ReactNode;

  value?: SyncedDataContextValue;
}

export const GuestDataProvider: FC<SyncedDataProviderProps> = ({ children }) => {
  const channel = useMemo(() => new MessageChannel(), []);

  // This worker is responsible for managing all operations that relate to scanning
  const scanWorker = useMemo(
    () =>
      new Worker(new URL('../workers/GuestDataWorker.ts', import.meta.url), {
        type: 'module',
        name: 'scan',
      }),
    // eslint-disable-next-line
    [],
  );

  // This worker is responsible for managing all operations that relate to syncing data
  // to/from the backend. This worker posts messages that are consumed by the scanner
  // worker when new data is synced down.
  const syncWorker = useMemo(
    () =>
      new Worker(new URL('../workers/GuestDataWorker.ts', import.meta.url), {
        type: 'module',
        name: 'sync',
      }),
    // eslint-disable-next-line
    [],
  );

  useEffect(() => {
    dispatchGuestAction(
      scanWorker,
      InitializeWorkerAction.from({ mode: WorkerMode.SCAN, port: channel.port1 }),
      [channel.port1],
    );
    dispatchGuestAction(
      syncWorker,
      InitializeWorkerAction.from({ mode: WorkerMode.SYNC, port: channel.port2 }),
      [channel.port2],
    );
    // eslint-disable-next-line
  }, []);

  return (
    <GuestDataContext.Provider value={{ worker: scanWorker }}>
      <GuestDataApolloProvider>
        <DataSyncContextProvider>{children}</DataSyncContextProvider>
      </GuestDataApolloProvider>
    </GuestDataContext.Provider>
  );
};
