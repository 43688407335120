import { GuestTicket } from '../../components/guest/GuestTicket';
import { TicketQuantity } from '../../components/scanning/TicketQuantity';
import { WorkflowStep, WorkflowStepProps } from '../../components/workflow/WorkflowStep.interface';
import { WorkflowStepLayout } from '../../components/workflow/WorkflowStepLayout';
import { CheckInWorkflowContext } from './CheckInWorkflowContext.interface';
import { FinishCheckInButton } from './FinishCheckInButton';
import { ButtonStack } from '@greatcrowd/ui-button';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FC, useCallback, useState } from 'react';

export interface PartyCheckInStepProps extends WorkflowStepProps<CheckInWorkflowContext> {}

export const PartyCheckInStep: FC<PartyCheckInStepProps> = ({
  context,
  onBack,
  onNext,
  onSubmit,
  setContext,
  stepIndex,
  steps,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleTicketQuantityChange = useCallback(
    (quantity: number) => {
      setContext({
        ...context,
        quantity,
      });
    },
    [context, setContext],
  );

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    await onSubmit();
    await onNext();
  }, [onNext, onSubmit, setLoading]);
  const isNextToLastStep = steps.length - 2 === stepIndex;

  return (
    <WorkflowStepLayout onBack={onBack} steps={steps} stepIndex={stepIndex} title="Group Check-in">
      <Stack direction="column" spacing={2} sx={{ flexGrow: 1, justifyContent: 'start' }}>
        <TicketQuantity
          disabled={loading}
          max={context.unscannedTicketCount}
          onChange={handleTicketQuantityChange}
          value={context.quantity}
        />
        <Typography variant="subtitle2" sx={{ textAlign: 'center' }}>
          This ticket is part of an order. How many guests are checking in right now?
        </Typography>
      </Stack>
      <Stack direction="column" spacing={2} sx={{ flexGrow: 0 }}>
        <GuestTicket guest={context.guest} />

        <ButtonStack sx={{ flexGrow: 0, pb: 3 }}>
          {isNextToLastStep ? (
            <FinishCheckInButton loading={loading} onClick={handleSubmit} />
          ) : (
            <Button color="primary" onClick={onNext} size="large" variant="contained">
              Next
            </Button>
          )}
        </ButtonStack>
      </Stack>
    </WorkflowStepLayout>
  );
};

export const PartyCheckInStepDefinition: WorkflowStep = {
  Component: PartyCheckInStep,
  id: 'party',
};
