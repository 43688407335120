import { WorkflowRunner } from '../../components/workflow/WorkflowRunner';
import { WorkflowStep } from '../../components/workflow/WorkflowStep.interface';
import '../../gql/__generated__/graphql';
import {
  GetClearDataWorkflowContextQuery,
  useClearDataMutation,
  useGetClearDataWorkflowContextQuery,
} from '../../gql/__generated__/graphql';
import { hasUnsyncedData } from '../../hooks/useSyncMetrics';
import { routes } from '../../routes';
import { FRIENDLY_NAME_LOCAL_STORAGE_KEY } from '../common/constants';
import { UnsyncedDataStepDefinition } from '../common/steps/UnsyncedDataStep';
import { UploadDataStepDefinition } from '../common/steps/UploadDataStep';
import { ClearDataConfirmStepDefinition } from './ClearDataConfirmStep';
import { ClearDataWorkflowContext } from './ClearDataWorkflowContext.interface';
import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export interface ClearDataWorkflowProps {}

const getDefaultContext = (
  data: GetClearDataWorkflowContextQuery,
): Partial<ClearDataWorkflowContext> => {
  if (!data) {
    return null;
  }

  return {
    friendlyName: data.appConfig?.scanner.friendlyName,
    ignoreUnsyncedData: false,
    hasUnsyncedData: hasUnsyncedData(data.metrics?.sync),
  };
};

const getEnabledSteps = (context: Partial<ClearDataWorkflowContext>): WorkflowStep[] => {
  if (!context) {
    return [];
  }

  const steps = [];

  if (context.hasUnsyncedData) {
    steps.push(UnsyncedDataStepDefinition);
    steps.push(UploadDataStepDefinition);
  }

  return steps.concat([ClearDataConfirmStepDefinition]);
};

export const ClearDataWorkflow: FC<ClearDataWorkflowProps> = (props) => {
  const navigate = useNavigate();
  const result = useGetClearDataWorkflowContextQuery();
  const [clearData] = useClearDataMutation();

  const defaultContext = useMemo(() => getDefaultContext(result?.data), [result?.data]);

  const steps = useMemo(() => getEnabledSteps(defaultContext), [defaultContext]);

  const handleAbort = useCallback(
    async (context: ClearDataWorkflowContext) => {
      navigate(routes.adminDebug());
    },
    [navigate],
  );
  const handleSubmit = useCallback(
    async (context: ClearDataWorkflowContext) => {
      // Store friendlyName in local storage for future use
      localStorage.setItem(FRIENDLY_NAME_LOCAL_STORAGE_KEY, context.friendlyName);

      await clearData();
      navigate(routes.home());
    },
    [clearData, navigate],
  );

  if (!defaultContext) {
    return null;
  }

  return (
    <WorkflowRunner
      {...props}
      defaultContext={defaultContext}
      onAbort={handleAbort}
      onSubmit={handleSubmit}
      steps={steps}
    />
  );
};
