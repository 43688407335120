import {
  AppConfig,
  AttendeeWaiver,
  FetchWaiverUploadUrlDocument,
  FetchWaiverUploadUrlQuery,
  ScannerSettingName,
  ScannerSyncDirection,
  ScannerSyncType,
  SyncCountMetrics,
} from '../../gql/__generated__/graphql';
import { getSetting } from '../AppSettings';
import { WaiverRepository } from '../repositories/WaiverRepository';
import { DexieService } from './DexieService';
import { GraphQlClientService } from './GraphQlClientService';
import { SyncService } from './SyncService';
import { Logger } from '@greatcrowd/ui-logging';
import { Service } from 'typedi';

@Service()
export class WaiverImageService extends DexieService<AttendeeWaiver, number> {
  protected readonly logger = new Logger(WaiverImageService.name);
  protected readonly defaultSortField: string = 'id';

  constructor(
    protected readonly repository: WaiverRepository,
    private readonly graphqlClientService: GraphQlClientService,
    private readonly syncService: SyncService,
  ) {
    super();
  }

  async getUnsynced(cursor: number, limit: number = 100): Promise<AttendeeWaiver[]> {
    return this.repository.listByOffset(cursor, limit);
  }

  private async pushImage(waiver: AttendeeWaiver, purge: boolean): Promise<void> {
    this.logger.debug('Pushing waiver image', { waiverId: waiver.id });

    // Fetch S3 pre-signed PUT URL
    const data = await this.graphqlClientService.query<FetchWaiverUploadUrlQuery>({
      query: FetchWaiverUploadUrlDocument,
      variables: {
        waiverId: waiver.id,
      },
    });
    const presignedUrl = data.waiverUploadUrl;

    // Convert data URL to blob
    const blob = await (await fetch(waiver.imageData)).blob();

    // Send image to S3
    await fetch(presignedUrl, {
      body: blob,
      headers: {
        'Content-Type': 'image/png',
      },
      method: 'PUT',
    });

    // If the scanner supports purging the image after syncing, remove the imageData
    // from the IndexedDb table.
    if (purge) {
      waiver.imageData = null;
      await this.repository.update(waiver);
    }
  }

  private async push(waivers: AttendeeWaiver[], purge = false): Promise<void> {
    const batches = new Array(Math.ceil(waivers.length / 10)).fill(0).map((_, index) => index);

    for (const batchIndex of batches) {
      const start = batchIndex * 10;
      const end = batchIndex * 10 + 10;
      const waiverBatch = waivers.slice(start, end);

      await Promise.allSettled(waiverBatch.map((waiver) => this.pushImage(waiver, purge), this));
    }
  }

  async syncUp(appConfig: AppConfig, ignoreInterval = false, ignoreLimit = false): Promise<void> {
    const enabled = getSetting<boolean>(appConfig, ScannerSettingName.SyncWaiversUpEnabled);
    const limit = ignoreLimit
      ? 10
      : getSetting<number>(appConfig, ScannerSettingName.SyncWaiversUpLimit);
    const purgeImage = getSetting<boolean>(appConfig, ScannerSettingName.SyncWaiversPurgeImage);

    if (!enabled) {
      this.logger.debug('SYNC__WAIVERS_UP_ENABLED is false');
      return;
    }

    const cursor = await this.syncService.getLastCursor(
      ScannerSyncType.WaiverImage,
      ScannerSyncDirection.Up,
      ScannerSettingName.SyncWaiversUpInterval,
      ignoreInterval,
    );
    if (cursor === null) {
      return;
    }

    const unsynced = await this.getUnsynced(cursor, limit);

    if (!unsynced?.length) {
      return;
    }

    let error = null;

    const start = new Date();
    try {
      await this.push(unsynced, purgeImage);
    } catch (err) {
      this.logger.error('Failed to push waiver images', err, { count: unsynced.length });
      error = err.toString();
    }
    const end = new Date();

    await this.syncService.create({
      cursor: (cursor + unsynced.length).toString(),
      direction: ScannerSyncDirection.Up,
      end: end.toISOString(),
      error,
      requested: unsynced.length,
      returned: 0,
      start: start.toISOString(),
      type: ScannerSyncType.WaiverImage,
    });
  }

  async getUnsyncedCount() {
    const metrics = await this.getSyncUpMetrics();
    return metrics.total - metrics.totalSynced;
  }

  async getSyncUpMetrics(): Promise<SyncCountMetrics> {
    const lastSyncUp = await this.syncService.lastSuccessful(
      ScannerSyncType.WaiverImage,
      ScannerSyncDirection.Up,
    );

    return {
      total: await this.count(),
      totalSynced: parseInt(lastSyncUp?.cursor ?? '0'),
    };
  }
}
