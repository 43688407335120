import { SettingsListItem, SettingsListItemProps } from './SettingsListItem';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ListItemIcon from '@mui/material/ListItemIcon';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface RoutedListItemProps extends SettingsListItemProps {
  to: string;
}

export const RoutedSettingsListItem: FC<RoutedListItemProps> = ({ to, ...props }) => {
  const linkProps = {
    component: to.startsWith('http') ? 'a' : Link,
    href: to.startsWith('http') ? to : undefined,
    target: to.startsWith('http') ? '_blank' : undefined,
    to: to.startsWith('http') ? undefined : to,
  };

  return (
    <SettingsListItem {...props} {...linkProps}>
      <ListItemIcon sx={{ mr: 0 }}>
        <ChevronRight />
      </ListItemIcon>
    </SettingsListItem>
  );
};
