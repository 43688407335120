import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

export interface ScanWaiverDialogToolbarProps {
  onClose: () => void;

  title: string;
}

export const ScanWaiverDialogToolbar: FC<ScanWaiverDialogToolbarProps> = ({ onClose, title }) => {
  const theme = useTheme();

  return (
    <Toolbar sx={{ px: 0 }}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box sx={{ flexBasis: 120 }}>
          <IconButton
            aria-label="close waiver scanning dialog"
            color="inherit"
            edge="start"
            onClick={onClose}
            size="large"
            sx={{ mr: 2 }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ flexBasis: 120, textAlign: 'center' }}>
          <Typography
            component="h1"
            sx={{ fontWeight: theme.typography.fontWeightBold }}
            variant="body1"
          >
            {title}
          </Typography>
        </Box>
        <Box sx={{ flexBasis: 120, textAlign: 'end' }}>
          <span></span>
        </Box>
      </Box>
    </Toolbar>
  );
};
