import { useTheme } from '@mui/material/styles';
import { FC, ReactNode } from 'react';

interface ScanSizerProps {
  children: ReactNode;
  hiddenPercent?: number;
  style?: React.CSSProperties;
}

export const ScanSizer: FC<ScanSizerProps> = ({ children, hiddenPercent = 2.5, style = {} }) => {
  const theme = useTheme();
  return (
    <div
      style={{
        borderRadius: theme.shape.borderRadius,
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        ...style,
      }}
    >
      <div style={{ margin: `-${hiddenPercent}%` }}>{children}</div>
    </div>
  );
};
