import { SvgIcon } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

interface TableStatusProps {
  Icon: typeof SvgIcon;

  message: string;
}

export const TableStatus: FC<TableStatusProps> = ({ Icon, message }) => {
  return (
    <Stack
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center',
        flexGrow: 1,
        flexShrink: 1,
      }}
    >
      <Icon sx={{ fontSize: 80 }} />
      <Typography variant="subtitle1">{message}</Typography>
    </Stack>
  );
};
