import {
  WorkflowStep,
  WorkflowStepProps,
} from '../../../components/workflow/WorkflowStep.interface';
import { WorkflowStepLayout } from '../../../components/workflow/WorkflowStepLayout';
import { DataSyncType, useDataSyncContext } from '../../../contexts/DataSyncContext';
import { useGetDownloadDataStepMetricsQuery } from '../../../gql/__generated__/graphql';
import { allSynced } from '../../../hooks/useSyncMetrics';
import { LinkScannerWorkflowContext } from '../../LinkScannerWorkflow/LinkScannerWorkflowContext.interface';
import { DataSyncProgress } from '../DataSyncProgress';
import { ButtonStack } from '@greatcrowd/ui-button';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FC, useCallback, useEffect } from 'react';

export interface DownloadDataStepProps extends WorkflowStepProps<LinkScannerWorkflowContext> {}

export const DownloadDataStep: FC<DownloadDataStepProps> = ({
  context,
  onNext,
  onSubmit,
  setContext,
  stepIndex,
  steps,
}) => {
  const { data, loading } = useGetDownloadDataStepMetricsQuery({ pollInterval: 500 });
  const { setCurrentSync } = useDataSyncContext();

  useEffect(() => {
    // Begin the initialization sync cycle
    setCurrentSync(DataSyncType.Download);
  });

  const handleNext = useCallback(async () => {
    // Begin the usual sync cycle
    setCurrentSync(DataSyncType.Sync);

    await onNext();
  }, [onNext, setCurrentSync]);

  const ticketData = data?.metrics?.sync.tickets.down;
  const scanData = data?.metrics?.sync.scans.down;

  const hasUnsyncedData = !allSynced(ticketData) || !allSynced(scanData);

  if (loading) {
    return null;
  }

  return (
    <WorkflowStepLayout disableStepper steps={steps} stepIndex={stepIndex}>
      <Stack spacing={4} sx={{ flexGrow: 1, pt: 6 }}>
        <Stack spacing={2}>
          <Typography variant="subtitle1">This might take a few minutes...</Typography>

          <Typography variant="body1">
            Keep the app running to sync all data. If the app closes, syncing will stop.
          </Typography>
        </Stack>

        <Stack spacing={3} sx={{ flexGrow: 1 }}>
          <DataSyncProgress label="Tickets" metrics={ticketData} />
          <DataSyncProgress label="Scans" metrics={scanData} />
        </Stack>
      </Stack>
      <ButtonStack sx={{ flexGrow: 0, pb: 3 }}>
        <Button
          color="primary"
          disabled={hasUnsyncedData}
          onClick={handleNext}
          size="large"
          variant="contained"
        >
          Next
        </Button>
      </ButtonStack>
    </WorkflowStepLayout>
  );
};

export const DownloadDataStepDefinition: WorkflowStep = {
  Component: DownloadDataStep,
  id: 'download',
};
