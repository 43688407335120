import { customFetch } from './customFetch';
import { abortCheckInResolver } from './resolvers/abortCheckInResolver';
import { addWaiverResolver } from './resolvers/addWaiverResolver';
import { checkInGuestResolver } from './resolvers/checkInGuestResolver';
import { clearDataResolver } from './resolvers/clearDataResolver';
import { createManualScanResolver } from './resolvers/createManualScanResolver';
import { createScanResolver } from './resolvers/createScanResolver';
import { getAppConfigResolver } from './resolvers/getAppConfigResolver';
import { getGuestResolver } from './resolvers/getGuestResolver';
import { getGuestsResolver } from './resolvers/getGuestsResolver';
import { getLocalScansResolver } from './resolvers/getLocalScansResolver';
import { getLocalSyncsResolver } from './resolvers/getLocalSyncsResolver';
import { getMetricsResolver } from './resolvers/getMetricsResolver';
import { getRandomGuestResolver } from './resolvers/getRandomGuestResolver';
import { getScanResolver } from './resolvers/getScanResolver';
import { getTicketResolver } from './resolvers/getTicketResolver';
import { guestResolver } from './resolvers/guest.resolver';
import { metricsResolver } from './resolvers/metricsResolver';
import { scanResolver } from './resolvers/scan.resolver';
import { scanMetricsResolver } from './resolvers/scanMetricsResolver';
import { syncableMetricsResolver } from './resolvers/syncMetricsResolver';
import { ticketResolver } from './resolvers/ticket.resolver';
import { ApolloClient, createHttpLink, InMemoryCache, Resolvers } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  fetch: customFetch,
  uri: process.env.REACT_APP_GRAPH_URL,
});

const authLink = setContext(async (_, { headers, token, organizationId }) => {
  return {
    headers: {
      ...headers,
      'x-org-id': organizationId,
      authorization: `Bearer ${token}`,
    },
  };
});

export const localResolvers: Resolvers = {
  ...guestResolver,
  ...metricsResolver,
  ...scanResolver,
  ...scanMetricsResolver,
  ...syncableMetricsResolver,
  ...ticketResolver,
  Mutation: {
    abortCheckIn: abortCheckInResolver,
    addWaiver: addWaiverResolver,
    checkInGuest: checkInGuestResolver,
    clearData: clearDataResolver,
    createManualScan: createManualScanResolver,
    createScan: createScanResolver,
  },
  Query: {
    appConfig: getAppConfigResolver,
    guest: getGuestResolver,
    guests: getGuestsResolver,
    localScans: getLocalScansResolver,
    localSyncs: getLocalSyncsResolver,
    metrics: getMetricsResolver,
    randomGuest: getRandomGuestResolver,
    scan: getScanResolver,
    ticket: getTicketResolver,
  },
};

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
  link: authLink.concat(httpLink),
  resolvers: localResolvers,
  uri: process.env.REACT_APP_GRAPH_URL,
});
