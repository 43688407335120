import { Scanner } from '../../gql/__generated__/graphql';
import { ActionData } from './ActionData';
import { GuestAction } from './GuestAction';
import { GuestActionType } from './GuestActionType.enum';

export interface InitializeApplicationActionData extends ActionData {
  scanner: Scanner;

  token: string;
}

export class InitializeApplicationAction extends GuestAction<InitializeApplicationActionData> {
  data: InitializeApplicationActionData;
  type = GuestActionType.INITIALIZE_APPLICATION;

  constructor(scanner: Scanner, token: string) {
    super();

    this.data = {
      scanner,
      token,
    };
  }

  static from(data: InitializeApplicationActionData): InitializeApplicationAction {
    return new InitializeApplicationAction(data.scanner, data.token);
  }
}
