/** Convert a 2D array into a CSV string
 */
export const arrayToCsv = (data: object[], ...extraValues: string[]): string => {
  return (data || [])
    .map(
      (row) =>
        Object.values(row)
          .concat((extraValues || []).filter((value) => !!value))
          .map(String) // convert every value to String
          .map((v) => v.replaceAll('"', '""')) // escape double colons
          .map((v) => `"${v}"`) // quote it
          .join(','), // comma-separated
    )
    .join('\r\n'); // rows starting on new lines
};

export const downloadBlob = (content: string, filename: string) => {
  // Create a blob
  const blob = new Blob([content], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  const pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', filename);
  pom.click();
};
