import {
  AppConfig,
  CreateScannerSyncInput,
  PushScannerSyncsDocument,
  PushScannerSyncsMutation,
  Scanner,
  ScannerSettingName,
  ScannerSync,
  ScannerSyncDirection,
  ScannerSyncType,
  SyncCountMetrics,
} from '../../gql/__generated__/graphql';
import { getSetting } from '../AppSettings';
import { AppConfigRepository } from '../repositories/AppConfigRepository';
import { SyncRepository } from '../repositories/SyncRepository';
import { DexieService } from './DexieService';
import { GraphQlClientService } from './GraphQlClientService';
import { Logger } from '@greatcrowd/ui-logging';
import { Service } from 'typedi';
import { v4 as uuidV4 } from 'uuid';

@Service()
export class SyncService extends DexieService<ScannerSync, number> {
  protected readonly logger = new Logger(SyncService.name);
  protected readonly defaultSortField: string = 'start';

  constructor(
    protected readonly repository: SyncRepository,
    private readonly appConfigRepository: AppConfigRepository,
    private readonly graphqlClientService: GraphQlClientService,
  ) {
    super();
  }

  async create(sync: Partial<ScannerSync>): Promise<number> {
    sync.id = uuidV4();
    return super.create(sync);
  }

  // TODO: this is misnomer - fix the name
  async getUnsynced(cursor: number, limit: number): Promise<ScannerSync[]> {
    return this.repository.listByOffset(cursor, limit);
  }

  async last(type: ScannerSyncType, direction: ScannerSyncDirection): Promise<ScannerSync> {
    return this.lastByPredicate(
      (sync: ScannerSync) => sync.direction === direction && sync.type === type,
    );
  }

  async lastSuccessful(
    type: ScannerSyncType,
    direction: ScannerSyncDirection,
  ): Promise<ScannerSync> {
    return this.lastByPredicate(
      (sync: ScannerSync) =>
        sync.direction === direction &&
        !sync.error &&
        sync.type === type &&
        ((sync.direction === 'DOWN' && sync.returned > 0) ||
          (sync.direction === 'UP' && sync.requested > 0)),
    );
  }

  private async push(scanner: Scanner, syncs: ScannerSync[]): Promise<void> {
    this.logger.debug('Pushing syncs', { count: syncs.length });

    await this.graphqlClientService.mutate<PushScannerSyncsMutation>({
      mutation: PushScannerSyncsDocument,
      variables: {
        scannerSyncs: syncs.map<CreateScannerSyncInput>((sync) => ({
          cursor: sync.cursor,
          direction: sync.direction,
          end: sync.end,
          error: sync.error,
          id: sync.id,
          requested: sync.requested,
          returned: sync.returned,
          start: sync.start,
          type: sync.type,
        })),
      },
    });
  }

  /**
   * Determines whether the resource should be synced by checking interval (time
   * since last sync). Returns a number-based cursor if the sync should happen
   * @param type
   * @param direction
   * @param setting
   * @param ignoreInterval
   */
  async getLastCursor(
    type: ScannerSyncType,
    direction: ScannerSyncDirection,
    setting: ScannerSettingName,
    ignoreInterval = false,
  ): Promise<number> {
    const now = new Date();

    const appConfig = await this.appConfigRepository.single();
    const interval = getSetting<number>(appConfig, setting);

    // Use last sync to determine if the minimum interval has passed
    const lastSync = await this.last(type, direction);
    // Use last successful sync to determine the last synced cursor
    const lastSuccessfulSync = lastSync?.error
      ? await this.lastSuccessful(type, direction)
      : lastSync;

    const lastSyncStart = lastSync?.start ? new Date(lastSync.start).getTime() : null;
    const nextSyncStart = Number.isInteger(lastSyncStart)
      ? lastSyncStart + interval
      : now.getTime();

    if (ignoreInterval || nextSyncStart <= now.getTime()) {
      return lastSuccessfulSync
        ? Number(lastSuccessfulSync?.cursor || 0) + lastSuccessfulSync?.returned
        : 0;
    }

    return null;
  }

  async syncUp(
    appConfig: AppConfig,
    ignoreInterval = false,
    ignoreLimit = false,
  ): Promise<void | boolean> {
    const enabled = getSetting<boolean>(appConfig, ScannerSettingName.SyncSyncsUpEnabled);
    const limit = ignoreLimit
      ? 100
      : getSetting<number>(appConfig, ScannerSettingName.SyncSyncsUpLimit);

    if (!ignoreInterval && !enabled) {
      this.logger.debug('SYNC__SYNCS_UP_ENABLED is false');
      return;
    }

    const cursor = await this.getLastCursor(
      ScannerSyncType.Sync,
      ScannerSyncDirection.Up,
      ScannerSettingName.SyncSyncsUpInterval,
      ignoreInterval,
    );
    if (cursor === null) {
      return;
    }

    const unsynced = await this.getUnsynced(cursor, limit);

    if (!unsynced?.length) {
      this.logger.debug('No new syncs');
      return;
    }

    if (unsynced.length < limit && !ignoreInterval) {
      this.logger.debug('Not enough syncs');
      return false;
    }

    let error = null;

    const start = new Date();
    try {
      await this.push(appConfig.scanner, unsynced);
    } catch (err) {
      this.logger.error('Failed to push scanner syncs', err, { count: unsynced.length });
      error = err.toString();
    }
    const end = new Date();

    if (unsynced.length === 1 && unsynced[0].type === ScannerSyncType.Sync) {
      unsynced[0].cursor = ((cursor || 0) + 1).toString();
      await this.update(unsynced[0]);
    } else {
      await this.create({
        cursor: (cursor + unsynced.length).toString(),
        direction: ScannerSyncDirection.Up,
        end: end.toISOString(),
        error,
        requested: unsynced.length,
        returned: 0,
        start: start.toISOString(),
        type: ScannerSyncType.Sync,
      } as Partial<ScannerSync>);
    }
  }

  async getUnsyncedCount() {
    const metrics = await this.getSyncUpMetrics();
    return metrics.total - metrics.totalSynced;
  }

  async getSyncUpMetrics(): Promise<SyncCountMetrics> {
    const lastSyncUp = await this.lastSuccessful(ScannerSyncType.Sync, ScannerSyncDirection.Up);

    return {
      total: await this.count(),
      totalSynced: parseInt(lastSyncUp?.cursor ?? '0'),
    };
  }
}
