import { CheckInType, Guest, Ticket } from '../../gql/__generated__/graphql';
import { getValidScanFromTicket } from './ticket.util';

export const getImplicitlyScannedTickets = (guest: Guest): Ticket[] => {
  return (
    (guest?.tickets as Ticket[]).filter(
      (ticket) => getValidScanFromTicket(ticket)?.type === CheckInType.Implicit,
    ) || []
  );
};

export const getScannedTickets = (guest: Guest): Ticket[] => {
  return guest?.tickets.filter((ticket) => !!ticket.scanned) || [];
};

export const getUnscannedTickets = (guest: Guest): Ticket[] => {
  return guest?.tickets.filter((ticket) => !ticket.scanned) || [];
};
