import { LinkScannerWorkflow } from '../../workflows/LinkScannerWorkflow/LinkScannerWorkflow';
import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

export const LinkScannerConfirmView: FC = () => {
  const [searchParams] = useSearchParams();

  return (
    <LinkScannerWorkflow
      organizationId={searchParams.get('organizationId')}
      scannerId={searchParams.get('scannerId')}
      token={searchParams.get('token')}
    />
  );
};
