import { CheckInType, Scan, ScanError, ScanMethod } from '../../gql/__generated__/graphql';

export const compareScans = (scan1: Scan, scan2: Scan): number => {
  if (areEqual(scan1, scan2)) {
    return 0;
  }

  if (scan1.type === scan2.type) {
    return new Date(scan2.timestamp).getTime() - new Date(scan1.timestamp).getTime();
  }

  return scan1.type === CheckInType.Explicit ? -1 : 1;
};

const areEqual = (scan1: Scan, scan2: Scan): boolean => {
  return scan1?.id && scan2?.id && scan1.id === scan2.id;
};

export interface ScanFilter {
  error?: ScanError;
  method?: ScanMethod;
  type?: CheckInType;
}

export type ScanPredicate = (scan: Scan) => boolean;

export const getPredicate = (filter?: ScanFilter): ScanPredicate => {
  const { type, method, error } = filter ?? {};
  return (scan: Scan) => {
    if (type !== undefined && scan.type !== type) {
      return false;
    }

    if (method !== undefined && scan.method !== method) {
      return false;
    }

    return !(error !== undefined && scan.error !== error);
  };
};
