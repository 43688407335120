import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material/styles';
import { MouseEventHandler, FC } from 'react';

export interface FinishCheckInButtonProps {
  loading?: boolean;
  disabled?: boolean;
  onClick?: MouseEventHandler;
}

export const FinishCheckInButton: FC<FinishCheckInButtonProps> = ({
  onClick,
  disabled,
  loading,
}) => {
  const theme = useTheme();

  return (
    <LoadingButton
      color="success"
      loadingPosition="center"
      size="large"
      sx={{ color: theme.palette.common.white }}
      variant="contained"
      {...{ disabled, loading, onClick }}
    >
      Finish Check-in
    </LoadingButton>
  );
};
