import { AppConfigService } from './AppConfigService';
import { GuestService } from './GuestService';
import { ScanService } from './ScanService';
import { SyncService } from './SyncService';
import { TicketService } from './TicketService';
import { WaiverService } from './WaiverService';
import { Logger } from '@greatcrowd/ui-logging';
import { Service } from 'typedi';

@Service()
export class ApplicationService {
  protected readonly logger = new Logger(ApplicationService.name);

  constructor(
    private readonly guestService: GuestService,
    private readonly scanService: ScanService,
    private readonly appConfigService: AppConfigService,
    private readonly syncService: SyncService,
    private readonly ticketService: TicketService,
    private readonly waiverService: WaiverService,
  ) {}

  async reset(): Promise<boolean> {
    try {
      await this.appConfigService.clear();
      await this.guestService.clear();
      await this.scanService.clear();
      await this.syncService.clear();
      await this.ticketService.clear();
      await this.waiverService.clear();
    } catch (error) {
      this.logger.error('Failed to reset application state', error);
      return false;
    }

    return true;
  }
}
