import { useQrCodeScanner, UseQrCodeScannerHookOptions } from '../../hooks/useQrCodeScanner';
import { PartyMode } from './PartyMode.enum';
import { PartyScanToggle } from './PartyScanToggle';
import { ScanMode } from './ScanMode.enum';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { FC, useCallback, useEffect } from 'react';

interface QrCodeScannerProps {
  extraPadding?: number;

  onScan: (url: string) => void;

  onScanModeChange?: (mode: ScanMode) => void;

  onTimeout?: () => void;

  partyMode?: PartyMode;

  qrScannerOptions?: UseQrCodeScannerHookOptions['qrScannerOptions'];

  scanMode?: ScanMode;

  timeoutAfter?: number;

  setSecondVideoRef?: (video: HTMLVideoElement) => void;
}

export const QrCodeScanner: FC<QrCodeScannerProps> = ({
  extraPadding = 0,
  onScan,
  onScanModeChange,
  onTimeout,
  partyMode,
  qrScannerOptions = {},
  scanMode = 'party',
  setSecondVideoRef,
  timeoutAfter = 20_000,
}) => {
  const theme = useTheme();

  const handleScanModeChange = useCallback(() => {
    onScanModeChange?.(scanMode === 'single' ? 'party' : 'single');
  }, [onScanModeChange, scanMode]);

  const handleScanResult = useCallback(
    (data: string) => {
      onScan(data);
    },
    [onScan],
  );

  useEffect(() => {
    if (!onTimeout) {
      return;
    }

    const timeout = setTimeout(() => {
      onTimeout();
    }, timeoutAfter);

    return () => {
      clearTimeout(timeout);
    };
  }, [scanMode]);

  const { videoRef } = useQrCodeScanner({
    onResult: handleScanResult,
    qrScannerOptions,
    setSecondVideoRef,
  });

  return (
    <Paper
      elevation={0}
      sx={{
        alignItems: 'center',
        backgroundColor: theme.palette.grey[700],
        borderRadius: 1,
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <Stack direction="column" sx={{ height: '100%', position: 'relative', width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            left: 0,
            p: 2,
            position: 'absolute',
            right: 0,
            zIndex: 1,
          }}
        >
          {partyMode === 'toggle' && (
            <PartyScanToggle onChange={handleScanModeChange} value={scanMode} />
          )}
        </Box>

        <video
          id="qr-code-scanner-video"
          ref={videoRef}
          style={{
            objectFit: 'cover',
            height: '100%',
            maxHeight: `calc(100vh - 75px - ${extraPadding}px)`,
            width: '100%',
          }}
        />
      </Stack>
    </Paper>
  );
};
