import {
  AppConfig,
  FetchScannerDocument,
  FetchScannerQuery,
  Scanner,
  ScannerSettingName,
  ScannerSyncDirection,
  ScannerSyncType,
} from '../../gql/__generated__/graphql';
import { AppSettings, DefaultAppSettings } from '../AppSettings';
import { AppConfigRepository } from '../repositories/AppConfigRepository';
import { DexieService } from './DexieService';
import { GraphQlClientService } from './GraphQlClientService';
import { SyncService } from './SyncService';
import { Logger } from '@greatcrowd/ui-logging';
import { Service } from 'typedi';

const getSettings = (scanner: Scanner): string =>
  JSON.stringify(
    scanner.settings.reduce<AppSettings>((acc, curr) => {
      // @ts-ignore
      acc[curr.key] = JSON.parse(curr.value);
      return acc;
    }, DefaultAppSettings),
  );

@Service()
export class AppConfigService extends DexieService<AppConfig, number> {
  protected readonly logger = new Logger(AppConfigService.name);
  protected readonly defaultSortField: string = 'id';

  constructor(
    protected readonly repository: AppConfigRepository,
    private readonly graphqlClientService: GraphQlClientService,
    private readonly syncService: SyncService,
  ) {
    super();
  }

  async getScannerId(): Promise<string> {
    const appConfig = await this.repository.single();
    return appConfig?.scanner.id;
  }

  async initialize(scanner: Scanner, token: string): Promise<void> {
    const settings = getSettings(scanner);
    await this.create({ scanner, settings, token });
  }

  private async fetch(scannerId: string): Promise<Scanner> {
    const data = await this.graphqlClientService.query<FetchScannerQuery>({
      query: FetchScannerDocument,
      variables: { scannerId },
    });

    return data.scanner as Scanner;
  }

  /**
   *
   * @param appConfig
   * @param ignoreInterval
   */
  async syncDown(appConfig: AppConfig, ignoreInterval: boolean = false): Promise<void> {
    const cursor = await this.syncService.getLastCursor(
      ScannerSyncType.Scanner,
      ScannerSyncDirection.Down,
      ScannerSettingName.SyncScannerSettingsInterval,
      ignoreInterval,
    );

    if (cursor === null) {
      return;
    }

    const start = new Date();
    const scanner = await this.fetch(appConfig.scanner.id);
    const end = new Date();

    await this.repository.updatePartial(appConfig.id, {
      scanner,
      settings: getSettings(scanner),
    });

    await this.syncService.create({
      cursor: '0',
      direction: ScannerSyncDirection.Down,
      end: end.toISOString(),
      error: null,
      requested: 1,
      returned: 1,
      start: start.toISOString(),
      type: ScannerSyncType.Scanner,
    });
  }
}
