import { GetTicketQueryVariables, Ticket } from '../../../gql/__generated__/graphql';
import { TicketService } from '../../services/TicketService';
import { addTypename } from './addTypename';
import { Resolver } from '@apollo/client';
import { Container } from 'typedi';

export const getTicketResolver: Resolver = async (
  _: null,
  { ticketId }: GetTicketQueryVariables,
): Promise<Ticket> => {
  const ticketService = Container.get(TicketService);

  return addTypename<Ticket>(ticketService.findOne(ticketId), 'Ticket');
};
