import { WorkflowStep, WorkflowStepProps } from '../../components/workflow/WorkflowStep.interface';
import { WorkflowStepLayout } from '../../components/workflow/WorkflowStepLayout';
import { UpdateDataWorkflowContext } from './UpdateDataWorkflowContext.interface';
import { ButtonStack } from '@greatcrowd/ui-button';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';

export interface UpdateDataCompleteStepProps extends WorkflowStepProps<UpdateDataWorkflowContext> {}

export const UpdateDataCompleteStep: FC<UpdateDataCompleteStepProps> = ({
  context,
  onNext,
  onSubmit,
  setContext,
  stepIndex,
  steps,
}) => {
  return (
    <WorkflowStepLayout disableStepper steps={steps} stepIndex={stepIndex}>
      <Stack spacing={4} sx={{ flexGrow: 1, pt: 6 }}>
        <Typography component="h2" variant="h4">
          All data has been downloaded!
        </Typography>
        <Typography variant="body1">You're all up to date. Happy scanning!</Typography>
      </Stack>
      <ButtonStack sx={{ flexGrow: 0, pb: 3 }}>
        <Button color="primary" onClick={onSubmit} size="large" variant="contained">
          Start Scanning
        </Button>
      </ButtonStack>
    </WorkflowStepLayout>
  );
};

export const UpdateDataCompleteStepDefinition: WorkflowStep = {
  Component: UpdateDataCompleteStep,
  id: 'complete',
};
