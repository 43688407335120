import { AppLayout } from '../../components/AppLayout';
import { AttendeeWaiverCollection } from '../../components/scanning/AttendeeWaiverCollection.enum';
import { ScanMode } from '../../components/scanning/ScanMode.enum';
import { Guest, ScannerSettingName, useGetScanQuery } from '../../gql/__generated__/graphql';
import { useAppSetting } from '../../hooks/useAppSetting';
import { routes } from '../../routes';
import { CheckInWorkflow } from '../../workflows/CheckInWorkflow/CheckInWorkflow';
import { FC, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface ScanningTicketsCheckInViewProps {}

export const ScanningTicketsCheckInView: FC<ScanningTicketsCheckInViewProps> = (props) => {
  const navigate = useNavigate();
  const routeParams = useParams();
  const scanId = routeParams.scanId;
  const waiverCollection = useAppSetting<AttendeeWaiverCollection>(
    ScannerSettingName.ScanWaiverCollection,
  );

  const handleAbort = useCallback(async () => {
    navigate(routes.scanningHome());
  }, [navigate]);

  const { data, loading } = useGetScanQuery({
    variables: { scanId },
  });

  if (loading) {
    return null;
  }

  const scan = data?.scan;
  const ticket = scan?.ticket;

  return (
    <AppLayout showAppBar={false}>
      <CheckInWorkflow
        scanId={scanId}
        guest={ticket.guest as Guest}
        onAbort={handleAbort}
        scanMode={routeParams.scanMode as ScanMode}
        waiverCollection={waiverCollection}
      />
    </AppLayout>
  );
};
