import { AppLayout } from '../../components/AppLayout';
import { QrCodeScanner } from '../../components/scanning/QrCodeScanner';
import { useLogger } from '@greatcrowd/ui-logging';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Path } from '@remix-run/router/history';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const validateLinkCode = (data: string): Partial<Path> => {
  const scannedUrl = new URL(data);
  const currentUrl = new URL(window.location.href);

  if (scannedUrl.hostname !== currentUrl.hostname) {
    throw new Error('Hostnames do not match');
  }

  return {
    pathname: scannedUrl.pathname,
    search: scannedUrl.search,
  };
};

export const LinkScannerScanView: FC = () => {
  const logger = useLogger(LinkScannerScanView.name);
  const navigate = useNavigate();

  const handleScan = useCallback(
    (data: string) => {
      logger.debug('Scanner link code scanned', { data });

      const path = validateLinkCode(data);
      navigate(path);
    },
    [logger, navigate],
  );

  return (
    <AppLayout>
      <Stack direction="column" spacing={2} sx={{ height: '100%', py: 2, width: '100%' }}>
        <Container sx={{ flexGrow: 1 }}>
          <QrCodeScanner onScan={handleScan} />
        </Container>
        <Container sx={{ flexGrow: 0, textAlign: 'center' }}>
          <Typography variant="subtitle2">
            Scan a scanner QR code on the scanner management page for the event in the Great Crowd
            UI
          </Typography>
        </Container>
      </Stack>
    </AppLayout>
  );
};
