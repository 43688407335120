import {
  WorkflowStep,
  WorkflowStepProps,
} from '../../../components/workflow/WorkflowStep.interface';
import { WorkflowStepLayout } from '../../../components/workflow/WorkflowStepLayout';
import { DataSyncType, useDataSyncContext } from '../../../contexts/DataSyncContext';
import { useSyncMetrics } from '../../../hooks/useSyncMetrics';
import { DataSyncProgress } from '../DataSyncProgress';
import { SyncDataContext } from '../SyncDataContext.interface';
import { ButtonStack } from '@greatcrowd/ui-button';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FC, useCallback, useEffect } from 'react';

export interface UploadDataStepProps extends WorkflowStepProps<SyncDataContext> {}

export const UploadDataStep: FC<UploadDataStepProps> = ({
  context,
  onNext,
  onSubmit,
  setContext,
  stepIndex,
  steps,
}) => {
  const { hasUnsyncedData, scanUpData, syncUpData, waiverUpData, waiverImageUpData, loading } =
    useSyncMetrics({ pollInterval: 500 });

  const { setCurrentSync } = useDataSyncContext();

  useEffect(() => {
    // Begin the upload data sync cycle
    setCurrentSync(DataSyncType.Upload);
  });

  useEffect(() => {
    if (context.ignoreUnsyncedData) {
      // noinspection JSIgnoredPromiseFromCall
      onNext();
    }
  }, [context.ignoreUnsyncedData, onNext]);

  const handleNext = useCallback(async () => {
    // Restore the usual sync cycle
    setCurrentSync(DataSyncType.Sync);

    await onNext();
  }, [onNext, setCurrentSync]);

  if (loading) {
    return null;
  }

  return (
    <WorkflowStepLayout disableStepper steps={steps} stepIndex={stepIndex}>
      <Stack spacing={4} sx={{ flexGrow: 1, pt: 6 }}>
        <Stack spacing={2}>
          <Typography variant="subtitle1">This might take a few minutes...</Typography>

          <Typography variant="body1">
            Keep the app running to sync all data. If the app closes, syncing will stop. Open the
            app to continue syncing.
          </Typography>
        </Stack>

        <Stack spacing={3} sx={{ flexGrow: 1 }}>
          <DataSyncProgress label="Scans" metrics={scanUpData} />
          <DataSyncProgress label="Waiver Data" metrics={waiverUpData} />
          <DataSyncProgress label="Waiver Images" metrics={waiverImageUpData} />
          <DataSyncProgress label="Logs" metrics={syncUpData} />
        </Stack>
      </Stack>
      <ButtonStack sx={{ flexGrow: 0, pb: 3 }}>
        <Button
          color="primary"
          disabled={hasUnsyncedData !== false}
          onClick={handleNext}
          size="large"
          variant="contained"
        >
          Next
        </Button>
      </ButtonStack>
    </WorkflowStepLayout>
  );
};

export const UploadDataStepDefinition: WorkflowStep = {
  Component: UploadDataStep,
  id: 'upload',
};
