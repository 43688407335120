import {
  ScanSyncMetrics,
  SyncSyncMetrics,
  TicketSyncMetrics,
  WaiverImageSyncMetrics,
  WaiverSyncMetrics,
} from '../../../gql/__generated__/graphql';
import { ScanService } from '../../services/ScanService';
import { SyncService } from '../../services/SyncService';
import { TicketService } from '../../services/TicketService';
import { WaiverImageService } from '../../services/WaiverImageService';
import { WaiverService } from '../../services/WaiverService';
import { Resolvers } from '@apollo/client';
import { Container } from 'typedi';

export const syncableMetricsResolver: Resolvers = {
  SyncableMetrics: {
    scans: async (): Promise<ScanSyncMetrics> => {
      const scanService = Container.get(ScanService);

      return {
        down: await scanService.getSyncDownMetrics(),
        up: await scanService.getSyncUpMetrics(),
      };
    },
    syncs: async (): Promise<SyncSyncMetrics> => {
      const syncService = Container.get(SyncService);

      return {
        up: await syncService.getSyncUpMetrics(),
      };
    },
    tickets: async (): Promise<TicketSyncMetrics> => {
      const ticketService = Container.get(TicketService);

      return {
        down: await ticketService.getSyncDownMetrics(),
      };
    },
    waiverImages: async (): Promise<WaiverImageSyncMetrics> => {
      const waiverImageService = Container.get(WaiverImageService);

      return {
        up: await waiverImageService.getSyncUpMetrics(),
      };
    },
    waivers: async (): Promise<WaiverSyncMetrics> => {
      const waiverService = Container.get(WaiverService);

      return {
        up: await waiverService.getSyncUpMetrics(),
      };
    },
  },
};
