import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { ChangeEvent, FC, useCallback } from 'react';

interface SearchProps {
  onChange: (value: string) => void;

  placeholder: string;

  value: string;
}

export const Search: FC<SearchProps> = ({ onChange, placeholder, value }) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => onChange?.(event.target.value),
    [onChange],
  );
  const handleClear = useCallback(() => onChange?.(''), [onChange]);

  return (
    <FormControl hiddenLabel sx={{ width: '100%' }}>
      <TextField
        autoFocus
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment aria-label="clear search" onClick={handleClear} position="end">
              <ClearIcon />
            </InputAdornment>
          ),
          fullWidth: true,
          hiddenLabel: true,
          startAdornment: (
            <InputAdornment position="start" sx={{ p: 0, mt: '0 !important' }}>
              <SearchIcon />
            </InputAdornment>
          ),
          sx: {
            borderRadius: 1,
          },
        }}
        onChange={handleChange}
        placeholder={placeholder}
        size="small"
        sx={{ alignItems: 'center' }}
        value={value}
        variant="filled"
      />
    </FormControl>
  );
};
