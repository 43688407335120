import { routes } from '../routes';
import { RoutedBottomNavigation, RoutedBottomNavigationAction } from '@greatcrowd/ui-navigation';
import BarChart from '@mui/icons-material/BarChart';
import QrCodeScanner from '@mui/icons-material/QrCodeScanner';
import Search from '@mui/icons-material/Search';
import Settings from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { FC } from 'react';

interface AppBottomNavigationProps {}

export const AppBottomNavigation: FC<AppBottomNavigationProps> = () => {
  return (
    <Box sx={{ flexGrow: 0, flexShrink: 0 }}>
      <Divider />
      <RoutedBottomNavigation>
        <RoutedBottomNavigationAction
          icon={<QrCodeScanner />}
          label="Scan"
          to={routes.scanningHome()}
          value={routes.scanningHome.template}
        />
        <RoutedBottomNavigationAction
          icon={<Search />}
          label="Lookup"
          to={routes.lookupGuests()}
          value={routes.lookupGuests.template}
        />
        {/*<RoutedBottomNavigationAction*/}
        {/*  icon={<BarChart />}*/}
        {/*  label="Metrics"*/}
        {/*  to={routes.metrics()}*/}
        {/*  value={routes.metrics.template}*/}
        {/*/>*/}
        <RoutedBottomNavigationAction
          icon={<Settings />}
          label="Settings"
          to={routes.adminSettings()}
          value={routes.adminSettings.template}
        />
      </RoutedBottomNavigation>
    </Box>
  );
};
