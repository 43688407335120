import { WorkflowStep } from './WorkflowStep.interface';
import { Check } from '@mui/icons-material';
import {
  Step,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  StepLabel,
  Stepper,
  StepperProps,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { FC } from 'react';

export interface WorkflowRunnerStepperProps extends StepperProps {
  darkMode?: boolean;

  stepIndex: number;

  steps: WorkflowStep[];
}

const CustomStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    display: 'flex',
    height: 22,
    alignItems: 'center',
    '& .CustomStepIcon-completedIcon': {
      color: 'inherit',
      zIndex: 1,
      fontSize: 18,
    },
    '& .CustomStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: ownerState.active ? 'currentColor' : theme.palette.grey[300],
    },
  }),
);

const CustomStepIcon = (props: StepIconProps) => {
  const { active, completed, className } = props;

  return (
    <CustomStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="CustomStepIcon-completedIcon" />
      ) : (
        <div className="CustomStepIcon-circle" />
      )}
    </CustomStepIconRoot>
  );
};

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'inherit',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'inherit',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.grey[300],
    borderTopWidth: 2,
    borderRadius: 1,
  },
}));

export const WorkflowRunnerStepper: FC<WorkflowRunnerStepperProps> = ({
  darkMode = false,
  stepIndex,
  steps,
  sx,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Stepper
      activeStep={stepIndex}
      alternativeLabel
      connector={<CustomConnector />}
      sx={{
        color: darkMode ? theme.palette.common.white : theme.palette.primary.main,
        marginRight: '-15px',
        overflow: 'hidden',
        ...sx,
      }}
      {...props}
    >
      {steps.map((step: WorkflowStep) => (
        <Step key={step.id}>
          <StepLabel StepIconComponent={CustomStepIcon} sx={{ minWidth: '60px' }} />
        </Step>
      ))}
    </Stepper>
  );
};
